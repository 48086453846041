import { useContext } from "react";
import FormSelect from "../../components/Select";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";

export const QuestionTypeSelector = ({
  selectedQuestionType,
  handleSetQuestionType,
}) => {
  // #region Initialize
  // utils.log.component(
  //   `Question Type Selector (selected: ${selectedQuestionType})`
  // );

  const { surveyService } = useContext(AuthContext);
  const questionTypes = surveyService.getQuestionTypes();
  const list = utils.toSelectItem(questionTypes, "label", "type");

  const defaultValue = !selectedQuestionType
    ? list[0].value // Default to first item in list
    : list.find((item) => item.value === selectedQuestionType).value;
  // #endregion

  return (
    <FormSelect
      label="Question Type"
      data={list}
      fullWidth
      value={defaultValue}
      onChange={(e) => handleSetQuestionType(e.target.value)}
    ></FormSelect>
  );
};

export default QuestionTypeSelector;
