import { Tab as MTab } from "@mui/material";
import MuiTabs from "@mui/material/Tabs";
import React, { useState } from "react";
import * as config from "../../services/config";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  horizontalTabs: {
    // marginTop: theme.spacing(2),
    width: "100%",
    borderBottom: "1px solid #ccc",
    //MAKE TAB STICKY
    // position: "sticky",
    //  top: 0,
    // background: "#fbfbfb",
    // zIndex: 999999999
  },
  sidebar: {
    width: 250,
    height: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(2),
  },
  tab: {
    // alignItems: "flex-start",
    alignItems: "center",
    outline: "none !important",
    minWidth: "150px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...rest } = props;
  return <div {...rest}>{value === index && <div>{children}</div>}</div>;
}

const Tab = ({ component, ...rest }) => {
  const classes = useStyles();
  return (
    <MTab
      classes={{ root: classes.tab }}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      {...rest}
      component={component}
    />
  );
};

export default function Tabs(props) {
  const {
    children,
    onChange = null,
    backgroundColor,
    orientation = "horizontal",
    defaultActiveTab = 0,
    ...rest
  } = props;
  const [value, setValue] = useState(defaultActiveTab || 0);

  const classes = useStyles({
    sidebarBg: backgroundColor || config.defaults.TABS_BACKGROUND_COLOR,
    orientation,
  });
  const handleChange = (e, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={orientation === "vertical" ? classes.root : ""}>
      <div
        className={
          orientation === "vertical" ? classes.sidebar : classes.horizontalTabs
        }
      >
        {/* here to do  */}
        <MuiTabs
          orientation={orientation}
          value={value}
          // indicatorColor={config.defaults.TABS_INDICATOR_COLOR}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          {...rest}
        >
          {React.Children.map(children, (child) => {
            const { component, ...rest } = child.props;
            return <Tab {...rest} />;
          })}
        </MuiTabs>
      </div>

      <div className={classes.content}>
        {React.Children.map(children, (child, index) => {
          return (
            <TabPanel value={value} index={index}>
              {child.props.component}
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
}

export { Tab };
