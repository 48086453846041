
export const pieChartData = [
  { name: "Contacted", value: 200 },
  { name: "Started", value: 400 },
  { name: "Completed", value: 300 },
  { name: "Exempted", value: 300 },
];

export const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
export const RADIAN = Math.PI / 180;
export const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  //debugger;
  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const customStylesOfTimeZone = {
  control: (provided) => ({
    ...provided,
    height: "56px",
  }),
};

export const excludedUSATerritories = [
  "AS", // American Samoa
  "GU", // Guam
  "MP", // Northern Mariana Islands
  "PR", // Puerto Rico
  "VI", // U.S. Virgin Islands
  "UM", // United States Minor Outlying Islands
  // Uninhabited Territories (can also be listed individually if needed)
  // "UM-BI", // Baker Island
  // "UM-HI", // Howland Island
  // "UM-JI", // Jarvis Island
  // "UM-JA", // Johnston Atoll
  // "UM-KR", // Kingman Reef
  // "UM-MA", // Midway Atoll
  // "UM-NI", // Navassa Island
  // "UM-PA", // Palmyra Atoll
  // "UM-WI", // Wake Island
];

// export const getStates = (State) =>
//     State.getStatesOfCountry("US")
//     ?.filter((state) =>
//     !excludedUSATerritories.includes(state.isoCode) && 
//     !state.isoCode.startsWith("UM-") && 
//     state.isoCode !== "DC" 
//     )
//     ?.map((state) => ({
//         label: state.name,
//         value: state.isoCode,
//         id: state.isoCode,
//         name: state.name,
//       }));
