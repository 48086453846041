/* eslint-disable no-useless-catch */
import { RETURNURL } from "../utils/env";
import { getApiOrigin, getCookie } from "./context";
export default class PlanService {
  constructor(headers) {
    this.headers = headers;
  }

  async getSubscriptionPlans() {
    // utils.log.api(`PlanService.getSubscriptionPlans()`);
    const remoteUrl = `${getApiOrigin()}/billing/plans`;

    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch subscription plans. Status: ${response.status}`
        );
      }

      const plans = await response.json();
      return plans;
    } catch (error) {
      // utils.log.error("Error fetching subscription plans:", error);
      throw error;
    }
  }

  async subscribe(plan, subscribers) {
    // utils.log.api(`PlanService.subscribe(plan: ${plan})`, subscribers);
    // debugger;
    const remoteUrl = `${getApiOrigin()}/payment/subscribe`;
    const gpgCstCookie = getCookie("GPG-CST");
    return await fetch(remoteUrl, {
      method: "POST",
      headers: {
        ...(this.headers || {}),
        "Content-Type": "application/json",
        "GPG-CST": gpgCstCookie,
      },
      body: JSON.stringify({
        PlanCode: plan,
        Subscribers: [subscribers],
        ReturnUrl: RETURNURL,
      }),
    });
  }

  async getTransactions() {
    const remoteUrl = `${getApiOrigin()}/billing/orders/history`;

    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const list = await response.json();

      return list;
    } catch (error) {
      // utils.log.error(`Fetch failed: ${error.message}`);
      throw error;
    }
  }

  async choosePlan(email, plan) {
    const remoteUrl = `${getApiOrigin()}/employee/GetByEmail/${email}/plan/${plan}`;
    try {
      const response = await fetch(remoteUrl, {
        method: "PATCH",
        headers: this.headers,
      });

      return response;
    } catch (error) {
      // utils.log.error(`Fetch failed: ${error?.message}`);
      throw error;
    }
  }

  async chooseSubscriptionPlans(payload) {
    const remoteUrl = `${getApiOrigin()}/payment/subscribe`;
    try {
      const response = await fetch(remoteUrl, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(payload),
      });

      return response;
    } catch (error) {
      // utils.log.error(`Fetch failed: ${error?.message}`);
      throw error;
    }
  }
}
