// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.

/*eslint-disable*/

import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import FormSelect from "../../components/Select";
import { DateTime } from "luxon";
import { TimePicker } from "antd"; // !NOTE: DO NOT USE TimePicker.RangePicker.  It does not work correctly.
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FormUtility from "../../services/form";
import * as utils from "../../services/utilities";
import {
  updateQuestionAnswer,
  initValueRangeObject,
} from "../../services/question";
import useMediaQuery from "../../hooks/useMediaQuery";

import { QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";

dayjs.extend(customParseFormat);
const formUtility = new FormUtility();
export const timeFormat = "HH:mm";
export const timeHourFormat = "HH:00";

export const comparators = ["None","equals", "before", "after", "not", "between"];
export function New() {
  const min = DateTime.now().toFormat(timeHourFormat);
  const max = DateTime.now().plus({ hour: 1 }).toFormat(timeHourFormat);

  return {
    type: "TimeInput",
    code: null,
    title: "New Time Input Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: null,
    min: min,
    max: max,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  // utils.log.component(`TimeInput.Reset(${question.code})`, question);
  utils.log.info(":: 11question time",question);
  question.answer = null
}
export function Edit({ question, setInputs }) {
  utils.assert(question != null, "question is null.");
  // #region Initialize
  formUtility.setDetail(question); // TODO: 062823 Remove dependency on formUtility

  // Validate min/max exists
  const questionMinValid = dayjs(question.min, timeFormat).isValid();
  const questionMaxValid = dayjs(question.max, timeFormat).isValid();

  if (!questionMinValid) question.min = dayjs().format(timeHourFormat); //if (question.min == null) question.min = DateTime.now().toFormat("HH:00");
  if (!questionMaxValid)
    question.max = dayjs().add(1, "hour").format(timeHourFormat); //DateTime.now().plus({ hour: 1 }).toFormat(timeHourFormat); //if (question.max == null)    question.max = DateTime.now().plus({ hour: 1 }).toFormat("HH:00");

  // #endregion
  // #region Events
  const handleChange = (timeRange) => {
    const startValue = dayjs(timeRange[0]).format(timeFormat);
    const endValue = dayjs(timeRange[1]).format(timeFormat);

    question.min = startValue;
    question.max = endValue;
    const startEvent = {
      target: { name: "min", value: startValue },
    };
    const endEvent = {
      target: { name: "max", value: endValue },
    };
    formUtility.handleChange(startEvent, setInputs); // TODO: 062823 Remove dependency on formUtility
    formUtility.handleChange(endEvent, setInputs); // TODO: 062823 Remove dependency on formUtility
  };

  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography id="non-linear-slider" gutterBottom>
          Range:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PickerRange
          question={question}
          startDefaultValue={question.min}
          endDefaultValue={question.max}
          handleChange={handleChange}
          hasDisabledTime={false}
        ></PickerRange>
      </Grid>
    </Grid>
  );
}

export const PickerRange = ({
  question, // required object - Contains the min/max limiter required values
  handleChange, // function
  startDefaultValue, // optional string ("HH:mm") - Default value to set start time
  endDefaultValue, // optional string ("HH:mm") - Default value to set end time
  hasDisabledTime = true, // optional boolean
}) => {
  // PURPOSE: Presents two time pickers to select a range of time.
  // utils.log.component(
  //   `PickerRange(${question.code}) -
  //     startDefaultValue: ${startDefaultValue},
  //     endDefaultValue: ${endDefaultValue})
  //     hasDisabledTime: ${hasDisabledTime})`,
  //   question
  // );
  // #region Assertions
  utils.log.info(":: question in PickerRaneg",{question});
  utils.assert(question.min !== undefined, `question.min is undefined`);
  utils.assert(question.max !== undefined, `question.max is undefined`);

  // #endregion Assertions
  // NOTE: Be sure that question.min/max are in the format of "HH:mm"
  // #region Initialize

  const [startTimeSelectedValue, setStartTimeSelectedValue] = useState(
    dayjs(startDefaultValue, timeFormat).isValid()
      ? dayjs(startDefaultValue, timeFormat)?.format(timeFormat)
      : null
  );
  const [endTimeSelectedValue, setEndTimeSelectedValue] = useState(
    dayjs(endDefaultValue, timeFormat).isValid()
      ? dayjs(endDefaultValue, timeFormat)?.format(timeFormat)
      : null
  );

  const startSelectedObject = dayjs(startDefaultValue, timeFormat).isValid()
    ? dayjs(startDefaultValue, timeFormat)
    : null;

  const endSelectedObject = dayjs(endDefaultValue, timeFormat).isValid()
    ? dayjs(endDefaultValue, timeFormat)
    : null;

  const isStartSelected = startSelectedObject !== null;
  // Validate min/max exists
  const questionMinRangeValid = dayjs(question.min, timeFormat).isValid();
  const questionMaxRangeValid = dayjs(question.max, timeFormat).isValid();

  const minRangeObject = questionMinRangeValid
    ? dayjs(question.min, timeFormat)
    : dayjs().format(timeFormat);
  const startMinRangeValue = minRangeObject?.format(timeFormat);

  const maxRangeObject = questionMaxRangeValid
    ? dayjs(question.max, timeFormat)
    : dayjs().add(1, "hour").format(timeFormat);

  const startMaxRangeValue = maxRangeObject?.format(timeFormat);

  const [endMinRangeValue, setEndMinRangeValue] = useState(
    minRangeObject?.format(timeFormat)
  );
  const endMaxRangeValue = maxRangeObject?.format(timeFormat);

  //let answer = question.answer == null ? null : question.answer;
  // #endregion
  // #region Events
  const handleChangeStart = (timeSelectedObject) => {
    utils.log.info("** handlChange in Picker",{timeSelectedObject});
    if (!timeSelectedObject || timeSelectedObject === undefined) return;
    // utils.log.event(
    //   `handleChangeStart(${timeSelectedObject.format(timeFormat)})`
    // );
    setStartTimeSelectedValue(timeSelectedObject.format(timeFormat)); //setStartSelectedObject(timeSelectedObject);
    setEndMinRangeValue(timeSelectedObject.format(timeFormat)); // Adjust End start range
    // utils.log.stateChange(
    //   `TimeInput.PickerRange.setStartTimeSelectedValue(${timeSelectedObject.format(
    //     timeFormat
    //   )})`,
    //   timeSelectedObject
    // );
    // utils.log.stateChange(
    //   `TimeInput.PickerRange.setEndMinRangeValue(${timeSelectedObject.format(
    //     timeFormat
    //   )})`
    // );
    if (endSelectedObject?.isBefore(timeSelectedObject)) {
      // utils.log.warn(`End time is before Start time.`);
      setEndTimeSelectedValue(timeSelectedObject.format(timeFormat)); //setEndSelectedObject(timeSelectedObject); // Adjust End time if it is before start time

      // utils.log.stateChange(
      //   `TimeInput.PickerRange.setEndTimeSelectedValue(${timeSelectedObject.format(
      //     timeFormat
      //   )}) - Adjusted End time to not be before Start time.`
      // );

      handleChange([timeSelectedObject, timeSelectedObject]);
    } else {
      handleChange([timeSelectedObject, endSelectedObject]);
    }
  };

  const handleChangeEnd = (timeSelectedObject) => {
    if (!timeSelectedObject || timeSelectedObject === undefined) return;
    // utils.log.event(
    //   `handleChangeEnd(${timeSelectedObject?.format(timeFormat)})`
    // );

    setEndTimeSelectedValue(timeSelectedObject?.format(timeFormat)); //setEndSelectedObject(timeSelectedObject);
    // utils.log.stateChange(
    //   `TimeInput.PickerRange.setEndTimeSelectedValue(${timeSelectedObject?.format(
    //     timeFormat
    //   )})`
    // );
    handleChange([startSelectedObject, timeSelectedObject]);
  };

  const disabledTimeForStart = () => {
    // PURPOSE: Return an array of disabled hours and minutes arrays
    const max = dayjs(endSelectedObject, timeFormat);
    let disabledHours = [];
    let disabledMinutes = [];
    // Calculate disabled hours
    for (let i = 0; i < 24; i++) {
      if (i > max.hour()) {
        // Disable all hours greater than max.hour()
        disabledHours.push(i);
      } else if (i === max.hour()) {
        // For the hour that matches max.hour(), disable minutes greater than or equal to max.minute()
        for (let j = max.minute(); j < 60; j++) {
          disabledMinutes.push(j);
        }
      }
    }
    // Function to return disabled hours
    const getDisabledHours = () => disabledHours;
    // Function to return disabled minutes based on the selected hour
    const getDisabledMinutes = (selectedHour) => {
      if (selectedHour === max.hour()) {
        return disabledMinutes;
      }
      return [];
    };
    const result = {
      disabledHours: getDisabledHours,
      disabledMinutes: getDisabledMinutes,
    };

    // utils.log.disabled("disabledTimeForStart: ", result);
    return result;
  };

  const disabledTimeForEnd = () => {
    // PURPOSE: Return an array of disabled hours and minutes arrays
    const min = dayjs(startSelectedObject, timeFormat);
    let disabledHours = [];
    let disabledMinutes = [];
    // Calculate disabled hours
    for (let i = 0; i < 24; i++) {
      if (i < min.hour()) {
        // Disable all hours less than min.hour()
        disabledHours.push(i);
      } else if (i === min.hour()) {
        // For the hour that matches min.hour(), disable minutes less than min.minute()
        for (let j = 0; j < min.minute(); j++) {
          disabledMinutes.push(j);
        }
      }
    }

    // Function to return disabled hours
    const getDisabledHours = () => disabledHours;
    // Function to return disabled minutes based on the selected hour
    const getDisabledMinutes = (selectedHour) => {
      if (selectedHour === min.hour()) {
        return disabledMinutes;
      }
      return [];
    };
    const result = {
      disabledHours: getDisabledHours,
      disabledMinutes: getDisabledMinutes,
    };
    // utils.log.disabled("disabledTimeForEnd: ", result);
    return result;
  };
  // #endregion

  const { isMdDown } = useMediaQuery();
  return (
    <Grid container spacing={4} >
      <Grid item xs={12} md={6} id="textboxMinimum">
        <Picker // Start time picker
          label="Start Time"
          question={question}
          handleChange={handleChangeStart}
          minValue={startMinRangeValue} // string time format
          maxValue={startMaxRangeValue} // string time format
          hasDisabledTime={hasDisabledTime}
          timeSelectedValue={startTimeSelectedValue}
          setTimeSelectedValue={setStartTimeSelectedValue}
          width={isMdDown ? "100%" : "300px"}
          disablehours={disabledTimeForStart}
          allowClear={false}
        ></Picker>
      </Grid>
      <Grid item xs={12} md={6} id="textboxMaximum">
        <Picker // End time picker
          label="End Time"
          question={question}
          handleChange={handleChangeEnd}
          minValue={endMinRangeValue} // string time format
          maxValue={endMaxRangeValue} // string time format
          hasDisabledTime={hasDisabledTime}
          isDisabled={!isStartSelected}
          timeSelectedValue={endTimeSelectedValue}
          setTimeSelectedValue={setEndTimeSelectedValue}
          width={isMdDown ? "100%" : "300px"}
          disablehours={disabledTimeForEnd}
          allowClear={false}
        ></Picker>
      </Grid>
    </Grid>
  );
};
export const Picker = ({
  question, // required object
  handleChange, // function
  minValue = null, // optional string time format
  maxValue = null, // optional string time format
  hasDisabledTime = false, // optional boolean
  isDisabled = false, // optional boolean
  label = null, // optional string
  timeSelectedValue = null, // optional string time format
  setTimeSelectedValue, // required function
  disablehours = null,
  width = "120px",
}) => {
  // PURPOSE: Presents a time picker with hours and minutes disabled that is outside of the question min/max.
  // utils.log.component(
  //   `Picker(${question.code}) -
  //     label: "${label}"
  //     defaultValue: {defaultValue},
  //     minValue: ${minValue},
  //     maxValue: ${maxValue},
  //     hasDisabledTime: ${hasDisabledTime})`,
  //   question
  // );
  // #region Assertions

  if (timeSelectedValue != null) {
    utils.assert(
      typeof timeSelectedValue === "string",
      `Expect timeSelectedValue to be a string in time format(${timeFormat})`
    );
    utils.assert(
      dayjs(timeSelectedValue, timeFormat).isValid(),
      `Parameter timeSelectedValue is not a string in time format(${timeFormat}).`
    );
  }

  // #endregion Assertions
  // #region Functions
  const disabledMinutesCalculate = (timeSelectedValue, minValue, maxValue) => {
    // PURPOSE: Calculate the disabled minutes for the time picker.
    // PARAMETERS:
    //   timeSelectedValue: optional string ("HH:mm")
    //   minValue: required string ("HH:mm")
    //   maxValue: required string ("HH:mm")
    // RETURN: array of disabled minutes

    // utils.log.info(
    //   `disabledMinutesCalculate(
    //     timeSelectedValue: ${timeSelectedValue},
    //     min: ${minValue},
    //     max: ${maxValue},
    //     hasDisabledTime: ${hasDisabledTime}})`
    // );

    // #region Assertions
    if (timeSelectedValue && timeSelectedValue !== null) {
      utils.assert(
        dayjs(timeSelectedValue, timeFormat).isValid(),
        `Parameter timeSelectedValue is not a string in time format(${timeFormat}).`
      );

      utils.assert(
        dayjs(timeSelectedValue, timeFormat).isValid(),
        `Parameter timeSelectedValue(${
          typeof timeSelectedValue === "string"
            ? timeSelectedValue
            : typeof timeSelectedValue
        }) is not a valid string in time format(${timeFormat}).`
      );
    }

    utils.assert(
      dayjs(minValue, timeFormat).isValid(),
      `Parameter minValue is not a string in time format(${timeFormat}).`
    );
    utils.assert(
      dayjs(maxValue, timeFormat).isValid(),
      `Parameter minValue is not a string in time format(${timeFormat}).`
    );
    // #endregion Assertions
    if (!hasDisabledTime) {
      // utils.log.info(`disabledMinutesCalculate(hasDisabledTime: false)`);
      return []; // No disabled minutes if hasDisabledTime is false
    }
    let disabledMinutes = [];

    const timeSelectedObject = dayjs(timeSelectedValue, timeFormat);
    const minObject = dayjs(minValue, timeFormat);
    const maxObject = dayjs(maxValue, timeFormat);

    // utils.log.info(`timeSelectedObject`, timeSelectedObject);

    if (timeSelectedObject.$H === minObject.$H) {
      // Selected minimum hour - disabled minutes specified in minValue
      for (var iMin = 0; iMin < 60; iMin++) {
        if (iMin < minObject.$m) disabledMinutes.push(iMin);
      }
    }
    if (timeSelectedObject.$H === maxObject.$H) {
      // Selected maximum hour - disabled minutes specified in maxValue
      for (var iMax = 0; iMax < 60; iMax++) {
        if (iMax > maxObject.$m) disabledMinutes.push(iMax);
      }
    }
    // utils.log.info(`disabledMinutes`, disabledMinutes);
    return disabledMinutes;
  };
  // #endregion
  // #region Initialize

  // utils.log.info(`timeSelectedValue: ${timeSelectedValue}`);

  const timeSelectedObject = dayjs(timeSelectedValue, timeFormat)?.isValid()
    ? dayjs(timeSelectedValue, timeFormat)
    : undefined;

  // utils.log.info(`timeSelectedObject`, timeSelectedObject);
  /*eslint-disable-next-line*/
  const [disabledMinutes, setDisabledMinutes] = useState(
    disabledMinutesCalculate(timeSelectedValue, question.min, question.max)
  );
  // const disabledTime = () => {
  //   // PURPOSE: Return an array of disabled hours and minutes arrays
  //   const min = dayjs(minValue ?? question.min, timeFormat);
  //   const max = dayjs(maxValue ?? question.max, timeFormat);

  //   let disabledHours = [];

  //   // Calculate disabled hours
  //   if (hasDisabledTime) {
  //     for (var i = 0; i < 24; i++) {
  //       if (i < min.$H || i > max.$H) disabledHours.push(i);
  //     }
  //   }
  //   const result = {
  //     disabledHours: () => disabledHours,
  //     disabledMinutes: () => disabledMinutes, // Calculated in handleSelect(time, timeString),
  //   };
  //   // utils.log.disabled("disabledTime: ", result);
  //   return result;
  // };

  // #endregion
  // #region Events
  const handleSelect = (timeSelectedObject) => {
    if (!timeSelectedObject) return;

    const timeSelectedValue = timeSelectedObject?.format(timeFormat);
    // utils.log.event(
    //   `TimeInput.Picker.handleSelect(time: ${timeSelectedValue})`,
    //   timeSelectedObject
    // );
    const minObject = dayjs(minValue, timeFormat);
    if (timeSelectedObject?.isBefore(minObject)) {
      timeSelectedObject = minObject;
      // utils.log.info(
      //   `TimeInput.Picker.handleSelect(time: ${timeSelectedValue}) - timeSelectedObject is before minValue.  Setting timeSelectedObject to minValue.`,
      //   timeSelectedObject
      // );
    }
    let disabledMinutes = disabledMinutesCalculate(
      timeSelectedValue,
      minValue, //question.min,
      question.max
    );
    setTimeSelectedValue?.(timeSelectedValue);

    setDisabledMinutes(disabledMinutes);
    // utils.log.stateChange(
    //   `TimeInput.Picker.setTimeSelected: ${timeSelectedValue}`
    // );
    // utils.log.stateChange(
    //   `TimeInput.Picker.setDisabledMinutes`,
    //   disabledMinutes
    // );
  };
  const handleOpenChange = (open) => {
    // utils.log.event(
    //   `TimeInput.Picker.handleOpenChange(${open})`,
    //   timeSelectedObject
    // );
    handleChange(timeSelectedObject);
  };

  // #endregion
  return (
    <TimePicker
      use12Hours
      //hideDisabledOptions
      hideDisabledOptions
      changeOnBlur
      // size="large"
      format="h:mm A"
      style={{ height: "56px", width: width, background: "#F8F8F8" }}
      onChange={handleChange}
      onSelect={handleSelect}
      onOpenChange={handleOpenChange}
      defaultValue={timeSelectedObject}
      value={timeSelectedObject}
      disabled={isDisabled}
      disabledTime={disablehours}
      allowClear={false}
      placeholder={label}
      getPopupContainer={(triggerNode) => {
        // PURPOSE:  Used to position time picker relative to the question.
        // utils.log.disabled(
        //   `timeInput.js - Render - getPopupContainer(${triggerNode})`,
        //   triggerNode.parentNode
        // );
        return triggerNode.parentNode;
      }}
    ></TimePicker>
  );
};
export const DependencySelector = ({context}) => {
  // !NOTE: useState() is prohibited in this component.
  // const dependency = dependencyUtil.get(context);
  // utils.log.info(`dependency:`, dependency);
  // const question = dependency.question;
  // const list = utils.toSelectItem(comparators);
  // const comparator = dependency?.comparator ?? list[0].value;

  // Validate min/max exists
  // const questionMinValid = dayjs(question.min, timeFormat).isValid();
  // const questionMaxValid = dayjs(question.max, timeFormat).isValid();
  // const minValue = questionMinValid ? question.min : dayjs().format(timeFormat);
  // const maxValue = questionMaxValid
  //   ? question.max
  //   : dayjs().add(1, "hour").format(timeFormat);

  // const value = valueConvertByComparator(dependency, minValue, maxValue);
  // if (dependency != null && dependency?.value == null) {
  //   // Add a value if it doesn't exist
  //   dependency.comparator = list[0].value;
  //   dependency.value = null;
  // }
  // const handleComparatorChange = (e) => {
  //   const dependency = dependencyUtil.get(context);
  //   dependency.comparator = e.target.value;
  //   dependencyUtil.set(context, dependency);
  // };
  // const handleChange = (e) => {
  //   const value = e?.format(timeFormat);
  //   const dependency = dependencyUtil.get(context);
  //   utils.assert(
  //     typeof value === "string",
  //     `handleChange(${value}) - value is not a string.`
  //   );
  //   dependency.value = value; // TODO 071723 - Check to ensure we are storing object in string time format.
  //   dependencyUtil.set(context, dependency);
  // };
  return (
    <>
    <DependencySelectorComparatorValue context={context}/>
      {/* <FormSelect
        id="timeInput-comparators"
        label="Comparators"
        data={list}
        defaultValue={comparator}
        onChange={handleComparatorChange}
        style={{
          width: utils.getTextBoxWidth(comparator.length),
        }}
      ></FormSelect>{" "}
      {dependency.comparator === "between" && (
        <ComparatorBetween
          context={context}
          minValue={question.min}
          maxValue={question.max}
        ></ComparatorBetween>
      )}
      {dependency.comparator !== "between" && (
        // TODO: 070223 - REVIEW for issues with value.  ASSERT typeof value === string
        <Picker // Dependency Selector
          question={selectedQuestion}
          handleChange={handleChange}
          //defaultValue={value} // !ISSUE: dependency.value can be a string or an object (when comparator === "between")
          timeSelectedValue={value}
          minValue={minValue} // string time format
          maxValue={maxValue} // string time format
          hasDisabledTime
        ></Picker>
      )} */}
    </>
  );
};


const DependencySelectorComparatorValue = ({context}) => {

  // STEP:1 get dependency from context
  const dependency = dependencyUtil.get(context);
  const {question , comparator , value} = dependency || {};
  if(!question){
    return;
  }
  utils.log.info("^^ context",{context});
  const {min , max} = question || {};
  const [minValue] = useState(min);
  const [maxValue] = useState(max);
  const list = utils.toSelectItem(comparators);
  const [selectedComparator,setSelectedComparator] = useState(comparator ?? list[0].value);
  // const [selectedValue,setSelectedValue] = useState(value ? value: minValue);
  const [selectedValue,setSelectedValue] = useState(value ? value : question?.min);

  // if there is no comparator => then add comparator
  if(!comparator){
    dependency.comparator = "None";
    dependencyUtil.set(context, dependency);
  }
  // if there is no value => then add value
  // if(!value){
  //   dependency.value = min;
  //   dependencyUtil.set(context, dependency);
  // }

  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    const comparator = e.target.value;
    dependency.comparator = comparator;
    dependencyUtil.set(context, dependency);
    setSelectedComparator(comparator);
  };

  const handleValueChange = (e) => {
    const value = e?.format(timeFormat);
    utils.log.info("^^ dependency in TimeInput.js e",{value,e})
    const dependency = dependencyUtil.get(context);
    dependency.value = value; // TODO 071723 - Check to ensure we are storing object in string time format.
    dependencyUtil.set(context, dependency);
    setSelectedValue(value);
  }

  return (
    <div
    style={{
    display:"flex",
    gap:"20px"
  }}
    >
    <div id="dropdownComparators">
      <FormSelect
              id="timeInput-comparators"
              label="Comparators"
              data={list}
              value={selectedComparator}
              onChange={handleComparatorChange}
              style={{
                width: utils.getTextBoxWidth(selectedComparator.length),
              }}
            />
    </div>
      { selectedComparator === "between" && (
        <ComparatorBetween
          context={context}
          minValue={question.min}
          maxValue={question.max}
        />
      )}
      {selectedComparator !== "between" && selectedComparator !== "None" && (
        // TODO: 070223 - REVIEW for issues with value.  ASSERT typeof value === string
        <Picker
          question={question}
          handleChange={handleValueChange}
          //defaultValue={value} // !ISSUE: dependency.value can be a string or an object (when comparator === "between")
          timeSelectedValue={selectedValue}
          setTimeSelectedValue={setSelectedValue}
          minValue={minValue} // string time format
          maxValue={maxValue} // string time format
          hasDisabledTime
       />
      )}
      </div>
  )
}
export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}

export function Render(props) {
  //#region Assertions

  // #endregion Assertions
  // #region Initialize
  const question = props.question;
  const preview = props.preview ?? false;
  const helperText = "";
  question.answer ??= null;
  // Validate min/max exists
  const questionMinValid = dayjs(question.min, timeFormat).isValid();
  const questionMaxValid = dayjs(question.max, timeFormat).isValid();

  const minValue = questionMinValid
    ? question.min
    : dayjs()?.format(timeFormat);
  const maxValue = questionMaxValid
    ? question.max
    : dayjs().add(1, "hour")?.format(timeFormat);
  let answer = question.answer == null ? null : question.answer;
  // #endregion
  // #region Events
  const handleChange = (timeSelectedObject) => {
    utils.log.info("** time in handleChange in TimeInput",timeSelectedObject);
    if (!timeSelectedObject || timeSelectedObject === undefined) return;
    utils.assert(
      dayjs(timeSelectedObject).isValid(),
      "Invalid timeSelectedObject"
    );
    const value = timeSelectedObject?.format(timeFormat);
    updateQuestionAnswer(question, value, preview, props.setQuestions);
  };
  const isQuestionCompleted = isCompleted(question);

  // const {isSmDown} = useMediaQuery()

  // #endregion
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            {question?.code}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          <div>{helperText}</div>

          <Picker
            question={question}
            handleChange={handleChange}
            defaultValue={answer}
            minValue={minValue} // string time format
            maxValue={maxValue} // string time format
            hasDisabledTime
            // width={isSmDown ? "100%":"300px"}
            width="300px"
          ></Picker>
        </div>
      </div>
    </>
  );
}
// #region Dependency

export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  if (dependency == null) {
    return true;
  }
  utils.log.info("^^ dependency in TimeInput.js",dependency);
  const {question , value ,comparator} = dependency || {};
  const {answer ,min,max} = question || {};
  if(!answer || !min || !max)return false;


  const currentDate = dayjs().format("YYYY-MM-DD");
  const timeAnswer = dayjs(`${currentDate}T${answer}`);
  const timeMin = dayjs(`${currentDate}T${min}`);
  const timeMax = dayjs(`${currentDate}T${max}`);
  let timeValue;
  let timeValueMin;
  let timeValueMax;
  if(comparator !== "between"){
   timeValue = dayjs(`${currentDate}T${value}`);
  }else{
    timeValueMin = dayjs(`${currentDate}T${value?.min}`);
    timeValueMax = dayjs(`${currentDate}T${value?.max}`);
  }
  utils.log.info("^^ dependency in TimeInput.js",{dependency , timeAnswer , timeValue});

  // *** Evaluate ***
  switch (comparator) {
    case "None":
      return isWithinRange(timeAnswer ,timeMin , timeMax);
    case "equals":
      return isWithinRange(timeAnswer ,timeMin , timeMax) &&  handleComparatorEquals(timeAnswer ,timeValue);
    case "before":
      return  isWithinRange(timeAnswer ,timeMin , timeMax) && handleComparatorBefore(timeAnswer ,timeValue);
    case "after":
      return isWithinRange(timeAnswer ,timeMin , timeMax) &&  handleComparatorAfter(timeAnswer ,timeValue);
    case "not":
      return isWithinRange(timeAnswer ,timeMin , timeMax) && handleComparatorNot(timeAnswer ,timeValue);
    case "between":
      return isWithinRange(timeAnswer ,timeMin , timeMax) &&  handleComparatorBetween(timeAnswer,timeValueMin,timeValueMax);
    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
export const valueConvertByComparator = (dependency, minValue, maxValue) => {
  // PURPOSE: Convert value to object for between comparator and string for other comparators.
  /* PARAMETERS:
  dependency: required object
  minValue: required string
  maxValue: required string
*/
  // #region Assertions
  utils.assert(
    dependencyUtil.isDependencyObject(dependency),
    `Required parameter dependency is not an dependency object.`
  );
  utils.assert(
    dayjs(minValue, timeFormat).isValid(),
    `Parameter minValue is not a string in time format(${timeFormat}).`
  );
  utils.assert(
    dayjs(maxValue, timeFormat).isValid(),
    `Parameter minValue is not a string in time format(${timeFormat}).`
  );
  // #endregion Assertions
  if (dependency.comparator === "between") {
    if (typeof dependency.value !== "object") {
      // Initialize value to object for between comparator
      dependency.value = initValueRangeObject(minValue, maxValue);
      // utils.log.info(
      //   "Converted dependency.value to object for between comparator."
      // );
      //dependencyUtil.set(context, dependency);
    }
    return dependency.value;
  } else {
    // Comparator is NOT "between" and value is a string
    // Check if value is object and convert to string
    if (typeof dependency.value === "object") {
      dependency.value = minValue; //dependency.value.min;
      // utils.log.info(
      //   `Converted dependency.value to string for ${dependency.comparator} comparator.`
      // );
    }
    /*     return (dependency.value = utils
      .checkDateValidity(
        `dependency(${dependency.question.code}).value`,
        dependency.value,
        minValue
      )
      .format(config.defaults.DATE_STORAGE_FORMAT)); */
    // Set value to minValue if it is not a valid time
    if (!dayjs(dependency.value, timeFormat).isValid()) {
      // utils.log.warn(
      //   `dependency.value(${dependency.value}) is not a valid time.  Will be changed to minValue(${minValue}).`
      // );
      dependency.value = minValue;
    }

    return dependency.value;
  }
};

const isWithinRange = (timeAnswer, timeMin, timeMax) => {
  const isSameOrAfterMin = timeAnswer.isSame(timeMin) || timeAnswer.isAfter(timeMin);
  const isSameOrBeforeMax = timeAnswer.isSame(timeMax) || timeAnswer.isBefore(timeMax);
  return isSameOrAfterMin && isSameOrBeforeMax;
};

function handleComparatorEquals(timeAnswer ,timeValue) {
  return timeAnswer?.isSame(timeValue);
}

function handleComparatorBefore(timeAnswer ,timeValue) {
  return timeAnswer?.isBefore(timeValue);
}
function handleComparatorAfter(timeAnswer ,timeValue) {
  return timeAnswer?.isAfter(timeValue);
}
function handleComparatorNot(timeAnswer ,timeValue) {
   return !timeAnswer?.isSame(timeValue);
}
function handleComparatorBetween(timeAnswer,timeValueMin,timeValueMax) {

  return isWithinRange(timeAnswer,timeValueMin,timeValueMax);
  // if (dependency.question.answer == null) {
  //   return false;
  // }
  // // TODO: Determine if dependency.question.answer is string time format or dayjs object
  // const answer = dayjs(dependency.question.answer, timeFormat);
  // const minObject = dayjs(dependency.value.min, timeFormat);
  // const maxObject = dayjs(dependency.value.max, timeFormat);
  // const isAfterMin =
  //   answer.isAfter(minObject) || answer.isSame(minObject, "hour");
  // const isBeforeMax =
  //   answer.isBefore(maxObject) || answer.isSame(maxObject, "hour");
  // const result = isAfterMin && isBeforeMax;
  // // utils.log.info(
  // //   `handleComparatorBetween(${dependency.question.code}): ${result} <-- ${
  // //     dependency.value.min
  // //   } < ${answer.format(timeFormat)} < ${
  // //     dependency.value.max
  // //   } isAfterMin: ${isAfterMin}, isBeforeMax: ${isBeforeMax})`
  // // );
  // return result;
}



// PURPOSE: Component for between comparator
export const ComparatorBetween = ({ context, minValue, maxValue }) => {
  /*
  PARAMETERS:
    context - required object
    minValue - optional string time format
    maxValue - optional string time format
  */

  const dependency = dependencyUtil.get(context);

  if (typeof dependency.value !== "object" || !dependency.value) {
    dependency.value = initValueRangeObject(minValue, maxValue);
    dependencyUtil.set(context, dependency);
  }
  const startDefaultValue = dependency.value.min ?? minValue;
  const endDefaultValue = dependency.value.max ?? maxValue;

  const handleChange = (timeRangeObject) => {
    const startValue = timeRangeObject[0].format(timeFormat);
    const endValue = timeRangeObject[1].format(timeFormat);
    dependency.value.min = startValue;
    dependency.value.max = endValue;
    // TODO: 071223 Put logic to prevent end from selecting before start.  See TimePickerRange.Render for resolution.
    // Update dependency.value
    dependencyUtil.set(context, dependency);
  };
  return (
    <PickerRange
      question={dependency.question}
      startDefaultValue={startDefaultValue}
      endDefaultValue={endDefaultValue}
      handleChange={handleChange}
      hasDisabledTime
    />
  );
};

export const isCompleted = (question) => {
  if (!question) {
    return false;
  }
  const { answer, min, max } = question;
  if (answer == null) return false;
  const dayjsAnswer = dayjs(answer);
  if (min != null && dayjsAnswer.isBefore(dayjs(min))) {
    return false;
  }
  if (max != null && dayjsAnswer.isAfter(dayjs(max))) {
    return false;
  }
  return true;
};

export const getValue = (question) => {
  return {
    value: question.answer,
  };
};
// #endregion
