import React, { useContext } from "react";

import { RadioGroup } from "@mui/material";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { Choice, QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";
import { ChoiceSelector } from "./dependencySelector";
// import useMediaQuery from "../../hooks/useMediaQuery";
export function New() {
  return {
    type: "SingleChoice",
    code: null,
    title: "New Single Choice Question",
    description: "",
    instructions: "",
    dependency: null,
    choices: [],
    answer: null,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  // utils.log.component("Reset(SingleChoice):", question);
    question.choices = question?.choices?.map((choice) => {
    return{
    ...choice,
    isSelected:false
    }})
  utils.log.info(":: SC question",question);
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // utils.log.component("Edit(SingleChoice):", question);
  return null;
}
export const DependencySelector = ({ context, selectedQuestion }) => {
  // utils.log.component(":: DependecySelector(ChoiceSelector)",{context,selectedQuestion})
  // !NOTE: useState() is prohibited in this component.
  //Use to render Choices in Dependency Selector
  return (
    <ChoiceSelector
      context={context}
      selectedQuestion={selectedQuestion}
    ></ChoiceSelector>
  );
};

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);

  return dependency;
}

export function Render(props) {
  // utils.log.component("::SingleChoice.Render()", props);
  // #region Initialize

  const { index, question, rowStyle, preview } = props;
  const validationMessage = ""; //const [validationMessage, setValidationMessage] = useState(""); // REQUIRED
  // #endregion
  const isQuestionCompleted = isCompleted(props.question);
  /*eslint-disable-next-line*/
  // const {isSmDown} = useMediaQuery();

  // utils.log.info(":::: CategoryChoiceQuestions single",props.questions);
  return (
    <div
      key={index}
      style={{
        ...rowStyle,
        border: isQuestionCompleted ? "1px solid #3BDB41" : "1px solid #E9E9E9",
      }}
    >
      {!preview && (
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {question.code}
        </div>
      )}
      <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
        <QuestionHeader question={question}></QuestionHeader>
        <RenderChoices {...props}></RenderChoices>
        {validationMessage}
      </div>
    </div>
  );
}

export const RenderChoices = (props) => {
  // utils.log.component("SingleChoice.RenderChoices()", props);
  // TODO: question.isDropdown is not implemented
  // TODO: Allow recipient to select custom comment (question.isCommentAllowed)
  // #region Initialize

  const { surveyService } = useContext(AuthContext);
  const preview = props.preview ?? false;
  const question = props.question;
  // utils.log.info(":: question in RenderChoices",{question});
  const choices = question.choices; //const [choices, setChoices] = useState(question.choices);
  const selectedChoice = choices.find((c) => c.isSelected) ?? null; // !KLUDGE 060623 - Until we can resolve the functions being stripped by spread operator.
  const selectedValue = selectedChoice?.valueFormatted?.() ?? "";
  const choicesFiltered = preview
    ? choices // Show all choices in preview mode
    : choices.filter((c) => c.isVisible);
  // // utils.log.info(
  //   `RenderChoices: question: ${props.question.code} isVisible: ${
  //     props.question.isVisible
  //   } selectedChoice: ${selectedChoice?.valueFormatted(".") ?? "- none -"} `
  // );
  // #endregion
  // #region Events

  const handleSelected = (choice) => {
    // const choiceFormatted = choice.valueFormatted?.(".");

    // utils.log.event(`handleSelected(${choiceFormatted})`, choice);

    // *** Update question state ***
    if (preview) {
      props.setQuestions((qun) => {
        const updatedChoices = props.question.choices.map((c, index) => {
          if (c.code === choice.code) {
            return { ...c, isSelected: true };
          } else {
            return { ...c, isSelected: false };
          }
        });
        return {
          ...qun,
          choices: updatedChoices,
        };
      });
    } else {
      props.setQuestions((prevQuestions) => {
        const questionCode = choice?.question && choice?.question()?.code;
        // *** Set selected choice ***
        let updatedQuestions = prevQuestions.map((prevQuestion) => {
          if (prevQuestion.code === questionCode) {
            // Create a new copy of the choices array with updated isSelected value
            const updatedChoices = prevQuestion.choices.map((c, index) => {
              // utils.log.info(`Checking choice(${c.code} index: ${index})`, c);
              if (c.code === choice.code) {
                // utils.log.info(
                //   `handleSelected(${choiceFormatted}): Choice(${questionCode}.${c.code}) found and set to isSelected = true`,
                //   config.cssStyle.success
                // );
                return { ...c, isSelected: true };
              } else return { ...c, isSelected: false };
            });
            const updatedChoicesSelected = updatedChoices.filter(
              (c) => c.isSelected
            );
            utils.assert(
              updatedChoicesSelected != null,
              "handleSelected: No choice was selected."
            );
            utils.assert(
              updatedChoicesSelected.length === 1,
              `handleSelected: Only a single choice(${updatedChoicesSelected.length}) is allowed.`
            );

            // Update the choices array of the parent question
            return { ...prevQuestion, choices: updatedChoices };
          }
          return prevQuestion;
        });

        // Resolve visibility for all questions
        // if (!preview) dependencyUtil.resolveVisibilities(updatedQuestions);

        // utils.log.stateChange(
        //   `*** STATE CHANGE *** handleSelected(${choiceFormatted})`,
        //   updatedQuestions
        // );

        return updatedQuestions;
      });
    }
  };
  // #endregion

  return (
    <>
      <RadioGroup defaultValue="" value={selectedValue} row>
        {choicesFiltered.map((choice) => {
          return (
            <Choice
              key={choice.code}
              choice={choice}
              handleSelected={handleSelected}
              renderAs={question.isDropdown ? "dropdown" : "radio"}
            ></Choice>
          );
        })}
        {question.isCommentAllowed && (
          <Choice
            key={"choice.code"}
            choice={surveyService.newChoice("code", "value", "description")}
            handleSelected={handleSelected}
            renderAs="comment"
          ></Choice>
        )}
      </RadioGroup>
    </>
  );
};

// PURPOSE: Resolve dependency of Single Choice Question
export function isAnswered(dependency) {
  if (dependency == null) {
    // return false;
    return true;
  }
  const dependencyType = dependencyUtil.getType(dependency);

  switch (dependencyType) {
    // When We choose question only and no choices
    case "question":
      // TODO(LOW): When we figure out how to restore survey functions, convert checkedCount to that.
      if (dependency?.question?.choices?.find((choice) => choice?.isSelected)) {
        return true;
      }
      break;
    // When we choose both question and choices as dependency
    case "choice":
      if (
        dependency?.question?.choices?.find(
          (choice) => choice.code === dependency?.choice?.code
        )?.isSelected
      ) {
        return true;
      }
      break;
    default:
      throw new Error(
        `SingleChoice.isAnswered: Invalid dependency type: ${dependency.type}`
      );
  }
  return false;
}

export const isCompleted = (question) => {
  // utils.log.component(":: Running SingleChoice.isCompleted()", question);
  if (!question) {
    // utils.log.warn("SingleChoice.isCompleted(false): question is null");
    return false;
  }
  const isChoiceSelected = question.choices.find((c) => c.isSelected);

  return Boolean(isChoiceSelected);
};

export const getValue = (question) => {
  return {
    value: question.choices.find((c) => c.isSelected)?.code || "",
  };
};
// #endregion
