import { useConfirm } from "material-ui-confirm";
import { useContext, useEffect, useState } from "react";
import Button from "../../../components/Button";
import Grid from "../../../components/Grid";
import Input from "../../../components/Input/inputs";
import Table from "../../../components/Table";

import { AuthContext } from "../../../services/context";
import MUIDialog from "../../../components/Modal";
import { enqueueSnackbar } from "notistack";
import { HeaderActions } from "../../../components/HeaderActions";
export default function Exemptions() {

  const updateList = async () => {
    const result = await exemptionService.getAll();
    setList(result);
  };
  const confirm = useConfirm();
  const { exemptionService } = useContext(AuthContext);
  const [openDetail, setDetailOpen] = useState(false);
  const [openAdd, setAddOpen] = useState(false);
  const [detail, setDetail] = useState("");
  const [list, setList] = useState([]);

  const columns = [
    { Header: "Code", accessor: "code" },
    { Header: "Reason", accessor: "reason" },
    { Header: "Description", accessor: "description" },
  ];
  useEffect(() => {
    updateList();
  }, [openAdd, openDetail]);
  const handleAddOpen = () => {
    setDetail(null);
    setAddOpen(true);
  };
  const handleClose = () => {
    setAddOpen(false);
    setDetailOpen(false);
    updateList();
  };

  const handleEdit = (row) => {
    const exemption = row?.original
    setDetail(exemption);
    setDetailOpen(true);
  };
  const handleDelete = (row) => {
    const code = row?.code;
    confirm({
      description: `Confirm deletion of survey ${row?.name}.`,
    }).then(() => {
      exemptionService
        .remove(code)
        .then(async (result) => {
          await updateList(); // Ensure Table component below has a conditional on list.
          //setSurveyOpen(false);
          //// utils.log.stateChange("setSurveyOpen(false)");
        })
        .catch((error) => {
          // utils.log.error(error);
        });
    });
    // .catch(() => // utils.log.warn("Survey deletion cancelled."));
  };

  const headerAction = (
    <HeaderActions
      buttonTitle={"Add"}
      onAdd={handleAddOpen}
    />
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={list}
            selected
            onSelected={(row) => handleEdit(row)}
            headerAction={headerAction}
            caption={"Exemption User List"}
            actions={{
              delete: handleDelete,
              edit:handleEdit
            }}
          ></Table>
        </Grid>
      </Grid>
      {openAdd && (
        <Add
          open={openAdd}
          onClose={handleClose}
        />
      )}
      {openDetail && (
        <Details
          exemption={detail}
          open={openDetail}
          onClose={handleClose}
        />
      )}
    </>
  );
}
const Details = ({ exemption, open, onClose }) => {

  const {  exemptionService } = useContext(AuthContext);
  const [inputs, setInputs] = useState({
    code: exemption?.code,
    description: exemption?.description,
    reason: exemption?.reason,
  });
  const handleUpdate = async () => {
    let payload = {...exemption , ...inputs};
    const response = await exemptionService.update(payload);
    if(response?.ok){
      onClose();
    }
  };
  const handleChange = (e) => {
    const {name , value} = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]:value
    }))
  }
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Update Exemption"
      description="Exemptions allow recipients to excuse themselves from participating in
    a survey."
      maxWidth="sm"
      actions={
        <>
          <Button 
          variant="contained-primary"
          style={{
            borderRadius:"4px"
          }}
          onClick={handleUpdate}>Update</Button>
        </>
      }
      PaperProps={{ style: { height: "450px" } }}
    >
      <Grid container spacing={3} style={{padding:"10px"}}>
        <Grid item xs={12} >
          <Input
            label="Code"
            fullWidth
            name="code"
            disabled
            onChange={handleChange}
            defaultValue={inputs?.code}
          />
        </Grid>
        <Grid item xs={12} >
          <Input
            label="Reason"
            fullWidth
            name="reason"
            onChange={handleChange}
            defaultValue={inputs?.reason}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            label="Description"
            fullWidth
            name="description"
            onChange={handleChange}
            defaultValue={inputs?.description}
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

const Add = ({ open, onClose }) => {
  // utils.log.component(`Add()`);
  const { exemptionService } = useContext(AuthContext);
  const [exemptionDetails, setExemptionDetails] = useState({
    code: "",
    description: "",
    reason: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExemptionDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async () => {
    const response = await exemptionService.add(exemptionDetails);
    if (response?.ok) {
      enqueueSnackbar("Exemption Added Successfully", { variant: "success" });
      onClose();
    }
  };
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Add Recipient Exemption"
      description="Define recipient exemption instructions."
      actions={
        <>
          <Button 
          variant="contained-primary"
          style={{
            borderRadius:"4px"
          }}
          onClick={handleSubmit}>Add</Button>
        </>
      }
      height={"400px"}
    >
      <Grid container spacing={2} sx={{padding:"5px 13px"}}>
        <Grid item xs={12} >
          <Input
            label="Code"
            fullWidth
            name="code"
            value={exemptionDetails?.code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Reason"
            fullWidth
            name="reason"
            value={exemptionDetails?.reason}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Input
            label="Description"
            fullWidth
            name="description"
            value={exemptionDetails?.description}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};
