import { getApiOrigin } from "./context";

export default class DepartmentService {
  constructor(headers) {
    this.headers = headers;
  }

  async get(departmentId) {
    const remoteUrl = `${getApiOrigin()}/worksite/department/${departmentId}?viewModel=Organization.ViewModels.Worksite`;

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }
  async getAll() {
    const remoteUrl = `${getApiOrigin()}/department?viewModel=Organization.ViewModels.Worksite`;

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }

  async getByWorksite_OLD(worksiteCode, surveyCode = null) {
    // utils.log.api(`department.getByWorksite(${worksiteCode})`);
    let remoteUrl = `${getApiOrigin()}/department/worksite/${worksiteCode}`;

    if (surveyCode !== null) {
      remoteUrl += `?surveyCode=${surveyCode}`;
    }

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }
  async getByWorksite(worksiteCode, surveyCode = null) {
    try {
      // utils.log.api(
      //   `department.getByWorksite(workSite: ${worksiteCode}), survey: ${surveyCode})}`
      // );

      let remoteUrl = `${getApiOrigin()}/department/worksite/${worksiteCode}?`;
      if (surveyCode !== null) {
        remoteUrl += `surveyCode=${surveyCode}`;
      }

      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        // utils.log.error(
        //   `Request failed with status ${response.status}`,
        //   response
        // );
      }

      return response;
    } catch (error) {
      console.error("Error in getByWorksite:", error);
      throw error;
    }
  }

  async add(worksiteCode, department) {
    // utils.log.api(
    //   `department.add(${worksiteCode}, department: ${department.code}})`,
    //   department.code
    // );
    const remoteUrl = `${getApiOrigin()}/department/worksite/${worksiteCode}`;

    return await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(department),
    });
  }
  async update(department) {
    // utils.log.api(
    //   `department.update(department: ${department.code}})`,
    //   department
    // );

    const remoteUrl = `${getApiOrigin()}/department`;
    const response = await fetch(remoteUrl, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(department),
    });
    //    // utils.log.debug("department.update response", response);
    if (!response.ok) throw new Error("Failed to update department.");
    //return await response.json();
  }

  async remove(department) {
    // utils.log.api(`department.remove(${department.id})`, department);
    const remoteUrl = `${getApiOrigin()}/worksite/${
      department.worksite
    }/department/${department.id}`;

    return await fetch(remoteUrl, {
      method: "DELETE",
      headers: this.headers,
    });
  }

  async getAllDepartments() {
    const remoteUrl = `${getApiOrigin()}/department?viewModel=Organization.ViewModels.Department`;

    /*     return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    }); */
    // try {
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
    return response;
    // } catch (error) {
    //   // utils.log.error(error);
    //   throw error;
    // }
  }
}
