
const getPlanButtonName = (allPlans,activePlanCode) => {
    const allPlanCode = allPlans?.map((plan) => plan?.code);
        return allPlanCode?.findIndex((planCode) => planCode === activePlanCode);
}

export const formatPlanData = (allPlans,preview,activePlan) =>{

    const activePlanIndex = getPlanButtonName(allPlans,activePlan?.code);

    return allPlans.map((items,index) => {
        return {
          ...items,

          heading: items?.code || "Starter",

          description:
            items?.description ||
            "Get your starter plan for getting small scale services.",

          price: `$${items?.price}`,

          btnText: preview ? "Choose Plan" : activePlanIndex === index ? "Selected" : activePlanIndex < index ? "Upgrade" : "Downgrade",

          services: [
            {
              service: `Up to ${
                items?.surveyMax.toLocaleString() || 0
              } surveys`,
            },
            {
              service: `Up to ${
                items?.coordinatorMax.toLocaleString() || 0
              } coordinators`,
            },
            {
              service: `Up to ${
                items?.questionMax.toLocaleString() || 0
              } questions/survey`,
            },
            /*               {
              service: `${
                items?.dataRetentionDays || 0
              } > Data Retentions Days`,
            }, */
            {
              service: `${
                items?.surveyRecipientMax.toLocaleString() || 0
              } recipients/survey`,
            },
            {
              service: `${
                items?.includedSurveyResponses.toLocaleString() || 0
              } survey responses included`,
            },
            /*               {
              service: `${items?.reportsIncluded || 0} Reports Included`,
            }, */
            /*               {
              service: `${items?.dataRetentionDays || 0} day data retention`,
            }, */
            /*               {
              service: items?.enableLiveResults
                ? "Live Results"
                : "Live Results (Not Included)",
            },
            {
              service: items?.includeLayouts
                ? "Include Layouts"
                : "Layouts (Not Included)",
            }, */
            /*               {
              service: items?.reportsIncluded
                ? "Reports Included"
                : "Reports (Not Included)",
            }, */
          ],
        };
      });
}