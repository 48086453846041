import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Grid from "../Grid";

import PropTypes from "prop-types";
import Button from "../Button";

export default function Popup({ options }) {
  // utils.log.component("Popup", options);

  return (
    <Dialog
      open={options.open}
      onClose={options.onClose}
      fullWidth={options.fullWidth ?? false}
      maxWidth={options.maxWidth ?? "sm"}
    >
      <DialogTitle>{options.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{options.dialogContentText}</DialogContentText>

        <Grid container spacing={2}>
          {options.gridItems}
          {options.error && (
            <Grid item xs={12}>
              {options.error}
            </Grid>
          )}
        </Grid>
        <DialogActions>
          <Button onClick={options.onClose}>Close</Button>
          {options.dialogActions}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

Popup.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  dialogTitle: PropTypes.string,
  dialogContentText: PropTypes.string,
  dialogActions: PropTypes.array,
  gridItems: PropTypes.array,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};
