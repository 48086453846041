import { Button as MUIButton } from "@mui/material";
import PropTypes from "prop-types";

export default function Button({
  variant = "contained",
  size = "medium",
  fullWidth = false,
  className,
  ...rest
}) {
  return (
    <MUIButton variant={variant} size={size} fullWidth={fullWidth} className={className} {...rest} />
  );
}

Button.propTypes = {
  variant: PropTypes.oneOf(["contained", "contained-primary","contained-green","contained-white","text", "outlined", "text", PropTypes.string]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fullWidth: PropTypes.bool,
  className:PropTypes.string,
};
