import { getApiOrigin } from "./context";
export default class PaymentService {
  constructor(uid, accessCode) {
    this.uid = uid;
    this.accessCode = accessCode;
  }


  async completed(survey) {
    debugger;
    const remoteUrl = `${getApiOrigin()}/recipient/survey/completed`;
    return await fetch(remoteUrl, {
      method: "POST", // NOTE: Intentionally POST over PUT due to back-end handling needs.
      body: JSON.stringify(survey),
      headers: { "Content-Type": "application/json" },
    });
  }
}
