import React from "react";
import { Box, Typography, Container, Link, Paper, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { makeStyles } from "@mui/styles";
import { CONTACTUS_INFO } from "../utils/contentData";

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: "150px",
  },
  title: {
   fontFamily:'Public-sans',
    marginBottom: "50px",
    fontWeight: "bold",
    color: "#333",
    fontSize: "2.5rem",
    "@media (max-width: 600px)": {
      fontSize: "2rem",
    },
  },
  card: {
    padding: "30px",
    textAlign: "center",
    borderRadius: "20px",
    background: "linear-gradient(145deg, #ffffff, #f0f0f0)",
    boxShadow: "8px 8px 16px #d9d9d9, -8px -8px 16px #ffffff",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "8px 8px 20px #d0d0d0, -8px -8px 20px #ffffff",
    },
  },
  icon: {
    fontSize: "2rem",
  },
  cardTitle: {
    fontFamily:'Public-sans',
    marginTop: "12px",
    fontWeight: "bold",
    color: "#333",
  },
  cardContent: {
    marginTop: "8px",
  },
}));

export default function ContactUs() {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h4" align="center" className={classes.title} >
        Contact Us
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {/* Email Card */}
        <Grid item xs={12} sm={6}>
          <Paper className={classes.card}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <EmailIcon className={classes.icon} color="primary" />
              <Typography variant="subtitle1" className={classes.cardTitle}>
                Email
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.cardContent}
              >
                <Link
                  href={`mailto:${CONTACTUS_INFO?.email}`}
                  color="primary"
                  underline="none"
                >
                  {CONTACTUS_INFO?.email}
                </Link>
              </Typography>
            </Box>
          </Paper>
        </Grid>

        {/* Phone Card */}
        <Grid item xs={12} sm={6}>
          <Paper className={classes.card}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <PhoneIcon className={classes.icon} color="primary" />
              <Typography variant="subtitle1" className={classes.cardTitle}>
                Phone
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.cardContent}
              >
                <Link
                  href={`tel:${CONTACTUS_INFO?.phone}`}
                  color="primary"
                  underline="none"
                >
                  {CONTACTUS_INFO?.phone}
                </Link>
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
