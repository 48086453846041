/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import FormSelect from "../../components/Select";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { makeStyles } from "@mui/styles";
import PricingCard from "../../components/PricingCard";

export default function Plans() {
  return <h1>Plans</h1>;
}

export const PlanSelector = ({ selectedPlan, handleSelected }) => {
  const { planService } = useContext(AuthContext);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    // utils.log.useEffect(`PlanSelector`, { plans });
    const fetchPlans = async () => {
      try {
        const plans = await planService.getSubscriptionPlans();
        setPlans(plans);
        // utils.log.stateChange(`PlanSelector.Init`, plans);
      } catch (error) {
        // utils.log.error("Error fetching and setting plans:", error);
      }
    };
    fetchPlans();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();

    handleSelected(e.target.value);
  };

  if (plans.length === 0) {
    return <>- No plans found. -</>;
  }

  return (
    <FormSelect
      label="Subscription plans"
      data={utils.toSelectItem(plans, "code", "code")}
      fullWidth
      onChange={handleChange}
    ></FormSelect>
  );
};

// export const PlanSelector2 = ({ plans, employee }) => {
//   const classes = useStyles();

//   const [isLoading, setIsLoading] = useState(false);
//   const { planService } = useContext(AuthContext);

//   // const allPlanCode = ["Free","Early Access(Monthly)","Early Access(Annual)"];
//   // let planIndex = 1;
//   // allPlanCode.forEach((planCode,index) =>{
//   //   if(planCode === employee?.plan?.code){
//   //     planIndex = index;
//   //   }
//   // })

//   const handlePayment = async (item) => {
//     setIsLoading(true);
//     try {
//       const response = await planService.subscribe(item?.code, employee.email,employee?.organization?.code);

//       if (!response) return;
//       const data = await response.json();

//       if (data) {
//         window.open(data.redirect);
//       }
//     } catch (error) {
//       // utils.log.error(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   if (plans.length === 0) {
//     return <>- No plans found. -</>;
//   }
//   // const plansArr = plans?.map((items, index) => {
//   //   return {
//   //     heading: items?.code || "Starter",
//   //     description:
//   //       items?.description ||
//   //       "Get your starter plan for getting small scale services.",
//   //     price: `$${items?.price}`,
//   //     code: items?.code,
//   //     btnText: items?.code === isLoading ? "Please Wait" : "Choose Plan",
//   //     services: [
//   //       { service: `Up to ${items?.surveyMax.toLocaleString() || 0} surveys` },
//   //       {
//   //         service: `Up to ${
//   //           items?.coordinatorMax.toLocaleString() || 0
//   //         } coordinators`,
//   //       },
//   //       {
//   //         service: `Up to ${
//   //           items?.questionMax.toLocaleString() || 0
//   //         } questions/survey`,
//   //       },
//   //       /*         {
//   //         service: `${items?.dataRetentionDays || 0} > Data Retentions Days`,
//   //       }, */
//   //       {
//   //         service: `Up to ${
//   //           items?.surveyRecipientMax.toLocaleString() || 0
//   //         } recipients/survey`,
//   //       },
//   //       {
//   //         service: `${
//   //           items?.includedSurveyResponses.toLocaleString() || 0
//   //         } survey responses included`,
//   //       },
//   //       /*         {
//   //         service: `${items?.reportsIncluded || 0} Reports Included`,
//   //       }, */
//   //       /*         { service: `${items?.dataRetentionDays || 0} day data retention` }, */
//   //       /*         {
//   //         service: items?.enableLiveResults
//   //           ? "Live Results"
//   //           : "Live Results (Not Included)",
//   //       },
//   //       {
//   //         service: items?.includeLayouts
//   //           ? "Include Layouts"
//   //           : "Layouts (Not Included)",
//   //       }, */
//   //       /*         {
//   //         service: items?.reportsIncluded
//   //           ? "Reports Included"
//   //           : "Reports (Not Included)",
//   //       }, */
//   //     ],
//   //   };
//   // });

//   const handleClickParams = (item) => {
//     handlePayment(item);
//   };

//   return (
//     <>
//       <div className={classes.dialogContent}>
//         <div className={classes.containerDialog}>
//           <div className={classes.plansWrapper}>
//             <p>Pricing Plan</p>
//           </div>
//           <PricingCard
//             pricingArr={plans}
//             showAd={false}
//             handleClickParams={handleClickParams}
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// const useStyles = makeStyles((theme) => ({
//   contentContainer: {
//     marginTop: theme.spacing(2),
//     padding: theme.spacing(1, 2.5),
//   },
//   containerDialog: {
//     display: "flex",
//     justifyContent: "center",
//     flexDirection: "column",
//     // background: "white",
//   },
//   dialogContent: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   descriptionCard: {
//     color: "#7e7e7e",
//     fontWeight: 600,
//     fontSize: "20px",
//     "& p": {
//       paddingBottom: "0px",
//     },
//   },
//   dialogWrapper: {
//     width: "500px",
//     height: "200px",
//     "& h2": {
//       margin: 0,
//       fontWeight: 600,
//       fontSize: "22px",
//       display: "flex",
//       justifyContent: "center",
//       marginTop: "36px",
//     },
//   },
//   paymentCancelIcon: {
//     border: "2px solid red",
//     borderRadius: "50%",
//     margin: "auto",
//     display: "flex",
//     marginBottom: "15px",
//   },
//   successIcon: {
//     margin: "auto",
//     display: "flex",
//     marginBottom: "15px",
//   },
//   PricingContainer: {
//     display: "flex",
//     alignItems: "center",
//     gap: "20px",
//     margin: "20px 15px",
//   },
//   CardDescription: {
//     border: "1px solid #D6D6D6",
//     padding: "25px",
//     borderRadius: "25px",
//     paddingBottom: "25px",
//     width: "300px",
//     "& h4": {
//       paddingTop: "10px",
//       fontSize: "20px",
//       fontWeight: 600,
//       paddingBottom: "20px",
//       color: "#000",
//     },
//     "& h3": {
//       fontSize: "26px",
//       fontWeight: 600,
//       color: "#000",
//     },
//     "& p": {
//       paddingBottom: "0px",
//       fontSize: "16px",
//       fontWeight: 500,
//     },
//   },

//   title: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     borderBottom: "1px solid #ccc",
//     padding: theme.spacing(1),
//     "& p": {
//       margin: 0,
//       fontWeight: 700,
//       fontSize: 24,
//     },
//   },
//   plansWrapper: {
//     padding: theme.spacing(1),
//     "& p": {
//       margin: 0,
//       fontWeight: 700,
//       fontSize: 30,
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       color: "#000",
//       padding: "20px 0px",
//     },
//   },
//   actionPaymentButtons: {
//     marginTop: theme.spacing(4),
//     gap: theme.spacing(2),
//     "& button": {
//       border: "1px solid",
//       borderColor: "transparent",
//       borderRadius: "20px",
//       width: "250px",
//     },
//   },
//   cancelPaymentButtons: {
//     marginTop: theme.spacing(4),
//     marginBottom: theme.spacing(3),
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     gap: theme.spacing(2),
//     "& button": {
//       border: "1px solid",
//       borderRadius: "10px",
//       borderColor: "transparent",
//       width: "150px",
//       height: "45px",
//       "&:hover": {},
//     },
//   },
// }));
