// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*
ISSUES:
BUG: 051323 - Crash when trying to edit a choice.
BUG: 050423 - When in Choice detail view, 
  on Delete button click, dialog box closes to Questions list.  
  Expected to close to Choices list.
handleChoiceDelete() closes 
  STEPS:
  CAUSE: 
  RESOLUTION:
  FIX:

*/

/*eslint-disable*/
import { Grid } from "@mui/material";
import { useContext, useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input/inputs";
import Table from "../../components/Table";
import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import { CommentEditView as Comment } from "./comment";
import * as dependency from "./dependency";
import { Context, Selector as DependencySelector } from "./dependencySelector";
import FormUtility from "../../services/form";
import { HeaderActions } from "../../components/HeaderActions";
import MUIDialog from "../../components/Modal";
// import { SurveyContext } from "../survey";
// import surveyConte
const formUtility = new FormUtility();

/* Here, question = selectedQuestion && setQuestion = setSelectedQuestion */
export const Choices = ({ question, setQuestion, questions, onDragEnd ,selectedSurveyCode }) => {
  // utils.log.component(":: Survey.Questions.Choice()");
  // #region Initialize

  // const { selectedSurveyCode} = useContext(SurveyContext);
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [originalChoice, setOriginalChoice] = useState(null);
  const columns = [
    { Header: "Code", accessor: "code" ,ids:"columnCode" },
    { Header: "Description", accessor: "description" ,ids:"columnDescription"},
    { Header: "Value", accessor: "value" ,ids:"columnValue" },
  ];
  const choices =
    question.choices?.length > 0 ? utils.cloneObject(question.choices) : []; // use spread to copy question.choices

  const handleAddOpen = () => {
    setAddOpen(true);
  };
  // const handleAddClose = () => {
  //   setAddOpen(false);
  // };
  const handleEdit = (choice) => {
    setSelectedChoice(choice);
    setOriginalChoice(choice);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    const choiceIndex = question.choices.findIndex(
      (c) => c.code === selectedChoice.code
    );
    question.choices[choiceIndex] = selectedChoice;
    setEditOpen(false);
  };

  const headerAction = (
    <HeaderActions buttonIds="buttonAdd"  buttonTitle={"Add"} onAdd={handleAddOpen} />
  );
  utils.log.info("^^ question choices",question);

  // #endregion
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} justifyContent="flex-end">
          {addOpen && (
            <Add
              open={addOpen}
              // onClose={handleAddClose}
              setAddOpen={setAddOpen}
              list={question.choices}
              setQuestion={setQuestion}
              questions={questions}
            ></Add>
          )}

          {editOpen && (
            <Details
              open={editOpen}
              onClose={handleEditClose}
              selectedChoice={selectedChoice}
              setSelectedChoice={setSelectedChoice}
              originalChoice={originalChoice}
              question={question}
              setQuestion={setQuestion}
              questions={questions}
            ></Details>
          )}
        </Grid>
        {/* {hasChoices && ( */}
        <Grid item xs={12}>
          <Table
            columns={columns}
            data={choices}
            hideFilter
            hidePagination
            selected
            onSelected={(row) => handleEdit(row.original)}
            isDraggable
            onDragEnd={(result) => onDragEnd(result.map((r) => r.original))}
            headerAction={headerAction}
            testingIds={`Survey.${selectedSurveyCode}.Question${question?.code}.Choice`}
          ></Table>
        </Grid>
        {/* )} */}
        <Grid item xs={12}>
          <Comment question={question}></Comment>
        </Grid>
      </Grid>
    </>
  );
};

const Details = ({
  open,
  onClose,
  selectedChoice,
  setSelectedChoice,
  originalChoice,
  question,
  setQuestion,
  questions,
}) => {
  // utils.log.component("Question(Choice.Details) component");
  // #endregion
  // #region Initialize
  const { error } = useContext(AuthContext);
  const choiceOriginal = utils.cloneObject(selectedChoice);
  const [codeErrorObject, setCodeErrorObject] = useState([]);
  /*eslint-disable-next-line*/
  const [valueErrorObject, setValueErrorObject] = useState(null);

  const isValid = () => {
    // if (error.hasErrors()) {
    // utils.log.error(
    //   `Validation errors: ${error.hasErrors()}`,
    //   error.getErrors()
    // );
    // }

    return !error.hasErrors();
  };

  const handleUpdate = (event, question, choice, choiceOriginal) => {
    event.preventDefault();
    if (isValid()) {
      const updatedChoices = question?.choices?.map((choice) => {
        return choice?.code === originalChoice?.code ? selectedChoice : choice;
      });
      setQuestion((prev) => ({
        ...prev,
        choices: updatedChoices,
      }));
      onClose();
    } else {
      // utils.log.error("Choices.Details.handleUpdate()", error.getErrors());
    }
  };

  const handleDelete = () => {
    const updatedChoices = question?.choices?.filter(
      (choice) => choice?.code !== originalChoice?.code
    );
    setQuestion((prev) => ({
      ...prev,
      choices: updatedChoices,
    }));
    onClose();
  };

  const handleChoiceChange = (e) => {
    const { name, value } = e.target;

    setSelectedChoice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChange = function (e) {
    const { name, value } = e.target;
    const updatedErrors = codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    setCodeErrorObject(updatedErrors);
    setSelectedChoice((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const choicesExcludingOriginal = question?.choices?.filter(
      (choice) => choice?.code !== originalChoice?.code
    );
    const duplicateChoice = choicesExcludingOriginal?.find(
      (choice) =>
        choice?.code?.toLowerCase() === selectedChoice?.code?.toLowerCase()
    );
    if (!value) {
      //value can't be empty
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    } else if (name === "code" && duplicateChoice) {
      //value can't be duplicate
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject("code"),
      ]);
    } else {
      //if not any error for this name => then clear the error for the given name
      error.clearErrorObject(name);
      const filteredErrors = codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      setCodeErrorObject(filteredErrors);
    }
  };

  const getHelperTextMessage = (name) => {
    return codeErrorObject?.find((error) => error?.name === name)?.message;
  };
  const hasError = (name) => {
    return !!codeErrorObject?.find((error) => error?.name === name)?.state;
  };
  const context = Context(question,selectedChoice);
  // #endregion
  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      title="Update Choice"
      description="Choice instructions..."
      actions={
        <>
          <Button id="buttonDelete" onClick={handleDelete}>Delete</Button>
          <Button id="buttonCancel" onClick={onClose}>Cancel</Button>
          <Button id="buttonUpdate"
            onClick={(e) =>
              handleUpdate(e, question, selectedChoice, choiceOriginal)
            }
          >
            Update
          </Button>
        </>
      }
    >
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} id={"textboxCode"}>
          <Input
            required
            label="Code"
            fullWidth
            name="code"
            onChange={(e) => handleInputChange(e)}
            defaultValue={selectedChoice.code}
            value={selectedChoice.code}
            error={hasError("code")}
            helperText={getHelperTextMessage("code")}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6} id={"textboxValue"}>
          <Input
            required
            label="Value"
            fullWidth
            name="value"
            onChange={(e) => handleInputChange(e)}
            defaultValue={selectedChoice.value}
            value={selectedChoice.value}
            error={hasError("value")}
            helperText={getHelperTextMessage("value")}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={12} id={"textboxDescription"}>
          <Input
            label="Description"
            fullWidth
            name="description"
            onChange={(e) => handleChoiceChange(e)}
            defaultValue={selectedChoice.description}
          />
        </Grid>
        <Grid item xs={12}>
          <DependencySelector
            // context={dependency.create(question, selectedChoice)}
            context={context}
            questions={questions}
            selectedQuestion={selectedChoice}
            setSelectedQuestion={setSelectedChoice}
          ></DependencySelector>
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

const Add = ({ open, setAddOpen, list }) => {
  // utils.log.component(":: Survey.Question.Choice.Add()", list);
  // #region Initialize

  const { surveyService, error } = useContext(AuthContext);
  const [inputs, setInputs] = useState({});
  const [codeErrorObject, setCodeErrorObject] = useState([]);
  const [addedChoice, setAddedChoice] = useState(
    surveyService.newChoice(inputs.code, inputs.value, inputs.description)
  );

  formUtility.setDetail(null);
  formUtility.setInputs(inputs);

  const onClose = () => {
    setAddOpen(false);
    //onClose => clear the errorObject &&  setCodeErrorObject = [];
    error.clearErrorObject();
    setCodeErrorObject([]);
  };

  const isValid = () => {
    const name = formUtility.getValue("code")
      ? formUtility.getValue("value")
        ? ""
        : "value"
      : "code";
    if (name) {
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    }
    // error.clearErrorObject("code");
    // error.clearErrorObject("value");

    // setCodeErrorObject([]);

    // if (!formUtility.getValue("code")) {
    //   error.setErrorObject("code", true, "Code is required.");
    //   setCodeErrorObject((prev) => [
    //     ...prev,
    //     { code: "code", state: true, message: "Code is required." },
    //   ]);
    // }

    // if (!formUtility.getValue("value")) {
    //   error.setErrorObject("value", true, "Value is required.");
    //   setCodeErrorObject((prev) => [
    //     ...prev,
    //     { code: "value", state: true, message: "Value is required." },
    //   ]);
    // }

    // const duplicateCode = list.find(
    //   (data) => data.code === formUtility.getValue("code")
    // );

    // if (duplicateCode) {
    //   error.setErrorObject("code", true, "Code already used.");
    //   setCodeErrorObject((prev) => [
    //     ...prev,
    //     { code: "code", state: true, message: "Code already used." },
    //   ]);
    // }
    // if (error.hasErrors())
    // utils.log.error(
    //   `Validation errors: ${error.hasErrors()}`,
    //   error.getErrors()
    // );
    return !error.hasErrors();
  };

  const handleSubmitChoices = (event) => {
    event.preventDefault();
    if (isValid()) {
      addedChoice.code = formUtility.getValue("code");
      addedChoice.description = formUtility.getValue("description");
      addedChoice.value = formUtility.getValue("value");
      setAddedChoice(addedChoice);
      list.push(addedChoice);

      onClose();
    }
  };

  const actions = (
    <>
      <Button id="buttonCancel" onClick={onClose}>Cancel</Button>
      <Button id="buttonAddChoices" onClick={(e) => handleSubmitChoices(e)}>Add Choices</Button>
    </>
  );

  const handleAddChange = (e) => {
    formUtility.handleChange(e, setInputs);
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const duplicateCode = list.find(
      (data) =>
        data.code?.toLowerCase() === formUtility.getValue("code")?.toLowerCase()
    );
    if (!value) {
      //value can't be empty
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    } else if (name === "code" && duplicateCode) {
      //value can't be duplicate
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject("code"),
      ]);
    } else {
      //if not any error for this name => then clear the error for the given name
      error.clearErrorObject(name);
      const filteredErrors = codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      setCodeErrorObject(filteredErrors);
    }
  };

  const handleInputChange = function (e) {
    const { name, value } = e.target;
    const updatedErrors = codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    setCodeErrorObject(updatedErrors);
    formUtility.handleChange(e, setInputs);
  };

  const getHelperTextMessage = (name) => {
    return codeErrorObject?.find((error) => error?.name === name)?.message;
  };
  const hasError = (name) => {
    return !!codeErrorObject?.find((error) => error?.name === name)?.state;
  };

  // #endregion
  return (
    <>
      <MUIDialog
        open={open}
        onClose={onClose}
        actions={actions}
        title="Add Choice"
        description="Choice instructions..."
      >
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6} id={"textboxCode"}>
            <Input
              label="Code"
              fullWidth
              name="code"
              onChange={handleInputChange}
              defaultValue={formUtility.getValue("code")}
              value={formUtility.getValue("code")}
              error={hasError("code")}
              helperText={getHelperTextMessage("code")}
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}  id={"textboxValue"}>
            <Input
              label="Value"
              fullWidth
              name="value"
              onChange={handleInputChange}
              defaultValue={formUtility.getValue("value")}
              value={formUtility.getValue("value")}
              error={hasError("value")}
              helperText={getHelperTextMessage("value")}
              onBlur={handleInputBlur}
            />
          </Grid>
          <Grid item xs={12} id="textboxDescription">
            <Input
              label="Description"
              fullWidth
              name="description"
              onChange={handleAddChange}
              value={formUtility.getValue("description")}
            />
          </Grid>
          {/*             <Grid item xs={12}>
              <DependencySelector questions={questions}></DependencySelector>
            </Grid> */}
        </Grid>
      </MUIDialog>
    </>
  );
};

export function checkedCount(choices) {
  // PURPOSE: Returns the number of choices that are checked.
  const count = choices.filter((choice) => choice.isSelected).length;
  return count;
}
export const valueFormatted = (choice, delimeter = "|") => {
  // PURPOSE: Returned value for use in radio/checkbox/dropdown input.
  const value = `${choice.question().code}${delimeter}${choice.code}`;
  return value;
};
export default Choices;

/* 
FIXED
040723 - Editing choice causes crash.
    CAUSE(040723): 
      * Details component is calling DependencySelector with the wrong parameter name (current instead of context).
    FIX(040723): 
      * Changed parameter from current to context.

040723 - Adding/updating choice, closes dialog to questions list.  Need to only close to the choices list.
    CAUSE(040723): Form component  onSubmit was creating unintended behavior.
    FIX(040723): Use Button component onClick event handler instead to call the same function.    
*/
