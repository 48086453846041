// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
// TODO: Support multiple columns for choices when rendering for recipient.
/*
ISSUES

*/

import {
  FormGroup,
  FormHelperText,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { Choice, QuestionHeader } from "../recipients/question";
import { ChoiceSelector } from "./dependencySelector";
//import Input from "../../components/Input/inputs";
import { AuthContext } from "../../services/context";
import FormUtility from "../../services/form";
import { checkedCount } from "./choices";
import * as dependencyUtil from "./dependency";
import errorIcon from "../../assets/errorIcons.svg";
import * as utils from "../../services/utilities";
// import useMediaQuery from "../../hooks/useMediaQuery";

const formUtility = new FormUtility();
export function New() {
  return {
    type: "MultipleChoice",
    code: null,
    title: "New Multiple Choice Question",
    description: "",
    instructions: "",
    dependency: null,
    choices: [],
    minChoices: 1,
    maxChoices: 1,
    answer: null,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  // utils.log.component("Reset(MultipleChoice):", question);
  question.choices = question?.choices?.map((choice) => {
    return {
      ...choice,
      isSelected: false,
    };
  });
  utils.log.info(":: SC question MC", question);
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // utils.log.component("Edit(MultipleChoice):", question);
  // #region Initialize
  const hasChoices = question.choices?.length > 0;
  //question.minChoices = question.minChoices < 1 ? 1 : question.minChoices;
  //question.maxChoices = question.maxChoices < 1 ? 10 : question.maxChoices;
  formUtility.setDetail(question);
  // #endregion
  // #region Events
  return (
    <>
      <Grid container spacing={2}>
        {hasChoices && (
          <Grid item xs={12}>
            <ExpectedAnswers question={question}></ExpectedAnswers>
          </Grid>
        )}
      </Grid>
    </>
  );
}
const ExpectedAnswers = ({ question }) => {
  const marks = [
    { value: 1, label: "1" },
    { value: question.choices.length, label: question.choices.length },
  ];
  const [value, setValue] = useState([
    question.minChoices,
    question.maxChoices,
  ]);
  const handleChange = (event, value) => {
    question.minChoices = value[0];
    question.maxChoices = value[1];
    setValue(value);
  };
  return (
    <>
      <Typography
        id="non-linear-slider"
        gutterBottom
        style={{ fontFamily: "Public-sans" }}
      >
        Expected Answers:
      </Typography>
      <Slider
        getAriaLabel={() => "Expected Answers"}
        marks={marks}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="on"
        getAriaValueText={valuetext}
        min={1}
        max={question.choices.length}
        disableSwap
      />
    </>
  );
};
function valuetext(value) {
  return `${value} choices`;
}
export const DependencySelector = ({ context, selectedQuestion }) => {
  // !NOTE: useState() is prohibited in this component.
  return (
    // Dependency mode: select
    <ChoiceSelector
      context={context}
      selectedQuestion={selectedQuestion}
    ></ChoiceSelector>
    // TODO: 062123 - Dependency mode: count - Support count mode (dependency based on total number of choices selected as dependency parameter).
  );
};
export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);

  return dependency;
}
export function Render(props) {
  // #region Initialize
  // utils.log.component("MultipleChoice.Render()", props);
  const { question, index, rowStyle, preview } = props;
  // const preview = props.preview ?? false;
  // utils.log.section(`Render ${question.type} Question(${question?.code})})`);
  // const isVisible = preview ? true : question.isVisible;
  const isQuestionCompleted = isCompleted(props.question);

  // const [checked, setChecked] = useState(false); // !BUG: Creating Rule of Hooks Violation
  const { minChoices, maxChoices } = question;
  // const count = question?.choices?.filter((choice) => choice?.isSelected);

  const helperText =
    minChoices === maxChoices
      ? `Make ${minChoices} choice(s).`
      : `Make between ${minChoices} and ${maxChoices} choice(s).`;

  // const minChoicesMessage =
  //   count.length < minChoices
  //     ? `Make ${minChoices - count.length} more choices.`
  //     : "";

  // const maxChoicesMessage =
  //   count.length >= maxChoices ? "Maximum choices reached." : "";

  /*eslint-disable-next-line*/
  // const {isSmDown} = useMediaQuery();

  // #endregion
  return (
    <>
      <div
        key={index}
        style={{
          ...rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            {question.code}
          </div>
        )}
        <div
          style={{
            padding: preview ? "30px" : 0,
            width: "100%",
          }}
        >
          <QuestionHeader question={question}></QuestionHeader>
          <div
            style={{
              marginTop: "-15px",
              marginBottom: "20px",
              color: "rgba(7, 7, 7, 0.698)",
            }}
          >
            {helperText}
          </div>
          <RenderChoices
            // checked={checked}
            // setChecked={setChecked}
            {...props}
          ></RenderChoices>
          {/* <div>
            <div style={{ color: "rgba(7, 7, 7, 0.698)" }}>
              {maxChoicesMessage}
            </div>
            <div style={{ color: "rgba(7, 7, 7, 0.698)" }}>
              {minChoicesMessage}
            </div>
          </div> */}
        </div>
      </div>
      {question?.choices?.filter((choice) => choice?.isSelected)?.length >=
        question?.maxChoices && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
            marginTop: preview ? 0 : "-20px",
          }}
        >
          <img
            src={errorIcon}
            alt="error-icon"
            height={"15px"}
            width={"15px"}
          />
          <FormHelperText style={{ color: "#DB3B3B" }}>
            Maximum choices reached
          </FormHelperText>
        </div>
      )}
    </>
  );
  // return null;
}
export const RenderChoices = (props) => {
  // TODO: question.isDropdown is not implemented
  // TODO: Allow recipient to select custom comment (question.isCommentAllowed)

  // #region Initialize
  const { surveyService } = useContext(AuthContext);
  const preview = props.preview ?? false;
  const question = props.question;
  const choices = question.choices;
  const choicesFiltered = preview
    ? choices // Show all choices in preview mode
    : choices.filter((c) => c.isVisible);
  const maxChoices = question.maxChoices;
  // const {  setChecked } = props;
  // #endregion

  // #region Events
  const handleSelected = (choice) => {
    choice.isSelected = !choice.isSelected;
    // const choiceFormatted = choice.valueFormatted?.(".");
    // Prevent more than maxChoices to be checked
    if (checkedCount(question.choices) > maxChoices) {
      choice.isSelected = !choice.isSelected; // Undo check
      // setChecked(true);
    }
    if (preview) {
      props.setQuestions((question) => {
        const updatedChoices = question.choices.map((c, index) => {
          if (c.code === choice.code) {
            return { ...c, isSelected: choice.isSelected };
          } else return { ...c };
        });
        return { ...question, choices: updatedChoices };
      });
    } else {
      props.setQuestions((prevQuestions) => {
        const questionCode = choice?.question && choice.question().code;
        // *** Set selected choice ***
        let updatedQuestions = prevQuestions.map((question) => {
          if (question.code === questionCode) {
            // Create a new copy of the choices array with updated isSelected value
            const updatedChoices = question.choices.map((c, index) => {
              // utils.log.info(`Checking choice(${c.code} index: ${index})`, c);
              if (c.code === choice.code) {
                // utils.log.info(
                //   `handleSelected(${choiceFormatted}): Choice(${questionCode}.${c.code}) found and set to isSelected = true`,
                //   config.cssStyle.success
                // );
                return { ...c, isSelected: choice.isSelected };
              } else return { ...c };
            });

            // Update the choices array of the parent question
            return { ...question, choices: updatedChoices };
          }
          return question;
        });

        // Resolve visibility for all questions
        if (!preview) dependencyUtil.resolveVisibilities(updatedQuestions);

        // utils.log.stateChange(
        //   `*** STATE CHANGE *** handleSelected(${choiceFormatted})`,
        //   updatedQuestions
        // );

        return updatedQuestions;
      });
    }
  };
  // utils.log.info(":: multiple Choice",choicesFiltered);
  // #endregion
  return (
    <>
      <div>
        <FormGroup row sx={{ alignItems: "center" }}>
          {choices == null
            ? null
            : choicesFiltered.map((choice) => {
                return (
                  <Choice
                    key={choice.code}
                    id={choice.code}
                    //current={{ question: question, choice: choice }}
                    choice={choice}
                    handleSelected={handleSelected}
                    renderAs="checkbox"
                  ></Choice>
                );
              })}
          {question.isCommentAllowed && (
            <Choice
              key={"choice.code"}
              current={{
                question: question,
                choice: surveyService.newChoice("code", "value", "description"),
              }}
              handleSelected={handleSelected}
              renderAs="comment"
            ></Choice>
          )}
        </FormGroup>
      </div>
    </>
  );
};

// PURPOSE: Determine if dependency is satisfied.
export function isAnswered(dependency) {
  if (dependency == null) {
    // return false;
    return true;
  }
  const dependencyType = dependencyUtil.getType(dependency);
  switch (dependencyType) {
    // If there is question but no choices
    case "question":
      // TODO(LOW): When we figure out how to restore survey functions, convert checkedCount to that.
      if (dependency?.question?.choices?.find((choice) => choice?.isSelected)) {
        return true;
      }
      break;
    // If there is question and choices also
    case "choice": // TRUE: If this choice is selected.
      if (
        dependency?.question?.choices?.find(
          (choice) => choice?.code === dependency?.choice?.code
        )?.isSelected
      ) {
        return true;
      }
      break;
    default:
      throw new Error(
        `MultipleChoice.isAnswered: Invalid dependency type: ${dependency.type}`
      );
  }
  return false;
}

export const isCompleted = (question) => {
  if (question == null) {
    return false;
  }

  const minChoices = question.minChoices;
  const maxChoices = question.maxChoices;
  const selectdChoices =
    question.choices.filter((c) => c.isSelected)?.length || 0;

  if (selectdChoices < minChoices || selectdChoices > maxChoices) {
    return false;
  }

  return true;
};

export const getValue = (question) => {
  return {
    value: question.choices.filter((c) => c.isSelected)?.map((c) => c.code),
  };
};

// #endregion
/*
FIXED

*/
