import { makeStyles } from "@mui/styles";
import heroImage from "../../assets/image copy.png";
import ProfileImage from "../../assets/profileImage.png";
import { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext } from "../../services/context";
import Loading from "../../components/Loading";
// import * as utils from "../../services/utilities";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: "150px",
    margin: "auto",
    background: "#ffffff",
  },
  heroSection: {
    padding: "0px 150px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: "30px",
      alignItems: "center",
      padding: "0px 25px",
    },
  },
  heroContentLeft: {
    paddingRight: "300px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  heroSectionImage: {
    height: "300px",
  },
  profileInfo: {
    display: "flex",
    marginTop: "20px",
    gap: "15px",
  },
  quoteSection: {
    background: "#0066F9",
    height: "300px",
    color: "#ffffff",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "100px",
  },
  quoteSectionTitle: {
    fontSize: "32px",
    [theme.breakpoints.down("md")]: {
      fontSize: "26px",
    },
  },
  quoteSectionDescp: {
    fontSize: "22px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
    },
  },
  contentSection: {
    fontSize: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "60px",
    width: "40%",
    margin: "auto",
    padding: "100px 0px",

    [theme.breakpoints.down("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  contentBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  sectionImage: {
    width: "100%",
    maxWidth: "700px",
  },
  sectionText: {
    fontSize: "18px",
  },
}));

const UseCasePage = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [userCasePageData, setUserCasePageData] = useState(null);
  const [selectedUserCasePage, setSelectedUserCasePage] = useState(null);
  const { noticeService } = useContext(AuthContext);
  const { id } = useParams();

  const fetchNoticeUseCasePage = useCallback(async () => {
    setLoading(true);
    try {
      const response = await noticeService.getBySection(
        "UseCase",
        "view",
        null,
        true
      );
      setUserCasePageData(response);
      setSelectedUserCasePage(null);
    } catch (err) {
      // utils.log.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchNoticeUseCasePage();
  }, [fetchNoticeUseCasePage]);

  useEffect(() => {
    // utils.log.info(":: selectedUserCasePage id",{id,userCasePageData})
    if (userCasePageData) {
      const selctedPage = userCasePageData?.find((item) => item?.title === id);
      setSelectedUserCasePage(selctedPage?.message);
    }
  }, [id, userCasePageData]);

  if (loading) {
    return <Loading />;
  }

  if (!userCasePageData) {
    return <div>No Data is available</div>;
  }

  function unescapeHTML(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.documentElement.textContent;
  }

  return (
    <div className={classes.pageContainer}>
      {/* Hero Section */}
      <div className={classes.heroSection}>
        <div className={classes.heroContentLeft}>
          <div>
            <p>March 27, 2024 | 9 min Read</p>
            <h1>Corporate Commuter Solutions</h1>
            <div className={classes.profileInfo}>
              <div>
                <img
                  src={ProfileImage}
                  height={"50"}
                  width={"50"}
                  alt="Profile"
                />
              </div>
              <div>
                <div>Matt Campbell</div>
                <div>Active</div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.heroContentRight}>
          <img
            src={heroImage}
            alt="Hero"
            className={classes.heroSectionImage}
          />
        </div>
      </div>

      {/* Quote Section */}
      <div className={classes.quoteSection}>
        <div className={classes.quoteSectionTitle}>
          “Transport Demand Management allows cities to optimize existing
          infrastructure and reduce congestion without the heavy costs
          associated with road expansion.”
        </div>
        <div className={classes.quoteSectionDescp}>Jane Doe, Urban Planner</div>
      </div>

      {/* Content Section  */}
      <div className={classes.contentSection}>
        {selectedUserCasePage && (
          <div
            dangerouslySetInnerHTML={{
              __html: unescapeHTML(selectedUserCasePage),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UseCasePage;
