import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import DashboardLayout from "../../components/DashboardLayout";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { Link } from "react-router-dom";
import { Eula, PrivacyPolicy } from "../../components/Notice";
import { CONTACTUS_INFO } from "../../utils/contentData";
//import { useUtils } from "@mui/x-date-pickers/internals";
const useStyles = makeStyles(() => ({
  main: {
    backgroundColor: "#fff",
    padding: "10px 20px",
    border: "1px solid #ccc",
    borderRadius: "12px",
  },
  span: {
    fontSize: "15px",
    color: "#444",
  },
  email: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

export const HelpSupport = () => {
  // utils.log.component("HelpSupport");
  // #region Initialize
  const styles = useStyles();
  const [openEula, setOpenEula] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  // #endregion
  // #region Events
  const handleEula_Click = () => {
    setOpenEula(true);
    // utils.log.stateChange("openEula", openEula);
  };
  const handlePrivacyPolicy_Click = () => {
    setOpenPrivacyPolicy(true);
    // utils.log.stateChange("openPrivacyPolicy", openPrivacyPolicy);
  };
  // #endregion
  // #region Functions
  // #endregion
  return (
    <DashboardLayout
      title="Get Help"
      description="Stuck somewhere? Reach out to us for help!"
    >
      <div className={styles.main}>
        <p>Please write to us on the below mail by explaining the issue:</p>
        <Link to={`mailto:${CONTACTUS_INFO?.email}`} target="_blank">
          <p className={styles.email}>
            <AttachEmailIcon />
            <span>{CONTACTUS_INFO?.email}</span>
          </p>
        </Link>
        <Button onClick={handleEula_Click}>EULA</Button>
        <Button onClick={handlePrivacyPolicy_Click}>Privacy Policy</Button>
        {openEula && (
          <Eula isOpen={openEula} setIsOpen={setOpenEula} preview={true} />
        )}
        {openPrivacyPolicy && (
          <PrivacyPolicy
            isOpen={openPrivacyPolicy}
            setIsOpen={setOpenPrivacyPolicy}
            preview={true}
          />
        )}
      </div>
    </DashboardLayout>
  );
};
