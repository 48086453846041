import DialogBox from "../components/Dialog";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../auth/config";

export const AccessDeniedModal = () => {
  const [open] = useState(true);
  const { logout, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const { domain } = getConfig();
  const [intervalId, setIntervalId] = useState(null);

  const getUserInfo = async () => {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`https://${domain}/userinfo`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return await response.json();
  };

  const handleUserInfo = async () => {
    try {
      const user = await getUserInfo();
      if (user?.email_verified) {
        loginWithRedirect();
      }
    } catch (error) {
      // utils.log.error("Error fetching user information", error);
    }
  };

  const handleTryAgain = async () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    await handleUserInfo();
    startInterval();
  };

  const startInterval = () => {
    const newIntervalId = setInterval(() => {
      handleUserInfo();
    }, 10 * 1000);
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    handleUserInfo();
    startInterval();
    if (intervalId) {
      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <DialogBox
      open={open}
      title="Access Denied"
      bodyTitle=" Email verification Required"
      body=" Your email needs to be verified before being granted access."
      footer="Please check your email for the verification link."
      primaryButtonText="Rechecked"
      handlePrimaryButtonClick={() => handleTryAgain()}
      secondaryButtonText={"Logout"}
      handleSecondaryButtonClick={() => logout()}
    />
  );
};
