import Viewer from "../Help";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const CustomWidthTooltip = styled(
  ({ className, code, placement, ...props }) => (
    <Tooltip
      classes={{ popper: className }}
      arrow
      title={<Viewer code={code} />}
      enterTouchDelay={0}
      placement={placement}
      {...props}
    />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    background: "#fff",
    borderRadius: "8px",
    border: "1px solid #eee",
    padding: "10px 20px",
    cursor: "pointer",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
});
