import {HomePageButtons,HomePageFeatureList} from "./Home/index"

export default function Features() {
  return(
    <div style={{paddingTop:"150px"}}>
        <h2 style={{textAlign:"center",paddingBottom:"20px"}}>Commuter Survey Tool that works for you.</h2>
        <HomePageButtons/>
        <HomePageFeatureList/>
      </div>
  );
}
