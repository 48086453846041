import React, { useContext, useState, useMemo, useEffect } from "react";
import { AuthContext } from "../../services/context";
import { SideBar } from "../../components/SideBar";
import { useSearchParams } from "react-router-dom";
import { MAIN_PAGE_TAB_LINKS } from "../../utils/sideBarData";
import { Eula } from "../../components/Notice";
import useMediaQuery from "../../hooks/useMediaQuery";
import MUIDialog from "../../components/Modal";
import Button from "../../components/Button";
import { useTour } from "@reactour/tour";
import styles from "./style.module.css";
import { AppContext } from "../../App";
import { steps } from "../../components/OnboardingProvider";
const Dashboard = ({ isSideBarOpen, handleToggleSideBar }) => {
  // utils.log.component("Dashboard");

  // #region Initialize
  const { employeeService } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("view") || MAIN_PAGE_TAB_LINKS[1].id
  );
  const { setHelpSection, isTourModalOpen, setIsTourModalOpen } =
    useContext(AppContext);
  const { isMdDown } = useMediaQuery();
  const [ActiveComponent, setActiveComponent] = useState(null);
  const { setIsOpen: setIsTourStart, currentStep, setCurrentStep } = useTour();

  const { noticeService } = useContext(AuthContext);
  // #endregion

  useEffect(() => {
    if (searchParams.get("view")) {
      setActiveTab(searchParams.get("view"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get("view")) {
      setSearchParams({ view: "coordinators" });
    }
  }, []);

  const allSteps = steps;
  // #region Events

  const handleSetActiveTab = (tab) => {
    setActiveTab(tab?.id);
    setSearchParams({ view: tab?.id });
    if (isMdDown) {
      handleToggleSideBar();
    }
  };

  const tabs = useMemo(() => {

    return MAIN_PAGE_TAB_LINKS.filter(
      (tab) =>
        tab.roles.includes("DepartmentCoordinator") ||
        employeeService.hasRole(tab.roles[0])
    ).map((tab, index) => {
      if (tab?.title) {
        return (
          <div key={index} className={styles.title}>
            {tab?.title}
          </div>
        );
      }

      return (
        <div
         id={tab?.testingIds}
          className={`${styles.tabContainer} ${
            activeTab === tab?.id ? styles.selectedTabs : ""
          }  ${tab?.className}`}
          key={index}
          onClick={() => handleSetActiveTab(tab)}
        >
          <div key={tab?.id} className={`${styles.tabs} `}>
            {tab?.icon}
            <span className="tab-label">{tab?.label}</span>
          </div>
        </div>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, employeeService, currentStep]);

  useEffect(() => {
    const tab = MAIN_PAGE_TAB_LINKS.find(
      (tab) => tab.id === activeTab
    )?.component;
    setActiveComponent(tab);
  }, [activeTab]);

  useMemo(() => {
    const tab = MAIN_PAGE_TAB_LINKS.find(
      (tab) => tab.id === allSteps[currentStep]?.id
    );
    //in case of header tour => there is no tab
    if (tab) {
      setActiveComponent(tab?.component);
      handleSetActiveTab(tab);
    }
  }, [currentStep]);

  const fetchNoticesHelp = async () => {
    const allNotice = await noticeService.getBySection(
      "Help",
      "view",
      null,
      true
    );
    setHelpSection(allNotice);
  };

  useEffect(() => {
    fetchNoticesHelp();
  }, []);

  // #endregion

  const handleReactTour = () => {
    setIsTourStart(true);
    setIsTourModalOpen(false);
    setCurrentStep(0);
  };

  return (
    <div className={styles.root}>
      {isSideBarOpen && <SideBar tabs={tabs} />}

      <div className={isSideBarOpen ? styles.body : styles.onSideBarOpen}>
        {ActiveComponent}
      </div>
      {<Eula />}

      {/* Onboarding  */}
      {
        <MUIDialog
          open={isTourModalOpen}
          onClose={() => setIsTourModalOpen(false)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "400px",
              justifyContent: "center",
              alignItems: "center",
              gap: "50px",
            }}
          >
            <div style={{ fontSize: "40px" }}>Welcome to GOPASSGO</div>
            <div style={{ display: "flex", gap: "30px" }}>
              <Button
                variant="contained-primary"
                className={styles.modalPrimaryButton}
                onClick={handleReactTour}
              >
                Start Tour
              </Button>
              <Button
                variant="contained-white"
                className={styles.modalSecondaryButton}
                onClick={() => setIsTourModalOpen(false)}
              >
                Exit Tour
              </Button>
            </div>
          </div>
        </MUIDialog>
      }
      {/* {<Viewer section="EULA" title="End User License Agreement" width="80%" />} */}
    </div>
  );
};

export default Dashboard;
