import { useContext } from "react";
import { AuthContext } from "../../services/context";
import Dashboard from "../Dashboard";
import { Seo } from "../Seo";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../../components/Button";
import Exemption from "../../assets/exemption.svg";
import FollowReminder from "../../assets/FollowReminder.svg";
import CoordinatorsImg from "../../assets/coordinatorsImg.svg";
import { HOMEPAGE_CONTENT } from "../../utils/contentData";

export default function Home({ isSideBarOpen, handleToggleSideBar }) {
  // utils.log.component("Home");

  // #region Initialize
  const { employee } = useContext(AuthContext);
  if (employee) {
    return (
      <Dashboard
        isSideBarOpen={isSideBarOpen}
        handleToggleSideBar={handleToggleSideBar}
      />
    );
  }

  return (
    <>
      <div style={{ background: "#FFFFFF", fontFamily: "DM Sans" }}>
        <div style={{ margin: "0px 0px" }}>
          <Seo
            title={"Commuter Survey Tool"}
            description={
              "The Commuter Survey Tool (CST) is a purpose built survey management system for the TDM community"
            }
          />
          {/* <div style={{padding:"200px"}}>
            <p className="first-step">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              at finibus nulla, quis varius justo. Vestibulum lorem lorem,
              viverra porta metus nec, porta luctus orci
            </p>
            <button onClick={() => setIsOpen(true)}>Open Tour</button>
          </div> */}

          <HeroSection />
          <VideoSection />
          <InfoSection />
          <HomePageFeatureList />
        </div>
        <Banner />
      </div>
    </>
  );
}

export const HeroSection = () => {
  return (
    <div className={style.heroWrapper}>
      <div className={style.heroWrapperTopIcon}></div>
      <div className={style.heroContainer}>
        <h2 className={`${style.heroTitle} `}>
          Commuter <span className={style.highlightedText}>Survey Tool</span>{" "}
          That Works For You.
        </h2>
        <p className={style.heroDescription}>
          The Commuter Survey Tool (CST) is a purpose-built survey management
          system for the TDM community powered by{" "}
          <Link
            to="https://www.gopassgo.net/"
            className={style.highlightedLink}
          >
            GoPassGo Networks, LLC
          </Link>
        </p>
        <HomePageButtons />
      </div>
    </div>
  );
};

export const HomePageButtons = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={style.heroButtonContainer}>
      <Button
        size="medium"
        color="primary"
        className={style.heroButtonPrimary}
        variant="contained-green"
        onClick={() =>
          loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })
        }
      >
        Start Free
      </Button>
      <Button
        size="medium"
        variant="contained-white"
        className={style.heroButtonSecondary}
        onClick={() => navigate("/contact")}
      >
        Talk with Sales
      </Button>
    </div>
  );
};

export const VideoSection = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={style.videoWrapper}>
      <div className={style.videoInfoWrapper}>
        <div className={style.videoInfoContainer}>
          <h2 className={style.videoInfoTitle}>
            Confused what suits your needs? Talk with Team
          </h2>
          <p className={style.videoInfoDescription}>
            Follow our newsletter. We will regularly update our latest project
            and availability.
          </p>
          <Button
            variant="contained-white"
            className={style.videoInfoButton}
            onClick={() =>
              loginWithRedirect({
                authorizationParams: { screen_hint: "signup" },
              })
            }
          >
            Try Free
          </Button>
        </div>
      </div>
      <div className={style.videoContainer}>
        <iframe
          style={{
            width: "100%",
            height: "100%",
          }}
          borderRadius="20px"
          src="https://www.youtube.com/embed/P_HFMVojp78?autoplay=1&mute=1&controls=0"
          title="Introduction to Commuter Survey Tool"
          frameBorder="0"
          autoplay
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          loop
        ></iframe>
      </div>
    </div>
  );
};

export const InfoSection = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const getIconByIndex = (index) => {
    switch (index) {
      case 0:
        return Exemption;
      case 1:
        return FollowReminder;
      case 2:
        return CoordinatorsImg;
      default:
        return null;
    }
  };

  return (
    <div className={style.infoSection}>
      <div className={style.infoSectionWrapper}>
        <h4 className={style.infoSectionTitle}>HOW IT WORKS</h4>
        <h3 className={style.infoTitleSecondary}>
          How GoPass Commuter Survey Tool Transforms Your Survey Process?
        </h3>
        <p className={style.infoDescription}>
          Explore how our tool streamlines survey management, improves data
          accuracy, and drives actionable insights easily.
        </p>
      </div>
      <div className={style.infoCardWrapper}>
        {HOMEPAGE_CONTENT?.cards?.map((item, index) => {
          return (
            <div className={style.infoCardContainer} key={item?.title}>
              <div className={style.infoIconsContainer}>
                <img
                  src={getIconByIndex(index)}
                  alt="icon"
                  width={48}
                  height={48}
                />
              </div>
              <h3>{item?.title}</h3>
              <p>{item?.description}</p>
            </div>
          );
        })}
      </div>

      <div className={style.infoButtonContainer}>
        <Button
          variant="contained-primary"
          className={style.infoButtonPrimary}
          onClick={() =>
            loginWithRedirect({
              authorizationParams: { screen_hint: "signup" },
            })
          }
        >
          Get a Free Demo
        </Button>
        <Button
          variant="text"
          className={style.infoButtonSecondary}
          onClick={() => navigate("/pricing")}
        >
          See Pricing
        </Button>
      </div>
    </div>
  );
};

export const HomePageFeatureList = () => {
  return (
    <>
      {HOMEPAGE_CONTENT.features.map((item, index) => (
        <div
          className={`${style.featureList} ${
            index % 2 === 0 ? style.featuresEvenCard : style.featuresOddCard
          }`}
          key={item?.title}
        >
          <div
            className={`${style.featuresLeft} ${
              index % 2 === 0 ? style.featuresEvenCard : style.featuresOddCard
            }`}
          >
            <img src={item.mainImg} alt="main" />
          </div>

          <div className={style.featuresRight}>
            <h3 className={style.featuresRightTitle}>{item.title}</h3>
            <p className={style.featuresRightDescription}>{item.description}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className={style.bannerWrapper}>
      <div className={style.bannerContainer}>
        <h4 className={style.bannerTitle}>
          Ready to Revolutionise Your Commuter Surveys?
        </h4>
        <p className={style.bannerDescription}>
          Join hundreds of organisations using our tool to optimise
          transportation strategies.
        </p>
      </div>
      <Button
        variant="contained-primary"
        className={style.bannerButton}
        onClick={() => navigate("/contact")}
      >
        Get in Touch
      </Button>
    </div>
  );
};
