/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
// TODO: Add preview button to each row of the table.
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import loadingImg from "../../assets/loading.gif";
import { DatePicker, TimePicker } from "antd";
import { DialogContent, Slider, Tooltip, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar, useSnackbar } from "notistack";
import TimezoneSelect from "react-timezone-select";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import Button from "../../components/Button";
import Form from "../../components/Form";
import Grid from "../../components/Grid";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import Table from "../../components/Table";
import Tabs, { Tab } from "../../components/Tabs";
import { NoticeEditor } from "../../components/Notice";
import * as config from "../../services/config";
import { AuthContext } from "../../services/context";
import Debug from "../../services/debug";
import * as utils from "../../services/utilities";
import { Questions } from "../survey-questions";
import * as dependencyUtil from "../survey-questions/dependency";
import { timeFormat } from "../survey-questions/timeInput";
import Emails from "../recipients/management/emails";
import Exemptions from "../recipients/management/exemptions";
import Find from "../recipients/management/find";
import Summary from "../recipients/management/summary";
import Upload from "../recipients/management/upload";
import Detail from "../recipients/detail";
import Chart from "react-apexcharts";
import { HeaderActions } from "../../components/HeaderActions";
import DashboardLayout from "../../components/DashboardLayout";
import { useSearchParams } from "react-router-dom";
import MUIDialog from "../../components/Modal";
import styles from "./style.module.css";
import usePermissions from "../../hooks/usePermission";
import useMediaQuery from "../../hooks/useMediaQuery";
import { CustomWidthTooltip } from "../../components/Tooltip";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { HelpOutlineOutlined } from "@mui/icons-material";
import {
  COLORS,
  customStylesOfTimeZone,
  pieChartData,
  renderCustomizedLabel,
} from "../../utils/surveyUtils";
import detailAnalyticsGraph from "../../../src/assets/analyticsGraph.svg";
import arrowUp from "../../../src/assets/redArrowUp.svg";
import infoIcon from "../../../src/assets/infoIcon.svg";
import mail from "../../../src/assets/Mail (1).svg";
import exemptions from "../../../src/assets/exemption.svg";
import notice from "../../../src/assets/Notes.svg";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tick from "../../assets/icons/tick.svg";
import Chip from "@mui/material/Chip";
import { grey } from "@mui/material/colors";

const dateTimeFormat = `${config.defaults.DATE_STORAGE_FORMAT} h:mm A`; //const dateTimeFormat = `${config.defaults.DATE_STORAGE_FORMAT} ${timeFormat}`;
const { RangePicker } = DatePicker;

const columns = [
  { Header: "Code", accessor: "code" },
  { Header: "Name", accessor: "name" },
  {
    Header: "Start",
    accessor: "surveyActivePeriod_EffectiveDate",
    Cell: ({ row }) =>
      row.original.surveyActivePeriod_EffectiveDate
        ? dayjs(row.original.surveyActivePeriod_EffectiveDate).format(
            "YYYY-MM-DD HH:mm"
          )
        : "N/A",
  },
  {
    Header: "End",
    accessor: "surveyActivePeriod_ExpirationDate",
    Cell: ({ row }) =>
      row.original.surveyActivePeriod_ExpirationDate
        ? dayjs(row.original.surveyActivePeriod_ExpirationDate).format(
            "YYYY-MM-DD HH:mm"
          )
        : "N/A",
  },
];

export const SurveyContext = React.createContext({});

export default function Surveys() {
  const { surveyService, employee } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSurveyCode, setSelectedSurveyCode] = useState(null);
  const [searchParams] = useSearchParams();
  const confirm = useConfirm();
  const { canAddSurvey } = usePermissions();

  const fetchSurveys = async () => {
    try {
      const list = await surveyService.getAll();
      setList(list);
    } catch (error) {
      utils.log.error("Survey.fetchSurvey()", error);
    } finally {
      setIsLoading(false);
    }
  };

  // - Done
  const handleDeleteSurvey = async (surveyCode) => {
    try {
      await confirm({
        description: `Are you sure you want to delete this ${surveyCode} survey?`,
      });
      await surveyService.remove(surveyCode);

      setList((prevList) =>
        prevList?.filter((list) => list?.code !== surveyCode)
      );
      setSelectedSurveyCode(null);

      enqueueSnackbar("Survey deleted successfully", {
        variant: "success",
        closeable: true,
      });
    } catch (err) {
      enqueueSnackbar(err?.message || "Error deleting employee", {
        variant: "error",
        closeable: true,
      });
    }
  };

  const handleRowClick = (row) => {
    setSelectedSurveyCode(row?.code);
  };

  useEffect(() => {
    fetchSurveys();
  }, []);

  useEffect(() => {
    const querySurveyCode = searchParams.get("surveyCode");
    if (querySurveyCode) {
      fetchSurveyUsingCode(querySurveyCode);
    }
  }, [searchParams]);

  const fetchSurveyUsingCode = async (code) => {
    setIsLoading(true);
    try {
      const survey = await surveyService.getByCode(code);
      let stagedSurvey = survey;
      if (!survey.emailSendTime) {
        stagedSurvey.emailSendTime = config.defaults.SURVEY_EMAIL_SEND_TIME;
      }
      setSelectedSurvey(stagedSurvey);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const surveyContextValue = useMemo(() => {
    return {
      list,
      setList,
      selectedSurvey,
      setSelectedSurvey,
      handleDeleteSurvey,
      fetchSurveys,
      selectedSurveyCode,
      setSelectedSurveyCode,
    };
  }, [
    list,
    selectedSurvey,
    selectedSurveyCode,
    handleDeleteSurvey,
    fetchSurveys,
  ]);

  // const isSurveyLimitReached = !canAddSurvey(list?.length, employee?.plan);

  const headerAction = (
    <HeaderActions
      buttonTitle={"Add"}
      buttonIds={"buttonAdd"}
      onAdd={setIsAddModalOpen}
      disabled={false}
      // tooltipTitle={
      //   isSurveyLimitReached ? "maximum survey limit is reached" : ""
      // }
      tourClassName={"onboarding-16"}
    />
  );

  return (
    <SurveyContext.Provider value={surveyContextValue}>
      <DashboardLayout
        title="Surveys"
        description="Easily Manage your surveys from a single place"
        code={"survey-list"}
      >
        {isAddModalOpen && (
          <Add
            open={isAddModalOpen}
            setIsAddModalOpen={setIsAddModalOpen}
          ></Add>
        )}

        {selectedSurveyCode && <Overview />}

        <Table
          columns={columns}
          data={list}
          selected
          onSelected={(row) => handleRowClick(row.original)}
          headerAction={headerAction}
          isLoading={isLoading}
          filterTourClassName={"onboarding-17"}
          paginationTourClassName={"onboarding-18"}
          testingIds="Survey"
        ></Table>
      </DashboardLayout>
    </SurveyContext.Provider>
  );
}

export const Overview = () => {
  const {
    list: surveys,
    selectedSurvey,
    setSelectedSurvey,
    handleDeleteSurvey,
    fetchSurveys,
    selectedSurveyCode,
    setSelectedSurveyCode,
  } = useContext(SurveyContext);

  const [preview, setPreview] = useState(false);
  const [codeErrorObject, setCodeErrorObject] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isConfirmValidationModalOpen, setIsConfirmValidationModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCommandBarVisible, setIsCommandBarVisible] = useState(true);
  const [validations, setValidations] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { surveyService, cache, error } = useContext(AuthContext);
  const { isMdDown, isSmDown, isSmUp, isMdUp } = useMediaQuery();

  // PURPOSE: Validate survey.
  const surveyCodes = surveys
    ?.map((item) => item.code)
    ?.filter((item) => item !== selectedSurveyCode);

  const isValid = () => {
    return !error.hasErrors();
  };

  const fetchSurveyUsingCode = async (code) => {
    setLoading(true);
    try {
      const survey = await surveyService.getByCode(code);
      let stagedSurvey = survey;
      if (!survey.emailSendTime) {
        stagedSurvey.emailSendTime = config.defaults.SURVEY_EMAIL_SEND_TIME;
      }
      cache.setDetails({
        ...stagedSurvey,
        sampleGoalPct: stagedSurvey?.sampleGoalPct || 30,
        skipValue: stagedSurvey?.skipValue || 10,
      });
      setSelectedSurvey(stagedSurvey);
      setQuestions(survey?.questions);
      const { validations } = stagedSurvey;
      setValidations(validations);
    } catch (error) {
      // utils.log.error(`Error fetching survey (${code})`, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchValidations = async () => {
    try {
      const payload = { ...cache.getDetails() };
      if (isValidationLoading && payload) {
        const response = await surveyService.validate(payload);
        if (response?.length > 0) {
          setValidations(response);
        }
      }
    } catch (error) {
      // utils.log.error("Overview.fetchValidations()", error);
    } finally {
      setIsValidationLoading(false);
    }
  };

  useEffect(() => {
    fetchValidations();
  }, [isValidationLoading]);

  const updateCategoryChoiceQn = () => {
    const allQuestions = cache?.get("questions");
    const updatedQuestions = allQuestions?.map((question) => {
      if (question?.type === "CategoryChoice" && question?.answer?.length > 0) {
        const updatedAnswer = question?.answer?.map((ans) => {
          return { ...ans, isSelected: false };
        });
        return { ...question, answer: updatedAnswer };
      } else {
        return question;
      }
    });
    cache.set("questions", updatedQuestions);
    setQuestions(updatedQuestions);
  };

  const handleUpdateSurvey = async (
    event,
    ignoreWarning,
    isPublishFlag = false, // What to send in isEnabled,
    validationLoading = false
  ) => {
    event.preventDefault();
    if (isValid()) {
      updateCategoryChoiceQn(); // to clear selcted answer in category Choice Question

      if (validationLoading) {
        setValidationLoading(true);
      } else {
        setLoading(true);
      }
      cache.set("id", selectedSurvey?.id); // inputs["id"] = survey.id;
      const requestBody = cache.getDetails();
      delete requestBody.validations;

      try {
        const query = {
          ignoreWarning: ignoreWarning,
        };

        const response = await surveyService.update(
          {
            ...requestBody,
            isEnabled: isPublishFlag,
          },
          query
        );

        // Check for error
        if ("status" in response && response.status !== 200) {
          enqueueSnackbar(
            response?.detail ||
              "Something went wrong while updating the survey",
            { variant: "error" }
          );
          return;
        }
        // if there is any validations error then error comes in response and we are showing Validation Modal
        if (response?.length > 0) {
          setIsConfirmValidationModalOpen(!isConfirmValidationModalOpen);
          setSelectedSurvey({
            ...cache.getDetails(),
            validations: response,
            // isEnabled: isPublishFlag
            //   ? !selectedSurvey?.isEnabled
            //   : selectedSurvey?.isEnabled,
          });
          return;
        }
        enqueueSnackbar("Success!", { variant: "success" });
        setSelectedSurvey(cache.getDetails());
        handleClose();
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: "error" });
      } finally {
        fetchSurveys();
        setValidationLoading(false);
        setLoading(false);
      }
      error.clearErrorObject();
      setCodeErrorObject([]);
    } else {
      // utils.log.error(error);
    }
  };

  // PURPOSE: Reset questions.
  const questionsReset = () => {
    setQuestions((prevQuestions) => {
      // const updatedQuestions = prevQuestions
      //   .filter((prevQuestion) => prevQuestion.hasOwnProperty("choices"))
      //   .map((prevQuestion) => {
      //     // Reset selections for choice based questions.
      //     const updatedChoices = prevQuestion.choices.map((choice) => {
      //       return { ...choice, isSelected: false };
      //     });
      //     // Re-establish dependency references.
      //     const dependency = dependencyUtil.resolveDependency(
      //       prevQuestions,
      //       prevQuestion.dependency
      //     );
      //     return {
      //       ...prevQuestion,
      //       choices: updatedChoices,
      //     };
      //   });
      const updatedQuestions = prevQuestions?.map((question) => {
        if (
          question?.type !== "CategoryChoice" &&
          question?.choices?.length > 0
        ) {
          const updatedChoices = question?.choices?.map((choice) => {
            return { ...choice, isSelected: false };
          });
          return { ...question, choices: updatedChoices };
        } else if (
          question?.type === "CategoryChoice" &&
          question?.answer?.length > 0
        ) {
          const updatedAnswer = question?.answer?.map((ans) => {
            return { ...ans, isSelected: false };
          });
          return { ...question, answer: updatedAnswer };
        } else {
          return question;
        }
      });
      updatedQuestions.forEach((question) => {
        surveyService.typeLookup(question.type).reset(question); // Reset user entered values.
      });
      dependencyUtil.resolveVisibilities(updatedQuestions);
      return updatedQuestions;
    });
  };

  // PURPOSE: Reset All Question i.e make isSelected false -> true from both choices and answer and open preview
  const handlePreview = (isOpen) => {
    questionsReset();
    setPreview(isOpen);
  };

  utils.log.info(":: all question", questions);

  // useEffect(() => {
  //   setQuestions(selectedSurvey?.questions);
  //   cache.set("questions",selectedSurvey?.questions)
  // }, [selectedSurvey?.questions]);

  useEffect(() => {
    fetchSurveyUsingCode(selectedSurveyCode);
  }, []);

  const handleClose = () => {
    setSelectedSurveyCode(null);
    setSelectedSurvey(null);
    setSearchParams({
      view: "surveys",
    });
    cache.clear();
  };

  const surveyManagementActionButtons = (
    <Form
      spacing={2}
      style={{ width: "100%" }}
      onSubmit={(e) => {
        handleUpdateSurvey(e, true);
      }}
    >
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "space-between", sm: "flex-start" }}
          cursor="pointer"
          gap={{ xs: "8px", sm: 0 }}
          spacing={1}
          xs={12}
          item
          sm={8}
        >
          <Grid
            style={{
              height: "fit-content",
              width: "fit-content",
              alignItems: "center",
            }}
          >
            <PublishUnpublishButtons
              selectedSurvey={selectedSurvey}
              handleUpdateSurvey={handleUpdateSurvey}
            />
          </Grid>
          <Grid item id="buttonDelete">
            {isMdDown ? (
              <Button
                style={{
                  backgroundColor: "red",
                  padding: "6px 12px",
                  fontSize: "12px",
                  borderRadius: "4px",
                  marginBottom: "10px",
                }}
                onClick={() => handleDeleteSurvey(selectedSurveyCode)}
                tabIndex={-1}
              >
                Delete
              </Button>
            ) : (
              <div
                onClick={() => handleDeleteSurvey(selectedSurveyCode)}
                className={styles.infoIconSection}
              >
                <div className={styles.infoIconLeftBorder}>
                  <DeleteOutlineTwoToneIcon
                    sx={{
                      marginTop: "0px",
                      marginLeft: "6px",
                      marginBottom: "10px",
                    }}
                    color="red"
                    htmlColor="red"
                    fontSize="medium"
                  />
                </div>
              </div>
            )}
          </Grid>
          {isMdUp && (
            <Grid>
              {validations?.length > 0 ? (
                <div className={styles.infoText}>
                  <img src={infoIcon} alt="" />
                  <span>{`You have ${validations?.length} validation issue(s)`}</span>
                </div>
              ) : (
                <div className={styles.infoText2}>
                  <img height={"20px"} src={Tick} alt="" />
                  <span>{`All validation passed successfully`}</span>
                </div>
              )}
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={0}
          item
          display="flex"
          alignItems="center"
          gap={isSmDown ? "0px" : "12px"}
          xs={12}
          sm={4}
          justifyContent={{ xs: "space-between ", sm: "flex-end" }}
        >
          <Grid item>
            <Button
              variant="contained-white"
              style={{
                border: "1px solid #11111114",
                borderRadius: "4px",
                padding: isMdDown ? "9px 12px" : "9px 25px",
                fontSize: isMdDown ? "12px" : "14px",
                fontWeight: 500,
                lineHeight: "16.45px",
                boxShadow: "0px 1px 2px 0px #A4ACB933",
              }}
              onClick={() => handlePreview(true)}
              tabIndex={-1}
            >
              Preview
            </Button>{" "}
          </Grid>
          <Grid item id="buttonUpdate">
            <Button
              variant="contained-primary"
              style={{
                padding: isMdDown ? "9px 12px" : "9px 25px",
                fontSize: isMdDown ? "12px" : "14px",
                borderRadius: "4px",
                fontWeight: 500,
                lineHeight: "16.45px",
                textTransform: "capitalize",
              }}
              type="submit"
            >
              {loading ? "Loading" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );

  const validationErrorActionButtons = (
    <>
      <Button
        onClick={() => setIsConfirmValidationModalOpen(false)}
        tabIndex={-1}
      >
        Cancel
      </Button>{" "}
      <Button
        onClick={(e) => {
          handleUpdateSurvey(e, true, false, true);
        }}
        type="submit"
      >
        {validationLoading ? "Loading" : "Update"}
      </Button>
    </>
  );

  return (
    <>
      <MUIDialog
        open={Boolean(selectedSurveyCode)}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        height={isMdDown ? "800px" : isSmDown ? "600px" : "800px"}
        title="Update Survey"
        description=" A survey is a series of questions that either have a choice or input
          that need to be answered by the user."
        actions={surveyManagementActionButtons}
        toolTipClasses={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "fit-content",
        }}
        iconClasses={{
          marginLeft: "5px",
        }}
        code={"survey-edit"}
      >
        {!selectedSurvey ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "300px",
            }}
          >
            <img src={loadingImg} width={50} height={50} alt="Loading" />
            <span>Please wait...</span>
          </div>
        ) : (
          <div style={{ marginTop: "0px" }}>
            <Tabs
              defaultActiveTab={
                searchParams.get("activeTab")?.includes("recipients") ? 2 : 0
              }
              style={{ marginTop: "0px" }}
            >
              <Tab
                style={{ marginTop: "0px" }}
                label="Details"
                id="tabDetails"
                component={
                  <Details
                    surveyCodes={surveyCodes}
                    codeErrorObject={codeErrorObject}
                    error={error}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                    setCodeErrorObject={setCodeErrorObject}
                  />
                }
              />
              <Tab
                label="Questions"
                id="tabQuestions"
                component={
                  <Questions
                    questions={questions}
                    setQuestions={setQuestions}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                    loading={loading}
                  />
                }
              />
              <Tab
                label="Recipients"
                id="tabSurveyRecipients"
                component={
                  <Recipients
                    survey={selectedSurvey}
                    setSurvey={setSelectedSurvey}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                  />
                }
              />
              <Tab
                label="Configuration"
                id="tabSurveyConfiguration"
                component={
                  <Configuration
                    survey={selectedSurvey}
                    setSurvey={setSelectedSurvey}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                  />
                }
              />
              <Tab
                label={`Validations (${validations?.length || 0})`}
                id="tabSurveyValidations"
                component={
                  <Validations
                    validations={validations}
                    isValidationLoading={isValidationLoading}
                    setIsValidationLoading={setIsValidationLoading}
                    setIsCommandBarVisible={setIsCommandBarVisible}
                  />
                }
              />
            </Tabs>
            <Preview
              survey={selectedSurvey}
              questions={questions}
              setQuestions={setQuestions}
              open={preview}
              handleClose={() => {
                handlePreview(false);
              }}
            />
          </div>
        )}
      </MUIDialog>

      <MUIDialog
        onClose={() => setIsConfirmValidationModalOpen(false)}
        open={Boolean(isConfirmValidationModalOpen)}
        fullWidth
        maxWidth="md"
        title="Are you sure, you want to update/publish survey with validation error?"
        actions={validationErrorActionButtons}
      >
        <Validations
          validations={validations || []}
          // isValidationLoading={isValidationLoading}
          // setIsValidationLoading={setIsValidationLoading}
          setIsCommandBarVisible={setIsCommandBarVisible}
        />
      </MUIDialog>
    </>
  );
};
const PublishUnpublishButtons = ({ selectedSurvey, handleUpdateSurvey }) => {
  const publishClass = selectedSurvey?.isEnabled
    ? styles.publishEnabled
    : styles.publishDisabled;

  const unpublishClass = !selectedSurvey?.isEnabled
    ? styles.unpublishDisabled
    : styles.unpublishEnabled;

  return (
    <div className={styles.container}>
      <Tooltip
        title={
          selectedSurvey?.isEnabled
            ? "Survey is currently published. Click 'Unpublish' to unpublish it."
            : "Click to publish it."
        }
      >
        <div
          className={publishClass}
          id="buttonPublish"
          onClick={(event) => handleUpdateSurvey(event, true, true)}
          tabIndex={-1}
        >
          Publish
        </div>
      </Tooltip>
      <Tooltip
        title={
          !selectedSurvey?.isEnabled
            ? "Survey is currently unpublished. Click 'Publish' to publish it."
            : "Click to 'Unpublish' it."
        }
      >
        <div
          className={unpublishClass}
          id="buttonUnpublish"
          onClick={(event) => handleUpdateSurvey(event, true, false)}
          tabIndex={-1}
        >
          Unpublish
        </div>
      </Tooltip>
    </div>
  );
};

const Configuration = ({ survey, setSurvey, setIsCommandBarVisible }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [emailSendTime, setEmailSendTime] = useState(
    survey.emailSendTime ?? config.defaults.SURVEY_EMAIL_SEND_TIME
  );
  const [openEmailRecipientsPreview, setOpenEmailRecipientsPreview] =
    useState(false);

  const handleCloseEmailRecipientsPreview = () => {
    setOpenEmailRecipientsPreview(false);
  };

  const handleEmailSendTimeChange = (e) => {
    //to handle case when we click on cross icon then we get nothing in e
    if (!e) return;
    const time = e?.format(timeFormat);
    // survey.emailSendTime = time;
    setSurvey({ ...survey, emailSendTime: time });
    setEmailSendTime(time);
  };

  const handlePreviewRecipients = () => {
    setOpenEmailRecipientsPreview(true);
  };

  const tabs = [
    { name: "Email", component: <Emails survey={survey} /> },
    { name: "Exemptions", component: <Exemptions survey={survey} /> },
    {
      name: "Notices",
      component: (
        <Notices
          setIsCommandBarVisible={setIsCommandBarVisible}
          type="Survey"
          id={survey?.id}
        />
      ),
    },
  ];

  return (
    <div className={styles.container}>
      {/* Sidebar */}
      <div className={styles.sidebar}>
        <div
          style={{ fontSize: "18px", fontWeight: 600, marginBottom: "15px" }}
        >
          Manage Configurations
        </div>
        <div className={styles.tabList}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`${styles.tab} ${
                activeTabIndex === index ? styles.activeTab : ""
              }`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.name === "Email" ? (
                <div className={styles.iconsClasses}>
                  <img src={mail} alt="mail" height={25} width={25} />
                  <span>{tab.name}</span>
                </div>
              ) : tab.name === "Exemptions" ? (
                <div className={styles.iconsClasses}>
                  <img
                    src={exemptions}
                    alt="exemptions"
                    height={25}
                    width={25}
                  />
                  <span>{tab.name}</span>
                </div>
              ) : (
                <div className={styles.iconsClasses}>
                  <img src={notice} alt="notice" height={25} width={25} />
                  <span>{tab.name}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className={styles.content}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            margin: "20px 0px",

            // width: "100%",
          }}
        >
          <div>
            Email Schedule
            <br />
            <TimePicker
              use12Hours
              changeOnBlur
              size="large"
              format="h:mm A"
              label="Email Schedule"
              style={{ height: "36px" }}
              defaultValue={dayjs(emailSendTime, timeFormat)}
              onChange={handleEmailSendTimeChange}
              //disabled={emailSendModeSelected !== "Automatic"}
              placeholder="Send time"
              getPopupContainer={(triggerNode) => {
                // PURPOSE:  Used to position time picker relative to the question.
                return triggerNode.parentNode;
              }}
              renderExtraFooter={() => (
                <div
                  style={{
                    color: "blue",
                    marginLeft: "10px",
                    lineHeight: "18px",
                  }}
                >
                  Set daily time to send email invitations and reminders.
                </div>
              )}
            ></TimePicker>
          </div>
          <div>
            <Button
              // size="small"
              onClick={handlePreviewRecipients}
              startIcon={<VisibilityIcon />}
              variant="contained-primary"
              style={{
                borderRadius: "4px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "12px ",
                paddingRight: "12px ",
                height: "36px",
                width: "104px",
              }}
            >
              Preview
            </Button>
          </div>
        </div>
        {tabs[activeTabIndex].component}
      </div>

      {/* To show Preview  */}
      {openEmailRecipientsPreview && (
        <EmailRecipientsPreview
          survey={survey}
          isOpen={openEmailRecipientsPreview}
          onClose={handleCloseEmailRecipientsPreview}
          disabled={true}
        ></EmailRecipientsPreview>
      )}
    </div>
  );
};

export const Validations = ({
  validations,
  isValidationLoading,
  setIsValidationLoading,
  setIsCommandBarVisible,
}) => {
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  const columns = [
    { Header: "Category", accessor: "category" },
    { Header: "Source", accessor: "source" },
    {
      Header: "Property",
      accessor: "property",
      Cell: ({ row }) => {
        const { property } = row.original;
        return (
          <Chip
            label={property}
            sx={{
              backgroundColor: "#E0E0E0",
              color: grey[700],
              fontWeight: "bold",
            }}
          />
        );
      },
    },
    { Header: "Summary", accessor: "summary" },
  ];

  useEffect(() => {
    if (!isValidationLoading && setIsValidationLoading) {
      setIsValidationLoading(true);
    }
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isValidationLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "250px",
              }}
            >
              <img src={loadingImg} width={50} height={50} alt="Loading" />
              <span>Please wait...</span>
            </div>
          ) : (
            <Table columns={columns} data={validations} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const Notices = ({ setIsCommandBarVisible, type, id }) => {
  setIsCommandBarVisible(false);
  const sections = [
    "Survey_Recipient_Unavailable",
    "Survey_Recipient_BeforeStart",
    "Survey_Recipient_Completion",
    "Exemption_BeforeStart",
    "Exemption_Completion",
  ];
  return <NoticeEditor sections={sections} type={type} id={id} />;
};

export const Render = (props) => {
  const { isSmDown } = useMediaQuery();
  return (
    <>
      {props?.questions?.map((question, index) => {
        const rowStyle = {
          background: "#FFFFFF",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          margin: "20px 0px",
          padding: "20px 30px",
          display: "flex",
          gap: isSmDown ? "4px" : "30px",
          flexDirection: isSmDown ? "column" : "row",
        };
        const questionType = config.typeLookup(question.type); // Get question type
        return questionType.render({
          index: index,
          rowStyle: rowStyle,
          question: question,
          questions: props.questions,
          setQuestions: props.setQuestions,
          survey: props.survey,
          preview: false,
          code: question.code,
          // setSurvey: props.setSurvey,
          // questionsResolveVisibility: props.questionsResolveVisibility,
        });
      })}
    </>
  );
};

Overview.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const Details = (props) => {
  props.setIsCommandBarVisible(true);

  const { selectedSurvey } = useContext(SurveyContext);
  const { cache, surveyService } = useContext(AuthContext);
  const [isOnboarding, setIsOnboarding] = useState(true);
  const [metricsModal, setMetricsModal] = useState({
    isOpen: false,
    type: "",
    data: [],
    options: {},
  });

  const [skipValue, setSkipValue] = useState(cache.get("skipValue"));
  const [startDate, setStartDate] = useState(
    dayjs(cache.get("surveyActivePeriod_EffectiveDate") || dayjs())
  );
  const [endDate, setEndDate] = useState(
    dayjs(
      cache.get("surveyActivePeriod_ExpirationDate") || dayjs().add(1, "day")
    )
  );
  const [sampleGoalPct, setSampleGoalPct] = useState(
    cache.get("sampleGoalPct")
  );
  // TODO: Survey.PercentRate property
  const [surveyMetrics, setSurveyMetrics] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState(
    selectedSurvey.timeZone ?? utils.getUserTimeZone() ?? {}
  );
  const [surveyDays, setSurveyDays] = useState(
    endDate?.diff(startDate, "days")
  );

  /* When we create survey => timezone && SurveyActive period is null,so When we land on detail page 
   then we update timezone and survey active perio on Updated Automatoically*/
  const initializeTimeZone = () => {
    if (!selectedSurvey.timeZone) {
      const userTimeZone = utils.getUserTimeZone();
      cache.set("timeZone", userTimeZone);
      setSelectedTimezone(userTimeZone);
    } else {
      setSelectedTimezone(selectedSurvey.timeZone);
    }
  };

  const initializeSurveyActivePeriod = () => {
    if (!cache.get("surveyActivePeriod_EffectiveDate")) {
      cache.set("surveyActivePeriod_EffectiveDate", startDate);
    }
    if (!cache.get("surveyActivePeriod_ExpirationDate")) {
      cache.set("surveyActivePeriod_ExpirationDate", endDate);
    }
  };

  const getSurveyMetrics = async () => {
    // utils.log.info(":: Survey(Details).getSurveyMetrics()");
    try {
      const metricsData = await surveyService?.getSurveyMetrics(
        selectedSurvey?.code
      );
      if (metricsData?.length > 0) {
        setSurveyMetrics(metricsData);
      }
    } catch (error) {
      // utils.log.error("Failed to fetch the survey metrics", error);
    }
  };

  useEffect(() => {
    initializeTimeZone();
    initializeSurveyActivePeriod();
    getSurveyMetrics();
  }, []);

  const handleTimezoneChange = (e) => {
    const timeZone = e.value;
    setSelectedTimezone(timeZone);
    cache.set("timeZone", timeZone);
  };

  const handleSurveyActivePeriodOkClick = (timeRange) => {
    if (timeRange === null) return;
    utils.assert(timeRange.length === 2, "timeRange.length !== 2");
    utils.assert(
      dayjs(timeRange[0]).isValid(),
      `Start(timeRange[0]) is invalid time (expect ${dateTimeFormat})`
    );

    if (timeRange[1] != null) {
      utils.assert(
        dayjs(timeRange[1]).isValid(),
        `End(timeRange[1]) is invalid time (expect ${dateTimeFormat})`
      );
    }
    setStartDate(dayjs(timeRange[0]));
    setEndDate(dayjs(timeRange[1]));
    cache.set(
      "surveyActivePeriod_EffectiveDate",
      dayjs(timeRange[0]).format("YYYY-MM-DDTHH:mm:ssZ")
    );
    cache.set(
      "surveyActivePeriod_ExpirationDate",
      dayjs(timeRange[1]).format("YYYY-MM-DDTHH:mm:ssZ")
    );
    setSurveyDays(dayjs(timeRange[1]).diff(dayjs(timeRange[0]), "days"));
  };

  const handleSampleGoalPctChange = (e) => {
    setSampleGoalPct(e.target.value);
    cache.set("sampleGoalPct", e.target.value);
  };

  const handleSampleGoalPctBlur = () => {
    if (sampleGoalPct < 0) {
      setSampleGoalPct(0);
    } else if (sampleGoalPct > 100) {
      setSampleGoalPct(100);
    }
  };

  const handleSkipValue = (e) => {
    const updatedSkipValue = Number(e.target.value);
    setSkipValue(updatedSkipValue);
    cache.set("skipValue", updatedSkipValue);
  };

  const handleOpenMetricsModal = (type, data, options) => {
    setMetricsModal({
      isOpen: true,
      type: type,
      data,
      options,
    });
  };

  const handleCloseMetricsModal = () => {
    setMetricsModal({
      isOpen: false,
      type: "",
      data: [],
      options: {},
    });
  };

  const handleClick = (metric) => {
    const dates = metric?.daily?.map((entry) => entry?.date);
    const totals = metric?.daily?.map((entry) => entry?.total);
    const newData = [
      {
        name: metric?.type,
        data: totals || [],
      },
    ];
    handleOpenMetricsModal(metric?.metric, newData, {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: dates,
      },
    });
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      props.error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      props.setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        props.error.getErrorObject(name),
      ]);
    } else if (
      name === "code" &&
      !utils.isUniqueValue(cache.get("code"), props.surveyCodes)
    ) {
      props.error.setErrorObject("code", true, "Code already used.");
      props.setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        props.error.getErrorObject("code"),
      ]);
    } else {
      props.error.clearErrorObject(name);
      const filteredErrors = props.codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      props.setCodeErrorObject(filteredErrors);
    }
  };

  const handleInputChange = function (e) {
    const { name } = e.target;
    const updatedErrors = props.codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    props.setCodeErrorObject(updatedErrors);
    cache.set(e);
  };

  const getHelperTextMessage = (name) => {
    return props.codeErrorObject?.find((error) => error?.name === name)
      ?.message;
  };
  const hasError = (name) => {
    return !!props?.codeErrorObject?.find((error) => error?.name === name)
      ?.state;
  };

  return (
    <div className={styles.updateSurveyBg}>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={12}>
          <div className={styles.detailsBarChartWrapperHead}>
            <div className={styles.detailsBarChartContainer}>
              {surveyMetrics &&
                surveyMetrics?.map((metric) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClick(metric)}
                  >
                    <div className={styles.overallmetric}>
                      <div className={styles.metricSection}>
                        <div className={styles.metricTitle}>
                          {metric?.metric}
                        </div>
                        <div className={styles.metricTotal}>
                          <div className={styles.total}>{metric?.total}%</div>
                          <div className={styles.overallChange}>
                            <span className={styles.numericChange}>+</span>
                            <span className={styles.numericChange}>0.0</span>
                            <span className={styles.arrowClasses}>
                              <img src={arrowUp} alt="arrowUp" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={styles.detailsBarChartWrapper}>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12} sm={6} md={6} id="textboxName">
            <Input
              // required
              label="Name"
              fullWidth
              name="name"
              onChange={handleInputChange}
              defaultValue={cache.get("name")}
              value={cache.get("name")}
              style={{
                paddingBottom: hasError("code") ? "20px" : null,
              }}
              error={hasError("name")}
              helperText={getHelperTextMessage("name")}
              onBlur={handleInputBlur}
            />
          </Grid>

          <Grid item xs={9} sm={4} md={5} id="textboxCode">
            <Input
              // required
              label="Code"
              fullWidth
              name="code"
              onChange={handleInputChange}
              defaultValue={cache.get("code")}
              style={{
                paddingBottom: hasError("name") ? "20px" : null,
              }}
              value={cache.get("code")}
              error={hasError("code")}
              helperText={getHelperTextMessage("code")}
              onBlur={handleInputBlur}
            />
          </Grid>

          {
            <Grid
              item
              sx={3}
              sm={2}
              md={1}
              paddingBottom={
                hasError("code") || hasError("name") ? "20px" : "16px"
              }
              display={"flex"}
              justifyContent={"end"}
            >
              {isOnboarding ? (
                <CustomWidthTooltip
                  code={"survey-question-add"}
                  placement={"left-start"}
                >
                  <HelpOutlineOutlined
                    style={{ cursor: "pointer", marginRight: "15px" }}
                    color="primary"
                    fontSize="medium"
                  />
                </CustomWidthTooltip>
              ) : (
                <HelpOutlineOutlined
                  style={{ cursor: "pointer" }}
                  color="primary"
                  fontSize="medium"
                />
              )}
            </Grid>
          }
          <Grid
            item
            xs={12}
            md={6}
            style={{ zIndex: "100" }}
            id="dropdownLocation"
          >
            <div className={styles.location}>
              <span className={styles.locationText}>Location</span>
              <div>
                <TimezoneSelect
                  styles={customStylesOfTimeZone}
                  height={"56px"}
                  value={selectedTimezone}
                  onChange={handleTimezoneChange}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={styles.location}>
              <span className={styles.locationText}>
                Survey Active Period ({surveyDays} days)
              </span>
              <RangePicker
                style={{ height: "56px", width: "100%" }}
                size="large"
                showTime={{
                  format: "h:mm A",
                }}
                defaultValue={[
                  dayjs(startDate, dateTimeFormat),
                  dayjs(endDate, dateTimeFormat),
                ]}
                format={dateTimeFormat}
                onOk={handleSurveyActivePeriodOkClick}
                getPopupContainer={(triggerNode) => {
                  // PURPOSE:  Used to position time picker relative to the question.
                  return triggerNode.parentNode;
                }}
              />
            </div>
          </Grid>

          <Grid item sm={4} xs={3} md={3} lg={3} id="textboxSkipValue">
            <Input
              type="number"
              label="Skip Value"
              fullWidth
              name={"skipValue"}
              defaultValue={skipValue}
              value={skipValue}
              onChange={handleSkipValue}
              hideNumberSpinner
            />
          </Grid>
          <Grid item sm={6} xs={7} md={7} lg={7} id="textboxGoalPct">
            <div style={{ position: "relative" }}>
              <span className={styles.samplingGoal}>Sampling Goal(%)</span>
            </div>
            <div>
              <div className={styles.samplingGoal}>
                <div className={styles.samplingGoalIncDec}>
                  <span className={styles.samplingValue}>0</span>
                  <span className={styles.samplingValue}>100</span>
                </div>
              </div>
              <div className={styles.sliderClasses}>
                <Slider
                  name="sampleGoalPct"
                  value={sampleGoalPct}
                  onChange={handleSampleGoalPctChange}
                  aria-labelledby="input-slider"
                />
              </div>
            </div>
          </Grid>
          <Grid item sm={2} xs={2} md={2} lg={2} id="textboxGoalPct ">
            <Input
              sx={{ width: "100% !important" }}
              label="Goal(%)"
              name={"sampleGoalPct"}
              defaultValue={cache.get("sampleGoalPct")}
              value={sampleGoalPct}
              onChange={handleSampleGoalPctChange}
              onBlur={handleSampleGoalPctBlur}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <Input
              type="number"
              label="Skip Value"
              fullWidth
              name={"skipValue"}
              defaultValue={skipValue}
              value={skipValue}
              onChange={handleSkipValue}
              hideNumberSpinner
            />
          </Grid>
          <Grid item xs={8} md={5}>
            <div style={{ position: "relative" }}>
              <span className={styles.samplingGoal}>Sampling Goal(%)</span>
            </div>
            <div>
              <div className={styles.samplingGoal}>
                <div className={styles.samplingGoalIncDec}>
                  <span className={styles.samplingValue}>0</span>
                  <span className={styles.samplingValue}>100</span>
                </div>
              </div>
              <div className={styles.sliderClasses}>
                <Slider
                  name="sampleGoalPct"
                  value={sampleGoalPct}
                  onChange={handleSampleGoalPctChange}
                  aria-labelledby="input-slider"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={4} md={1}>
            <Input
              sx={{ width: "100% !important" }}
              label="Goal(%)"
              name={"sampleGoalPct"}
              defaultValue={cache.get("sampleGoalPct")}
              value={sampleGoalPct}
              onChange={handleSampleGoalPctChange}
              onBlur={handleSampleGoalPctBlur}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid> */}

          <Grid item xs={5}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="350px" height="350px">
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  nameKey="name"
                  dataKey="value"
                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>

          <MUIDialog
            open={metricsModal.isOpen}
            onClose={handleCloseMetricsModal}
            maxWidth="lg"
            fullWidth
          >
            <Chart
              options={metricsModal.options}
              series={metricsModal?.data || []}
              type="bar"
              width="100%"
              height="400px"
            />
          </MUIDialog>
        </Grid>
      </div>
    </div>
  );
};

const Add = (props) => {
  const { surveyService, error } = useContext(AuthContext);
  const { fetchSurveys, list: allSurveys } = useContext(SurveyContext);
  const { enqueueSnackbar } = useSnackbar();

  const [codeErrorObject, setCodeErrorObject] = useState([]);
  const [inputs, setInputs] = useState({
    code: "",
    name: "",
    surveyActivePeriod_EffectiveDate: dayjs(),
    surveyActivePeriod_ExpirationDate: dayjs().add(1, "day"),
    description: "",
  });

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };
  const isValid = () => {
    //if initially,there is no value of code and name => then we set error of code and name
    const name = inputs.code ? (inputs.name ? "" : "name") : "code";
    if (name) {
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    }

    return !error.hasErrors();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValid()) {
      try {
        if (
          dayjs(inputs.surveyActivePeriod_EffectiveDate).isAfter(
            dayjs(inputs.surveyActivePeriod_ExpirationDate)
          )
        ) {
          enqueueSnackbar("Start date cannot be after end date", {
            variant: "error",
            persist: true,
          });
          return;
        }

        const result = await surveyService.add(inputs);

        if (!result.ok) {
          const error = await result.json();
          enqueueSnackbar(error.detail, { variant: "error", persist: true });
        } else {
          // we update get all to get the new survey data
          fetchSurveys();
          enqueueSnackbar("Success!", { variant: "success" });
          onClose();
        }
      } catch (error) {
        // utils.log.error("Error adding the survey", error);
      }
    }
  };

  const getMinEndDate = () => {
    if (inputs.surveyActivePeriod_EffectiveDate) {
      return dayjs(inputs.surveyActivePeriod_EffectiveDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    return dayjs().add(1, "day").format("YYYY-MM-DD");
  };

  const handleSurveyActivePeriodOkClick = (timeRange) => {
    if (timeRange === null) return;

    utils.log.event(`handleSurveyActivePeriodOkClick(timeRange)`, timeRange);
    utils.assert(timeRange.length === 2, "timeRange.length !== 2");
    utils.assert(
      dayjs(timeRange[0]).isValid(),
      `Start(timeRange[0]) is invalid time (expect ${dateTimeFormat})`
    );

    if (timeRange[1] != null) {
      utils.assert(
        dayjs(timeRange[1]).isValid(),
        `End(timeRange[1]) is invalid time (expect ${dateTimeFormat})`
      );
    }
    setInputs((prev) => ({
      ...prev,
      surveyActivePeriod_EffectiveDate: dayjs(timeRange[0]).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      ),
      surveyActivePeriod_ExpirationDate: dayjs(timeRange[1]).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      ),
    }));
  };

  const { isMdDown } = useMediaQuery();

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const duplicateSurveyCode = allSurveys?.find(
      (survey) => survey?.code?.toLowerCase() === value.toLowerCase()
    );
    if (!value) {
      //value can't be empty
      error.setErrorObject(
        name,
        true,
        `${name?.charAt(0)?.toUpperCase() + name?.slice(1)} Can't be empty`
      );
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject(name),
      ]);
    } else if (name === "code" && duplicateSurveyCode) {
      //value can't be duplicate
      error.setErrorObject("code", true, "Code already used.");
      setCodeErrorObject((prevErrors) => [
        ...prevErrors,
        error.getErrorObject("code"),
      ]);
    } else {
      //if not any error for this name => then clear the error for the given name
      error.clearErrorObject(name);
      const filteredErrors = codeErrorObject?.filter(
        (error) => error?.name !== name
      );
      setCodeErrorObject(filteredErrors);
    }
  };

  const handleInputChange = function (e) {
    const { name, value } = e.target;
    const updatedErrors = codeErrorObject?.filter(
      (error) => error?.name !== name
    );
    setCodeErrorObject(updatedErrors);
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const getHelperTextMessage = (name) => {
    return codeErrorObject?.find((error) => error?.name === name)?.message;
  };
  const hasError = (name) => {
    return !!codeErrorObject?.find((error) => error?.name === name)?.state;
  };

  const onClose = () => {
    props.setIsAddModalOpen(false);
    //onClose => clear the error from both error object and setCodeErrorObject
    setCodeErrorObject([]);
    error.clearErrorObject();
  };

  return (
    <MUIDialog
      open={props.open}
      onClose={onClose}
      title="Add Survey"
      width={"700px"}
      height={"510px"}
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            style={{
              padding: isMdDown ? "9px 12px" : "10 12px",
              fontSize: isMdDown ? "12px" : "14px",
              borderRadius: "8px",
              border: "1px solid #11111114",
              color: " #111111",
              boxShadow: "0px 1px 2px 0px #A4ACB933",
            }}
            type="button"
            variant="outlined"
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              padding: isMdDown ? "9px 12px" : "10 12px",
              fontSize: isMdDown ? "12px" : "14px",
              borderRadius: "8px",
            }}
            type="submit"
          >
            ADD
          </Button>
        </div>
      }
      // height="auto"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={3} padding="10px 0px" paddingBottom={"75px"}>
        <Grid item xs={12} md={12} id="textboxCode">
          <Input
            label="Code"
            fullWidth
            name="code"
            onChange={handleInputChange}
            defaultValue={inputs.code}
            value={inputs.code}
            error={hasError("code")}
            helperText={getHelperTextMessage("code")}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={12} md={12} id="textboxTitle">
          <Input
            label="Name"
            fullWidth
            name="name"
            onChange={handleInputChange}
            defaultValue={inputs.name}
            value={inputs.name}
            error={hasError("name")}
            helperText={getHelperTextMessage("name")}
            onBlur={handleInputBlur}
          />
        </Grid>

        <Grid item xs={12} sx={{ position: "relative" }}>
          <div className="custom-range-inputs-grid">
            <RangePicker
              style={{ height: "56px", width: "100%" }}
              // className
              showTime={{ format: "h:mm A" }}
              popupStyle={{ height: "20px" }}
              placement="bottomRight"
              cellHeight={10}
              cellWidth={10}
              // defaultValue={[dayjs(inputs.surveyActivePeriod_EffectiveDate, dateTimeFormat),dayjs(inputs.surveyActivePeriod_ExpirationDate, dateTimeFormat)]}
              format={dateTimeFormat}
              onOk={handleSurveyActivePeriodOkClick}
              getPopupContainer={(triggerNode) => {
                // PURPOSE:  Used to position time picker relative to the question.
                return triggerNode.parentNode;
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={12} id="textboxDescription">
          <Input
            label="Description"
            name="description"
            fullWidth
            onChange={handleChange}
            value={inputs.description}
          />
        </Grid>
      </Grid>
    </MUIDialog>
  );
};

const Preview = (props) => {
  // const { cache } = useContext(AuthContext);
  // const [previewQuestions, setPreviewQuestions] = useState(
  //   cache.get("questions")
  // );

  // useEffect(() => {
  //   // // utils.log.info(":: Preview question in useEffect cache",cache)
  //   // if(cache?.questions?.length > 0) {
  //   setPreviewQuestions(cache.get("questions"));
  //   // }
  // }, [cache]);

  const [allQuestions, setAllQuestions] = useState([]);

  const formattedQuestions = () => {
    const temp = [...props.questions];
    dependencyUtil.resolveVisibilities(temp);
    const allQn = temp.filter((q) => q.isVisible);
    setAllQuestions(allQn);
  };

  useEffect(() => {
    formattedQuestions();
  }, [props.questions]);

  return (
    <MUIDialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen
      maxWidth="xl"
    >
      <DialogContent style={{ padding: "50px 50px 0px 50px" }}>
        <Grid container spacing={12}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              {props?.survey?.name}
            </span>
            <Debug value={props}></Debug>
          </Grid>
          <Grid item xs={12}>
            <Render
              survey={props.survey}
              // questions={props?.questions}
              questions={allQuestions}
              setQuestions={props?.setQuestions}
            ></Render>
          </Grid>
        </Grid>
      </DialogContent>
    </MUIDialog>
  );
};

const Recipients = ({ survey, setSurvey, setIsCommandBarVisible }) => {
  // utils.log.component(":: Survey.Recipients()");

  // #region Assertions
  utils.assert(survey != null, "Survey required.  Unable to find recipients.");
  const { worksiteService } = useContext(AuthContext);
  /* TO RENDER Worksite ,Add Recipients and Upload Recipients at Same Place */
  const [activeState, setActiveState] = useState({
    worksite: false,
    UploadRecipients: false,
    addRecipients: false,
  });
  const [selectedWorksiteCode, setSelectedWorksiteCode] = useState("");
  const [commonWorksiteCode, setCommonWorksiteCode] = useState("");
  const [worksites, setWorksites] = useState([]);

  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  if (setIsCommandBarVisible) setIsCommandBarVisible(true);
  /* const effectiveDate = dayjs(survey.surveyActivePeriod_EffectiveDate);
  const expirationDate = dayjs(survey.surveyActivePeriod_ExpirationDate);
  const surveyDays = expirationDate.diff(effectiveDate, "days"); */

  const getWorksites = async () => {
    try {
      const result = await worksiteService.getAll();
      //const data = await result.json();
      // Sort the data by the 'code' property
      result.sort((a, b) => a.code.localeCompare(b.code));
      setWorksites(result);
      utils.log.stateChange("setWorksites(data)", result);
    } catch (error) {
      utils.log.error("Recipients.getWorksites()", error);
    }
  };

  useEffect(() => {
    getWorksites();
  }, []);

  const selectedWorksiteInfo = useMemo(
    () => worksites.find((w) => w.code === selectedWorksiteCode),
    [selectedWorksiteCode, worksites]
  );

  // const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!survey.emailSendTime) {
      setSurvey({
        ...survey,
        emailSendTime: config.defaults.SURVEY_EMAIL_SEND_TIME,
      });
    }
  }, [survey, setSurvey]);

  // #endregion
  // #region Events

  const handleChangeWorksite = (e) => {
    setSelectedWorksiteCode(e.target.value);
    setActiveState((prev) => ({
      ...prev,
      worksite: true,
      addRecipients: false,
      UploadRecipients: false,
    }));
  };

  const handleUploadRecipients = () => {
    setActiveState((prev) => ({
      ...prev,
      worksite: false,
      addRecipients: false,
      UploadRecipients: true,
    }));
    setSelectedWorksiteCode(null);
  };

  const handleAddRecipients = () => {
    setActiveState((prev) => ({
      ...prev,
      worksite: false,
      addRecipients: true,
      UploadRecipients: false,
    }));
    setSelectedWorksiteCode(null);
  };

  useEffect(() => {
    setSelectedWorksiteCode(commonWorksiteCode);
  }, [commonWorksiteCode]);
  // #endregion

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>Recipients</div>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Button
              size="medium"
              onClick={handleUploadRecipients}
              startIcon={<AddIcon />}
              variant="contained-primary"
              style={{
                borderRadius: "4px",
                background: "#FFFFFF",
                color: "#0066F9",
                border: "1px solid #11111114",
                // opacity: disabled ? "0.8" : "",
              }}
            >
              Upload Recipients From File
            </Button>
          </div>

          <div>
            <Button
              size="medium"
              onClick={handleAddRecipients}
              startIcon={<AddIcon />}
              variant="contained-primary"
              style={{
                borderRadius: "4px",
                // background: disabled ? "#aaa" : "",
                // opacity: disabled ? "0.8" : "",
              }}
            >
              Add Recipients
            </Button>
          </div>
        </div>
      </div>

      <Grid container style={{ marginBottom: "32px" }}>
        <Grid item xs={12} md={4}>
          <FormSelect
            label="Select worksite"
            value={selectedWorksiteCode}
            onChange={(e) => handleChangeWorksite(e)}
            data={utils.toSelectItem(worksites, "name", "code")}
            fullWidth
          />
        </Grid>
      </Grid>
      {activeState?.worksite === true ? (
        <WorksiteDetails
          survey={survey}
          selectedWorksiteCode={selectedWorksiteCode}
          selectedWorksiteInfo={selectedWorksiteInfo}
        />
      ) : activeState?.UploadRecipients === true ? (
        <Upload
          survey={survey}
          activeState={activeState}
          setActiveState={setActiveState}
          setCommonWorksiteCode={setCommonWorksiteCode}
        />
      ) : activeState?.addRecipients ? (
        <RecipientAdd
          survey={survey}
          activeState={activeState}
          setActiveState={setActiveState}
          setCommonWorksiteCode={setCommonWorksiteCode}
        />
      ) : null}
    </>
  );
};

const WorksiteDetails = ({
  survey,
  selectedWorksiteCode,
  selectedWorksiteInfo,
}) => {
  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #A4ACB933",
        background: "#FFFFFF",
        border: "1px solid #E1E1E1",
        borderRadius: "6px",
        padding: "20px",
      }}
    >
      <div>
        <h5>Summary</h5>
        <Tabs>
          <Tab
            label="WorkSites Table"
            component={
              <Summary
                survey={survey}
                selectedWorksiteCode={selectedWorksiteCode}
                selectedWorksiteInfo={selectedWorksiteInfo}
              ></Summary>
            }
          />
          <Tab label="Find UID" component={<Find survey={survey}></Find>} />
        </Tabs>
      </div>
    </div>
  );
};

const Metrics = () => {
  // utils.log.component(":: Survey.Metrics()");
  return (
    <>
      <h1>Metrics</h1>
      <Button>Export (CSV, Excel)</Button>
    </>
  );
};

export const SurveySelector = ({
  filter, // string (enabled, disabled, active) required
  selected, // string - required
  setSelected, // function - required
  label = "Survey", // string
  firstItemSelected = "",
}) => {
  // utils.log.component(":: Survey.SurveySelector()", {
  //   filter,
  //   selected,
  //   setSelected,
  // });
  // #region Assertions
  // #endregion
  // #region Functions
  const updateList = async () => {
    try {
      /*       const result = await surveyService.getAll(filter);
      const data = await result.json();
      if (data.status !== 500) {
        setList(utils.toSelectItem(data, "name", "code"));
        // utils.log.stateChange("setList(data)", data);
      } */
      const list = await surveyService.getAll(filter);
      // if (allSurvey) {
      //   const updatedSurvey = [
      //     { label: "All Survey", value: "allSurvey" },
      //     ...utils.toSelectItem(list, "name", "code"),
      //   ];
      //   setList(updatedSurvey);
      //   setSelected(updatedSurvey[0]?.value);
      // } else {
      setList(utils.toSelectItem(list, "name", "code") || []);
      // }
      if (firstItemSelected) {
        setSelected(list[0]?.code);
      }
      // utils.log.stateChange("setList(data)", list);
    } catch (error) {
      // utils.log.error("Error updating list:", error);
    }
  };
  // #endregion
  // #region Initialize
  const { surveyService } = useContext(AuthContext);
  const [list, setList] = useState([]);

  // #endregion
  // #region Events

  useEffect(() => {
    updateList();
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    setSelected(event.target.value);
    // utils.log.stateChange(
    //   `SurveySelector.setSelected(event.target.value: ${event.target.value})`
    // );
  };

  // if(!firstItemSelected){
  //   return;
  // }
  // #endregion

  return (
    <FormSelect
      label={label}
      data={list}
      fullWidth
      value={selected}
      onChange={(e) => {
        handleChange(e);
      }}
    ></FormSelect>
  );
};

const EmailRecipientsPreview = ({ survey, isOpen, onClose }) => {
  // utils.log.component(`:: Survey.EmailRecipientsPreview() isOpen: ${isOpen}`);
  // #region Assertions
  utils.assert(survey != null, "Survey required.");
  // #endregion
  // #region Functions

  // #endregion
  // #region Initialize
  const { surveyService, isDevelopment } = useContext(AuthContext);
  const [recipients, setRecipients] = useState([]);
  const [filteredRecipients, setFilteredRecipients] = useState([]);

  const filters = utils.toSelectItem([`Invitation`, `Reminder`, `Followup`]);
  const [filterSelected, setFilterSelected] = useState(filters[0].value);
  const columns = [
    { Header: "Recipient", accessor: "fullName" },
    { Header: "Email", accessor: "email" },
    /* { Header: "Type", accessor: "messageTemplate" }, */
  ];

  const updateList = async () => {
    try {
      const recipients = await surveyService.recipientsContact(survey.code);
      setRecipients(recipients);
      const filter = filterSelected ?? filters[0].value;
      const filteredRecipients = recipients.filter(
        (item) => item.messageTemplate === filter
      );
      setFilteredRecipients(filteredRecipients);
      // utils.log.stateChange(
      //   `setRecipients(recipients, ${filter})`,
      //   recipients,
      //   filteredRecipients
      // );
    } catch (error) {
      // Handle any potential errors here
      // utils.log.error("Error updating list:", error);
    }
  };

  const handleFilterChange = (e) => {
    // utils.log.event("handleFilterChange(e)", e.target);
    const filter = e.target.value;
    setFilterSelected(filter);
    const filteredRecipients = recipients.filter(
      (item) => item.messageTemplate === filter
    );
    setFilteredRecipients(filteredRecipients);
    // utils.log.stateChange(`setFilterSelected(${filter})`, filteredRecipients);
  };
  const handleSendEmails = async () => {
    // utils.log.event("handleSendEmails()");
    await surveyService.recipientsContact(survey.code, false);
    onClose();
  };

  // #endregion
  // #region Events
  useEffect(() => {
    // utils.log.useEffect("EmailRecipientsPreview", "Initialize");
    updateList();
  }, []);
  // #endregion

  return (
    <MUIDialog
      open={isOpen}
      onClose={onClose}
      title="Email Recipients(Preview)"
      description={
        isDevelopment
          ? "REMINDER: When in DEBUG mode, only a small subset of recipients are retrieved."
          : ""
      }
      actions={
        <Button
          variant="contained-primary"
          style={{ borderRadius: "4px" }}
          onClick={handleSendEmails}
        >
          Send NOW
        </Button>
      }
    >
      <Table
        columns={columns}
        data={filteredRecipients}
        selected
        caption={`Count: ${filteredRecipients.length} Total Recipients: ${recipients.length}`}
      />
    </MUIDialog>
  );
};

const RecipientAdd = ({ survey, setActiveState, setCommonWorksiteCode }) => {
  // utils.log.component(":: Survey.RecipientAdd()");
  if (!survey) return null;
  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #A4ACB933",
        background: "#FFFFFF",
        border: "1px solid #E1E1E1",
        borderRadius: "6px",
      }}
    >
      <Grid
        container
        sx={{
          padding: "15px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={4} sx={{ fontWeight: 600, fontSize: "20px" }}>
          Add Recipients
        </Grid>
      </Grid>
      <hr style={{ margin: "0px" }} />
      <Grid
        container
        spacing={2}
        sx={{ padding: "40px 15px 15px 40px", width: "100%" }}
      >
        <Detail
          surveyCode={survey.code}
          recipient={{}}
          mode="add"
          setActiveState={setActiveState}
          setCommonWorksiteCode={setCommonWorksiteCode}
        />
      </Grid>
    </div>
  );
};

/* 
FIXED
051223 - Prior survey details displayed on edit.
  STEPS: 
    1. Select a survey to edit from list.
    2. Note details of survey (e.g. code), then click Cancel or Update button.
    3. Select a different survey to edit from list.
    4. The edit screen displays the details of the prior survey.
    5. Note details of survey (e.g. code), then click Cancel or Update button.
    6. Edit the same survey from step 3.
    7. The edit screen displays the correct details of the survey.
  EXPECTED: The edit screen displays the correct details of the survey.
  CAUSE: Moving setSurveyOpen(true) from getSurvey() to handleEdit() caused extra renders.
  RESOLUTION: Put setSurveyOpen(true) back in getSurvey().
  FIX: Moved setSurveyOpen(true) back to getSurvey().
*/
