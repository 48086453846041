/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Badge from "@mui/material/Badge";
//import Table from "../components/Table";
import Tabs, { Tab } from "../components/Tabs";
import { AuthContext } from "../services/context";
import DashboardLayout from "../components/DashboardLayout";

import * as utils from "../services/utilities";
import Notices from "./notices";
import Metrics from "./metrics";
import Issues from "./issues";
import Scheduler from "./scheduler";
import Health from "./health";
import Payments from "./payments";
import Emails from "./emails";
import { StylesProvider } from "@material-ui/core/styles";

//import { enqueueSnackbar, useSnackbar } from "notistack";

const Admin = () => {
  const { employee } = useContext(AuthContext);
  // utils.log.component("Admin", employee);

  if (!(employee?.isAdministrator === true)) {
    return (
      <DashboardLayout title="Admin" description="Access denied.">
        <Dialog open>
          <DialogTitle>Access Denied</DialogTitle>
          <DialogContent>
            You do not have permission to access this page.
          </DialogContent>
        </Dialog>
      </DashboardLayout>
    );
  }
  //const { enqueueSnackbar } = useSnackbar();

  return (
    <div style={{ paddingTop: "100px" }}>
      <StylesProvider injectFirst>
        <DashboardLayout>
          <Tabs>
            <Tab
              label={
                <Badge badgeContent={5} color="primary" invisible>
                  Issues
                </Badge>
              }
              component={<Issues />}
            />
            <Tab label="Notices" component={<Notices />} />
            <Tab label="Health" component={<Health />} />
            <Tab label="Emails" component={<Emails />} />
            <Tab label="Payments" component={<Payments />} />
            <Tab label="Scheduler" component={<Scheduler />} />
            <Tab label="Metrics" component={<Metrics />} />
          </Tabs>
        </DashboardLayout>
      </StylesProvider>
    </div>
  );
};

export default Admin;
