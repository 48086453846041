// TODO: 051823 Move this to services folder.
// TODO: 062223 Implement dependency visualizer. https://github.com/projectstorm/react-diagrams
import { ValidateContext } from "./dependencySelector";
import * as config from "../../services/config";
import * as utils from "../../services/utilities";

// PURPOSE: Determine the dependency of question based on either we choose only question or question && choices and so on.....
// if(question && !choices && !category) => then type should be question
// if(question && !choices && category) => then type should be category
// if(question && choices && !category) => then type should be choices
// if(question && choices && category) => then type should be categoryChoice
export function getType(dependency) {
  let result = "none";
  if (dependency?.question != null && dependency?.choice == null) {
    if (dependency?.category && dependency?.category != null)
      result = "category";
    else result = "question";
  }
  if (dependency?.question != null && dependency?.choice != null) {
    if (dependency?.category && dependency?.category != null)
      result = "categoryChoice";
    else result = "choice";
  }
  return result;
}

export function createBase(question, choice) {
  // Question dependency (common to all question types)
  const dependency = {
    question: question,
    type: "question", // Default type
  };
  // Choice dependency (only for questions with choices)
  if (choice !== undefined && question.choices !== undefined)
    dependency.choice = choice;
  // Allow creation of choice dependency if question has choices

  dependency.type = getType(dependency); // Dependency types: question, choice, none
  return dependency;
}

export function create(question, choice, category) {
  // PURPOSE: Create a dependency object
  const dependency = {
    question: question,
  };
  if (choice != null) dependency["choice"] = choice;
  if (category != null) dependency["category"] = category;
  dependency.type = getType({ question: question, choice: choice });
  return dependency;
}

// PURPOSE: Return dependency from context question or choice
// => Basically qn which we select we put that question in context => and contex is cretad  object by ourself
export function get(context) {
  utils.log.info(":: dependencyUtil.get()", { context });
  utils.assert(context, "context is required");
  // TODO: Convert to retrieving dependency directly from survey.questions.

  switch (context.type) {
    case "question":
      return context?.question?.dependency;

    case "choice":
      return create(
        context?.choice?.dependency?.question,
        context?.choice?.dependency?.choice
      );
    default:
      // throw new Error(`dependency.get: Invalid context type(${context.type})`);
      break;
  }
}

export function set(context, dependency) {

  utils.log.info(":: set in dependency",{context,dependency});
  // PURPOSE: Assign the dependency to the correct context (question or choice)
  utils.assert(context, "context is required");
  utils.assert(
    dependency == null || dependency.type !== undefined,
    "dependency.type is required"
  );
  // #endregion
  // #region *** Initialize ***

  const handleValid = (context, dependency) => {
    switch (context.type) {
      case "question":
        questionSet(context, dependency);
        break;
      case "choice":
        choiceSet(context, dependency);
        break;
      // case "category":
      //   categorySet(context,dependency);
      //   break;
      default:
        defaultSet(context);
        break;
    }
  };

  // #endregion

  // #region *** Handle Events ***

  // #endregion
  ValidateContext(context, dependency, handleValid);
}

const questionSet = (context, dependency) => {
  // utils.log.event(":: dependency.questionSet()",{context,dependency});
  dependency == null
    ? (context.question["dependency"] = null) // Clear question dependency if null
    : (context.question["dependency"] = dependency); // Set question dependency
  if (context.choice !== undefined) context.choice = null; // Clear choice
  // utils.log.info("context.question", context.question);
};

const choiceSet = (context, dependency) => {
  context.question["dependency"] = null; // Clear question dependency

  dependency == null
    ? (context.choice["dependency"] = null) // Clear choice dependency if null
    : (context.choice["dependency"] = dependency); // Set choice dependency
};

const defaultSet = (context) => {
  if (context.question !== undefined) context.question["dependency"] = null;
  if (context.choice !== undefined) context.choice["dependency"] = null;
};
/* const isAnswered = (dependency) => {
  // PURPOSE: Check if question is answered
  switch (dependency.type) {
    case "question":
      return config.typeLookup(dependency.question.type).isAnswered(dependency);
    case "choice":
      return config.typeLookup(dependency.question.type).isAnswered(dependency);
    default:
      throw new Error(
        `isAnswered: Invalid dependency type(${dependency.type})`
      );
  }
}; */

export function getDependents() {
  // PURPOSE: Return the questions and choices that depend on this question or choice.
  debugger;
}

// PURPOSE: GET THE QUESTION/CHOICES FROM DEPENDENCY
export function getDependency(dependency) {
  if (dependency == null) return null;

  const type = getType(dependency);
  utils.log.info(":: getDependency",{dependency,type});
  switch (type) {
    case "question":
      return dependency.question;
    case "choice":
      return dependency.choice;
    case "category":
      return dependency.question.answer;
    case "categoryChoice":
      return dependency.question.answer;
    default:
      throw new Error(
        `getDependency: Invalid dependency type(${dependency.type})`
      );
  }
}

export const resolveDependency = (questions, dependencySource) => {
  // !KLUDGE: Returns correct references to dependencies.

  // Here, dependencySource is the  question on which this question is dependent
  utils.assert(questions != null, "questions can not be null.");
  utils.assert(
    Array.isArray(questions) &&
      questions.every((obj) => typeof obj === "object" && obj !== null),
    "questions is expected to be an array of objects."
  );

  if (dependencySource == null) return; // No dependency

  // find Actual questions from all questions list
  const question = questions.find(
    (q) => q.code === dependencySource.question.code
  );

  // inserting actual question on dependencySource
  dependencySource.question = question;

  utils.log.info(":: resolveDependency__1", { dependencySource });
  switch (dependencySource.type) {
    case "question":
      return dependencySource;
    /*       return {
        question: question,
        type: dependencySource.type,
        isAnswered: dependencySource.isAnswered,
      }; */

    case "choice":
      const choice = question.choices.find(
        (c) => c.code === dependencySource.choice.code
      );

      dependencySource.choice = choice;
      return dependencySource;
    /*       return = {
        question: question,
        choice: choice,
        type: dependencySource.type,
        isAnswered: dependencySource.isAnswered,
      }; */

    default:
      throw new Error(`Invalid dependency type: ${dependencySource.type}`);
  }
};
// PURPOSE: Resolve the visibility of the question STEP :: 1
export const resolveVisibilities = (questions) => {
  questions?.forEach((question, index) => {
    if (index === 0) {
      // First question is always visible
      question.isVisible = true;
      question?.choices?.forEach((choice) => {
        choice.isVisible = true;
      });
    } else {
      resolveQuestionVisibility(question, questions);
    }
  });
};

// As a question, I am visible if I don't have a dependency or if my dependency is answered.
// PURPOSE: Resolve the visibility of the question which is dependent on the dependency. STEP:: 2
export const resolveQuestionVisibility = (question, questions) => {
  // Currently unclear =>  but i think it is giving actual dependency question
  const dependency = resolveDependency(questions, question?.dependency);
  //getDependencyObject => it is taking dependency as argument and create a object and adding different propery like typeDependency ,type Dependency are ["question","choice","category","categoryChoices"];
  const dependencyObject = getDependencyObject(dependency);

  const isDependencyAnswered = !dependency
    ? true // No dependency - always visible
    : config
        .typeLookup(dependencyObject.questionType) // get all function of particular question
        .isAnswered(dependency); // call isAnswered() function for this particular question

  const isVisible = () => {
    return isDependencyAnswered;
  };

  question.isVisible = isVisible();
  if (question.isVisible && question.choices != null) {
    question.choices.forEach((choice) => {
      resolveChoiceVisibility(choice, questions);
    });
  }
};

const resolveChoiceVisibility = (choice, questions) => {
  //const question = choice.question();
  // const choiceValue = choice.valueFormatted?.(".");
  const dependency = resolveDependency(questions, choice.dependency);
  // utils.log.debug(
  //   `resolveChoiceVisibility(${choiceValue}): Checking if choice(${choiceValue}) dependency is answered...`,
  //   choice
  // );
  const dependencyObject = getDependencyObject(dependency);
  const isDependencyAnswered =
    dependency == null
      ? true // No dependency - always visible
      : config
          .typeLookup(dependencyObject.questionType) // Get isAnswered function for question type
          .isAnswered(dependency); // Check if dependency is answered
  //const isAnswered = dependency == null ? true : isQuestionAnswered; //question.isAnswered(dependency);
  //const isVisible = (dependencyObject?.isVisible ?? true) && isAnswered;
  const isVisible = () => {
    if (dependency == null) return true; // No dependency - always visible

    if (!dependencyObject.isVisible) return false; // Dependency object is not visible - always invisible

    return isDependencyAnswered; // Dependency object is visible - check if answered
  };
  // utils.log.info(
  //   dependency == null
  //     ? `resolveChoiceVisibility(${choiceValue}): isVisible(${isVisible()}) <-- (no dependency) <-- isDependencyAnswered(${isDependencyAnswered})`
  //     : `resolveChoiceVisibility(${choiceValue}): isVisible(${isVisible()}) <-- dependency(${
  //         dependencyObject.code
  //       }).isVisible(${
  //         dependencyObject.isVisible
  //       }) && isDependencyAnswered(${isDependencyAnswered})`
  // );
  choice.isVisible = isVisible();
};

// PURPOSE: take dependency and create dependency Object i.e it add type based on given conditions
// if(question && !choices && !category) => then type should be question
// if(question && !choices && category) => then type should be category
// if(question && choices && !category) => then type should be choices
// if(question && choices && category) => then type should be categoryChoice
// and so on it add other property

const getDependencyObject = (dependency) => {
  if (dependency == null) return null;

  // Here we get on either question ,choice , answer based on type of dependency , type of dependency = ["question","choie","category",categoryChoices];
  const dependencyObject = getDependency(dependency);
  // here we get type of dependency based on wherher the only question is available or question or chocies both avialble and so on ,type of dependency = ["question","choie","category",categoryChoices];
  const dependencyType = getType(dependency);
  /*   const dependencyCode =
    dependencyType === "question"
      ? dependencyObject?.code
      : dependencyObject?.valueFormatted("."); */
  let dependencyCode;

  utils.log.info(":: dependenyyObject", dependencyObject);

  switch (dependencyType) {
    case "question":
      dependencyCode = dependencyObject?.code;
      break;
    case "choice":
      // dependencyCode = dependencyObject?.valueFormatted?.(".");
      dependencyCode = `${dependency?.question.code}.${dependency?.choice.code}`;
      break;
    case "category":
      dependencyCode = `${dependency?.question.code}.${dependency.category}`;
      break;
    case "categoryChoice":
      dependencyCode = `${dependency?.question.code}.${dependency?.choice.code}.${dependency.category}`;
      break;
    default:
      throw new Error(`Invalid dependency type: ${dependencyType}`);
  }

  /*   const questionType =
    dependencyType === "question"
      ? dependencyObject.type
      : dependencyObject.question().type; */
  let questionType;

  switch (dependencyType) {
    case "question":
      questionType = dependencyObject?.type;
      break;
    case "choice":
      questionType = dependency?.question?.type;
      break;
    case "category":
      questionType = dependency?.question.type;
      break;
    case "categoryChoice":
      questionType = dependency?.question.type;
      break;
    default:
      throw new Error(`Invalid dependency type: ${dependencyType}`);
  }

  const result = {
    object: dependencyObject,
    isVisible: dependencyObject?.isVisible ?? true,
    code: dependencyCode ?? null,
    questionType: questionType,
    dependencyType: dependencyType,
  };
  return result;
};

export function hasDependency(dependency) {}

export function isDependencyObject(dependency) {
  // PURPOSE: Check if dependency is a dependency object
  if (dependency == null) return false;
  // Check if dependency has property question
  if (!dependency.hasOwnProperty("question")) return false;
  return true;
}
