/*eslint-disable*/
import "./survey.css";
import { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../components/Button";
import RecipientService from "../../services/recipient";
import Landing from "./landing";
import { Render as RenderSurveyQuestions } from "../survey";
import * as dependencyUtil from "../survey-questions/dependency";
import * as utils from "../../services/utilities";
import { enqueueSnackbar } from "notistack";
import { AuthContext } from "../../services/context";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import headerLogoIcon from "../../assets/GPG_Logo.svg";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#ffffff",
    height: "74px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    width: "100vw",
    borderBottom: "1px solid #d7d7d7",
    zIndex: "9999999",
  },
  headerLogo: {
    margin: "14px",
  },
  headerButton: {
    margin: "17px 14px",
  },
  content: {
    marginTop: "120px",
  },
  container: {
    padding: "75px 0px",
  },
  detailsCard: {
    // height: "125px",
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    border: "1px solid #E9E9E9",
    borderRadius: "8px",
    margin: "20px 0px 40px 0px",
    justifyContent: "center",
    padding: "20px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px 40px 8px",
    },
  },
  detailsCardWrapper: {
    padding: "20px",
  },
  detailsCardContent: {
    display: "flex",
    background: "#FFFFFF",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "4px",
    },
  },
  profileInitials: {
    minHeight: "46px",
    maxWidth: "46px",
    minWidth: "46px",
    maxHeight: "46px",
    borderRadius: "100%",
    background: "#0066F933",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "46px",
    fontWeight: "500",
  },
  profileInfoContainer: {
    display: "flex",
    flexDirection: "column",
    height: "46px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
    },
  },
  profileInfo: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  profileName: {
    fontSize: "16px",
    color: "#0066F9",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  profileEmail: {
    fontSize: "16px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  profileDetails: {
    display: "flex",
    gap: "25px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "4px",
    },
  },
  profileDetail: {
    paddingRight: "25px",
    borderRight: "1px solid #525252",
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  footer: {
    background: "none",
    boxShadow: "none",
    minHeight: 0,
    textAlign: "left",
    padding: 0,
  },
  footerContent: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  footerRow: {
    display: "flex",
    gap: "20px",
  },
  footerCheckboxText: {
    color: "#070707B2",
  },
}));

export default function Survey() {
  // utils.log.component("Survey");
  // #region Initialize
  const classes = useStyles();
  const [, setSearchParams] = useSearchParams();
  const { surveyCode } = useParams();
  const [survey, setSurvey] = useState(null);
  const [showSurveyQuestions, setShowSurveyQuestion] = useState(false);
  // #endregion
  // #region Events

  const handleSurveyStart = (surveyCode, uid, accessCode) => {
    // setSearchParams({ code: surveyCode, uid: uid, accessCode: accessCode });
    setSearchParams({ uid: uid, accessCode: accessCode });
    setShowSurveyQuestion(true);
  };

  const handleExitSurvey = () => {
    setShowSurveyQuestion(false);
  };
  // #endregion

  return (
    <>
      <div className={classes.header}>
        <Link href="/?view=coordinators">
          <img
            src={headerLogoIcon}
            height="44"
            width="auto"
            className={classes.headerLogo}
            alt="GoPassGo Networks, LLC"
          />
        </Link>

        {showSurveyQuestions ? (
          <Button className={classes.headerButton} onClick={handleExitSurvey}>
            Exit Survey
          </Button>
        ) : null}
      </div>

      <div className={classes.content}>
        {showSurveyQuestions ? (
          <Render
            survey={survey}
            setSurvey={setSurvey}
            surveyCode={surveyCode}
          />
        ) : (
          <Landing
            handleSurveyStart={handleSurveyStart}
            survey={survey}
            setSurvey={setSurvey}
            surveyCode={surveyCode}
          />
        )}
      </div>
    </>
  );
}

const Render = ({ survey, surveyCode }) => {
  utils.assert(survey != null, "Survey required.  Unable to render survey.");
  const [questions, setQuestions] = useState(survey.questions);
  const [recipientInfo, setRecipientInfo] = useState(null);
  const classes = useStyles();

  // utils.log.info(":: CCQ-0-recipient-survey",questions);
  const formattedQuestions = useMemo(() => {
    if (questions?.length > 0) {
      const temp = [...questions];
      dependencyUtil.resolveVisibilities(temp);
      return temp.filter((q) => q.isVisible);
    }
    return [];
  }, [questions]);

  const [uid] = useState(utils.getQuerystring("uid"));
  // const [surveyCode] = useState(utils.getQuerystring("code"));
  const { recipientService } = useContext(AuthContext);
  const getRecipientDetails = async () => {
    try {
      const response = await recipientService.getByUid(uid, surveyCode);
      const data = await response.json();
      setRecipientInfo(data);
    } catch (error) {
      // utils.log.error("getRecipientDetails()", error);
    }
  };

  useEffect(() => {
    getRecipientDetails();
  }, []);

  const handleSubmit = () => {
    const uid = utils.getQuerystring("uid");
    const accessCode = utils.getQuerystring("accessCode");

    if (!uid || !accessCode || !survey.code) {
      return enqueueSnackbar("Please enter a valid UID and Access Code.", {
        variant: "error",
      });
    }

    const requestBody = formattedQuestions.map((q) => {
      return {
        type: q.type,
        code: q.code,
        ...(q.getValue(q) || {}),
      };
    });

    const recipientService = new RecipientService();
    recipientService
      .submitSurvey({
        surveyCode: survey.code,
        uid,
        accessCode,
        body: requestBody,
      })
      .then((result) => {
        if (!result.ok)
          result.json().then((error) => {
            enqueueSnackbar(error.title, { variant: "error" });
          });
        else {
          enqueueSnackbar("Success!", { variant: "success" });
        }
      });
  };
  return (
    <Container maxWidth="md">
      <div className={classes.container}>
        <div style={{ marginBottom: "40px" }}>
          <h4>You’re filling this survey as:</h4>
          <div className={classes.detailsCard}>
            <div className={classes.detailsCardWrapper}>
              <div className={classes.detailsCardContent}>
                <div className={classes.profileInitials}>
                  {recipientInfo?.firstName?.[0]?.toUpperCase() +
                    recipientInfo?.lastName?.[0]?.toUpperCase() || ""}
                </div>

                <div className={classes.profileInfoContainer}>
                  <div className={classes.profileInfo}>
                    <span className={classes.profileName}>
                      {recipientInfo?.fullName || ""}
                    </span>{" "}
                    {recipientInfo?.email || ""}
                  </div>
                  <div className={classes.profileDetails}>
                    <div className={classes.profileDetail}>
                      Worksite:
                      <span style={{ color: "#070707" }}>
                        {recipientInfo?.worksite || ""}
                      </span>
                    </div>
                    <div>
                      Department:
                      <span style={{ color: "#070707" }}>
                        {recipientInfo?.department || ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4>Metrics Testing Survey</h4>
        </div>

        <RenderSurveyQuestions
          survey={survey}
          questions={formattedQuestions}
          setQuestions={setQuestions}
          preview={false} // In case of recipient survey => we are sending preview = false
        />

        <Footer questions={formattedQuestions} handleSubmit={handleSubmit} />
      </div>
    </Container>
  );
};

const Footer = ({ questions, handleSubmit }) => {
  const hasAllVisibleQuestionsCompleted = useMemo(() => {
    return questions.every((question) => {
      return question.isCompleted?.(question);
    });
  }, [questions]);
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        <div className={classes.footerRow}>
          <input
            type="checkbox"
            name={check}
            onChange={() => setCheck((prev) => !prev)}
            value={check}
          />
          <div className={classes.footerCheckboxText}>
            Information provided on Survey is for general informational purposes
            only and not a substitute for professional advice or services.
          </div>
        </div>
        <div className={classes.footerRow}>
          <Button
            disabled={!hasAllVisibleQuestionsCompleted || !check}
            onClick={handleSubmit}
            size="large"
          >
            Submit
          </Button>
          <Button size="large">Reset</Button>
        </div>
      </div>
    </footer>
  );
};
