import { Typography } from "antd/lib";
import styles from "./style.module.css";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CustomWidthTooltip } from "../Tooltip";
import { useState } from "react";

const DashboardLayout = ({
  title,
  description,
  actionButtons = null,
  code = null, // for Applying help tooltip during onboarding
  children,
}) => {
  /*eslint-disable-next-line*/
  const [isOnboarding,setIsOnboarding] = useState(true);
  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.headerInfo}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap:"20px",
              alignItems: "center",
            }}
          >
            {title && (
              <Typography className={styles.title} variant="h4" >
                {title}
              </Typography>
            )}
            <div>
            { isOnboarding && code ? (
              <CustomWidthTooltip style={{cursor:"pointer"}} code={code} placement={"right-start"} >
                <HelpOutlineIcon color="primary" fontSize="medium" />
              </CustomWidthTooltip>
            ) : code ? (
              <HelpOutlineIcon style={{cursor:"pointer"}} color="primary" fontSize="medium" />
            ) : null}
            </div>
          </div>
          {description ? (
            <Typography className={styles.description} >
              {description}
            </Typography>
          ) : null}
        </div>

        <div>
          {actionButtons && (
            <div className={styles.button}>{actionButtons}</div>
          )}
        </div>
      </div>

      {children}
    </>
  );
};

export default DashboardLayout;
