import React from "react";
import styles from "./style.module.css";

const PricingShimmer = ({ preview }) => {
  return (
    <div  style={{ padding: 0 }}>
      <div className={`${styles.shimmerWrapper} ${preview ? styles.shimmerWrapperPreview : styles.shimmerWrapperDefault}`}>
        <section>
          {[1, 2, 3].map((_, index) => (
            <div key={index} className={styles.shimmerCard}>
              <div className={styles.shimmerText}></div>
              <div className={styles.shimmerText}></div>
              <div className={styles.shimmerPrice}></div>
              <div className={styles.shimmerButton}></div>
              <div className={styles.shimmerService}></div>
              <div className={styles.shimmerService}></div>
              <div className={styles.shimmerService}></div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default PricingShimmer;
