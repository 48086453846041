import { useTour } from "@reactour/tour";
import React from "react";
import Button from "../Button";

const OnboardingTourStep = () => {
  const { setIsOpen, setCurrentStep } = useTour();
  return (
    <>
      <div style={{fontSize:"25px",marginBottom:"10px"}}>Would you like to Continue?</div>
      <div style={{ display: "flex", gap: "30px" }}>
        <Button
          variant="contained-primary"
          style={{ padding: "10px 40px", borderRadius: "50px" }}
          onClick={() => setCurrentStep((prev) => prev + 1)}
        >
          Yes
        </Button>
        <Button
          variant="contained-white"
          style={{ padding: "10px 40px", borderRadius: "50px" }}
          onClick={() => setIsOpen(false)}
        >
          No
        </Button>
      </div>
    </>
  );
};

export default OnboardingTourStep;
