import styled from "styled-components";
import * as config from "../../services/config";

export const StyledTable = styled.table`
  user-select: none;
  width: 100%;
  border-collapse: collapse;

  & td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  & tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  & tr:nth-child(odd) {
    background-color: #ffffff;
  }

  & tr:hover {
    background-color: #ddd;
  }

  & th {
    padding: 12px 8px 12px 8px;
    text-align: left;
    background-color: blue;
    color: white;
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TableWrapper = styled.div`
  overflow-x: scroll;

  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-track {
    background: transparent;
  }

  & ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & :hover::-webkit-scrollbar-thumb {
    background: #cfcfcf;
  }
  & :hover::-webkit-scrollbar-track {
    background: #e8e8e8;
  }
  border-radius: 0px 0px 8px 8px;
`;

export const TableBody = styled.tbody`
  overflow-x: scroll;
`;

export const TableRow = styled.tr`
  // background: ${(props) =>
    props?.selected
      ? ` ${config.defaults.TABLE_SELECTED_BACKGROUND_COLOR} !important`
      : ""};
`;

export const TableLoadingCell = styled.td`
  text-align: center;
`;
export const ActionCell = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ThIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
`;
export const ThWrapper = styled.div`
  width: 100%;
  display: flex;
  max-height: 35px;
`;
export const ThContentWrapper = styled.div`
  &:hover > ${ThIcon} {
    width: auto;
    visibility: visible;
  }
  flex: ${(props) => (props.align === "center" ? 1 : "")};
  display: flex;
  padding: 0 6px;
  overflow: hidden;
  min-width: 0;
  align-items: center;
  white-space: nowrap;
`;

export const ThTitle = styled.div`
  overflow: hidden;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

export const TableHeaderCaption = styled.div`
  font-size: 1rem;
  font-weight: bold;
  padding-left: 16px;
  margin-bottom: 16px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 1.5em;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  border: 1px solid #e9e9e9;
  border-radius: 8px 8px 0px 0px;
  background: #ffffff;
  font-family: Public-sans;
`;

export const TableHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
`;

export const TableContainer = styled.div`
  cursor: pointer;
`;

export const FilterInput = styled.input`
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  padding: 8px 8px 8px 35px;
  border-radius: 8px;
  width: 300px;
  font-family: Public-sans;

  @media (max-width: 767px) {
    width: 170px;
  }
`;

export const FilterInputContainer = styled.div`
  position: relative;
  display: inline-block;

  .search-icon {
    width: 19px;
    height: 19px;
    position: absolute;
    left: 8px;
    top: 10px;
    pointer-events: none;
  }
`;

export const Select = styled.select`
  border: none;
  appearance: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  font-family: Public-sans;
`;

export const IconButtons = styled.button`
  height: 9px;
  width: 20px;
  padding: 0;
  margin: 0;
  font-size: 10px;
  line-height: 1;
  border: none;
  background: #f8f8f8;
  color: ${(props) => (props.asc ? "black" : "#E9E9E9")};
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
`;
export const IconButtonDown = styled.button`
  height: 9px;
  width: 20px;
  padding: 0;
  margin: 0;
  font-size: 10px;
  line-height: 1;
  border: none;
  background: #f8f8f8;
  color: ${(props) => (props.desc ? "black" : "#E9E9E9")};
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
`;

export const ArrowIcon = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin: 0px 10px;
`;

export const PageDetails = styled.p`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: Public-sans;
`;
