/*eslint-disable*/
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import "../../App.css";
import { useContext, useEffect, useMemo, useState } from "react";
import { Typography } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";

import Button from "../../components/Button";
import Popup from "../../components/Form/Popup";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import Table from "../../components/Table";

import { AuthContext } from "../../services/context";
import * as utils from "../../services/utilities";
import Exemptions from "../recipients/management/exemptions";

import { SurveySelector } from "../survey";
import { CoordinatorContext } from "../../components/contexts/CoordinatorContext";
import { useSearchParams } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import { RecipientDetails } from "../recipients/management/summary";
import Tabs, { Tab } from "../../components/Tabs";
import MUIDialog from "../../components/Modal";
import { AppContext } from "../../App";

export const Progress = (props) => {
  // #region Assertions
  // #endregion
  // #region Functions
  const getDepartmentsByEmployee = async (coordinator, surveyCode) => {
    try {
      const departmentsAll = await employeeService.getDepartments(
        coordinator,
        surveyCode
      );
      if (setAllDepartments) {
        setAllDepartments(departmentsAll);
      }
      const assignedDepartments = departmentsAll?.filter(
        (department) => department?.isAssigned
      );
      if (setEmployeeDepartments) {
        setEmployeeDepartments(assignedDepartments);
      }
      setisLoading(false);
      const filteredDepartments = departmentsAll.filter(
        (department) => department.coordinatorUid === coordinator.uid
      );

      // Extract worksites from departments
      const worksites = filteredDepartments.reduce(
        (uniqueWorksites, department) => {
          const worksiteCode = department.worksiteCode;

          // Check if worksiteCode already exists in uniqueWorksites
          if (!uniqueWorksites.some((w) => w.worksiteCode === worksiteCode)) {
            // Add worksite only if it's unique
            uniqueWorksites.push({
              worksiteCode,
              worksite: department.worksite,
            }); // Include both code and worksite information
          }

          return uniqueWorksites;
        },
        []
      );

      setDepartments(filteredDepartments);
      setWorksites(worksites); // Add a new line to set the worksites state
    } catch (error) {
      // utils.log.error("Error in fetching departments data:", error);
      setisLoading(false);
    }
  };
  // #endregion
  // #region Initialize
  const {
    coordinator,
    allDepartments,
    setAllDepartments,
    handleSelectedRows,
    setEmployeeDepartments,
    selectedRowsIndex,
  } = props;
  const { employeeService, employee } = useContext(AuthContext);
  const selectedCoordinator = coordinator ?? employee;
  const { sideBarData, setSideBarData } = useContext(AppContext);

  const [surveyCode, setSurveyCode] = useState(
    sideBarData?.progressSelectedSurvey ?? ""
  );
  const [worksites, setWorksites] = useState([]);
  // this one

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [isOpenRecipients, setIsOpenRecipients] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  const columns = useMemo(
    () => [
      { Header: "Department", accessor: "code" },
      { Header: "Coordinator", accessor: "coordinator" },
      { Header: "Worksite", accessor: "worksite" },
      { Header: "Contacted", accessor: "contactedCount", align: "center" },
      { Header: "Started", accessor: "startedCount", align: "center" },
      { Header: "Completed", accessor: "completedCount", align: "center" },
      { Header: "Exempted", accessor: "exemptedCount", align: "center" },
    ],
    []
  );
  // #endregion
  // #region Events

  // utils.log.info(":: sideBarData ===>", sideBarData);
  useEffect(() => {
    // utils.log.info(":: Survey Code Change", surveyCode);

    setSideBarData({ ...sideBarData, progressSelectedSurvey: surveyCode });
    if (surveyCode && selectedCoordinator) {
      getDepartmentsByEmployee(selectedCoordinator, surveyCode);
    }
  }, [surveyCode, selectedCoordinator]);

  const handleRowSelect = (row) => {
    // utils.log.event("Departments.handleRowSelect()", row);
    setSelectedDepartment(row);
    setIsOpenRecipients(true);
    // utils.log.stateChange(
    //   `setSelectedDepartmentCode(row.code: ${row.code})`,
    //   row
    // );
  };

  const handleCloseRecipients = () => {
    setIsOpenRecipients(false);
  };
  // #endregion

  // utils.log.debug("Departments", {
  //   worksites,
  //   //selectedWorksiteCode,
  //   departments,
  //   selectedDepartmentCode: selectedDepartment,
  // });

  // utils.log.debug("surveyCode", surveyCode);

  return (
    <>
      <div
        style={{ margin: "20px 0px" }}
        className={props ? "" : "onboarding-22"}
      >
        <SurveySelector
          filter={"All"}
          selected={surveyCode}
          setSelected={setSurveyCode}
          firstItemSelected={surveyCode ? false : true}
          label="Select a survey"
        ></SurveySelector>
      </div>
      <DashboardLayout
        title={coordinator ? "" : "Progress"}
        description={coordinator ? "" : "A summary of survey progress."}
        code={Object.keys(props)?.length > 0 ? "" : "progress-section"}
      >
        {surveyCode && (
          <Table
            columns={columns}
            data={coordinator ? allDepartments : departments}
            selected
            onSelected={(row) => handleRowSelect(row.original)}
            isLoading={isLoading}
            selectedRows={coordinator ? selectedRowsIndex : {}}
            getSelectedRows={
              coordinator ? (rows) => handleSelectedRows(rows) : null
            }
            multiSelect={coordinator ? true : false}
            filterTourClassName={"onboarding-23"}
            paginationTourClassName={"onboarding-24"}
          ></Table>
        )}
        <MUIDialog
          open={isOpenRecipients}
          onClose={handleCloseRecipients}
          fullWidth
          maxWidth={"lg"}
          // PaperProps={{ style: { height: "600px" } }}
          height={"600px"}
          title="Recipients"
          description="These department recipients have been selected for the survey and
        require followup."
        >
          <RecipientsAssigned
            department={selectedDepartment}
            surveyCode={surveyCode}
            onClose={handleCloseRecipients}
          />
        </MUIDialog>
      </DashboardLayout>
    </>
  );
};

export const Details = ({
  detail,
  setDetail,
  open,
  onClose,
  getAllDepartments,
}) => {
  // utils.log.component("Department.Details", detail);
  // #region Assertions
  utils.assert(detail != null, "Detail is required");
  // #endregion
  // #region Initialize
  const confirm = useConfirm();
  const { departmentService } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [surveyCode, setSurveyCode] = useState("");

  /*   const handleUpdate =async () => {
    departmentService.update(detail).then((result) => {
      if (result.ok) {
        getAllDepartments();
        onClose();
      } else {
        result.json().then((data) => {
          setError(data.detail);
        });
      }
    });
  }; */
  const handleUpdate = async () => {
    try {
      setError(null);
      await departmentService.update(detail);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = () => {
    // utils.log.event(
    //   `Worksite.Departments handleDelete(${detail.code})`,
    //   detail
    // );
    confirm({
      description: `Confirm deletion of department ${detail.code}.`,
    }).then(() => {
      departmentService
        .remove(detail) // BUG: 092023 Doesn't appear to work
        .then(async (result) => {
          //const updatedSurveys = await updateList(); // Ensure Table component below has a conditional on list.
          //setList(updatedSurveys); // Refresh list.
          //// utils.log.stateChange("setList(detail)", updatedSurveys);
          onClose();
        })
        .catch((error) => {
          // utils.log.error(error);
        });
    });
    // .catch(() => // utils.log.warn("Department deletion cancelled."));
  };

  const handleChange = (e) => {
    setDetail((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // #endregion
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Update Department</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A department represents a grouping of employees. Select a survey to
          view department recipients.
        </DialogContentText>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Input
              label="Code"
              fullWidth
              name="code"
              disabled
              /*              onChange={(event) => formUtility.handleChange(event, setInputs)}
                value={formUtility.getValue("code")} */
              // onChange={(e) => cache.set(e)}
              // defaultValue={() => cache.get("code")}
              defaultValue={detail?.code}
              onChange={handleChange}
            ></Input>
          </Grid>
          <Grid item xs={5}>
            <Input
              label="Name"
              fullWidth
              name="name"
              required
              defaultValue={detail?.name}
              onChange={handleChange}
            ></Input>
          </Grid>
          <Grid item xs={5}>
            <SurveySelector
              filter="enabled"
              selected={surveyCode}
              setSelected={setSurveyCode}
            ></SurveySelector>
          </Grid>
          <Grid item xs={12}>
            {surveyCode === "" ? (
              <Typography color="primary" style={{ fontFamily: "Public-sans" }}>
                Select a survey to see recipients.
              </Typography>
            ) : (
              <RecipientsAssigned department={detail} surveyCode={surveyCode} />
            )}
          </Grid>
          {error && (
            <Grid item xs={12}>
              {error}
            </Grid>
          )}
        </Grid>
        <DialogActions>
          <Button onClick={handleDelete}>Delete</Button>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

// const Add = ({ open, onClose, getAllDepartments }) => {
//   const { worksiteService } = useContext(AuthContext);
//   const [worksites, setWorksites] = useState(null);
//   const [worksite, setWorksite] = useState("");
//   const { departmentService, cache } = useContext(AuthContext);
//   const [error, setError] = useState(null);
//   const [worksiteError, setworksiteError] = useState(false);

//   const handleChange = (e) => {
//     setWorksite(e.target.value);
//     if (e.target.value !== "") {
//       setworksiteError(false);
//     }
//   };

//   const handleSubmit = async () => {
//     if (!worksite) {
//       setworksiteError(true);
//       return;
//     }

//     try {
//       await departmentService.add(worksite, cache.getDetails());
//       getAllDepartments();
//       onClose();
//     } catch (error) {
//       setError(error?.detail);
//       // utils.log.error("Error in fetching worksites data:", error);
//     }
//   };

//   const getAllWorksites = async () => {
//     try {
//       const response = await worksiteService.getAll();
//       // const data = await response.json();
//       setWorksites(response);
//     } catch (error) {
//       // utils.log.info("Error in fetching worksites data:", error);
//     }
//   };

//   useEffect(() => {
//     getAllWorksites();
//     // utils.log.event("Initialize Add Department");
//     cache.clear();
//   }, []);

//   //const [inputs, setInputs] = useState({});
//   /*   formUtility.setDetail(null);
//     formUtility.setInputs(inputs); */
//   // #endregion
//   // #region Events

//   // #endregion
//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>Add Department</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           A department represents a grouping of employees. All employees must
//           belong to one department for survey purposes.
//         </DialogContentText>

//         <Grid container spacing={2}>
//           <Grid item xs={2}>
//             <Input
//               label="Code"
//               fullWidth
//               name="code"
//               /*               onChange={(event) => formUtility.handleChange(event, setInputs)}
//                 value={formUtility.getValue("code")} */
//               onChange={(e) => cache.set(e)}
//               defaultValue={() => cache.get("code")}
//             ></Input>
//           </Grid>
//           <Grid item xs={4}>
//             <Input
//               label="Name"
//               fullWidth
//               name="name"
//               /*               onChange={(event) => formUtility.handleChange(event, setInputs)}
//                 value={formUtility.getValue("name")} */
//               onChange={(e) => cache.set(e)}
//               defaultValue={() => cache.get("name")}
//             ></Input>
//           </Grid>
//           <Grid item xs={6}>
//             <Select
//               // style={worksiteError ? { border: "1px solid red" } : {}}
//               fullWidth
//               value={worksite}
//               onChange={handleChange}
//               displayEmpty
//               required
//             >
//               <MenuItem value="" disabled>
//                 Select a Worksite
//               </MenuItem>
//               {worksites?.map((worksite) => (
//                 <MenuItem key={worksite?.id} value={worksite?.code}>
//                   {worksite?.name}
//                 </MenuItem>
//               ))}
//             </Select>
//             {worksiteError && (
//               <FormHelperText style={{ color: "red" }}>
//                 Worksite is required
//               </FormHelperText>
//             )}
//           </Grid>

//           {error && (
//             <Grid item xs={12}>
//               {error}
//             </Grid>
//           )}
//         </Grid>
//         <DialogActions>
//           <Button onClick={onClose}>Close</Button>
//           <Button onClick={handleSubmit}>Add</Button>
//         </DialogActions>
//       </DialogContent>
//     </Dialog>
//   );
// };

const RecipientsAssigned = ({ department, surveyCode, onClose }) => {
  // utils.log.component(
  //   `Department.Recipients(dept: ${department}, survey: ${surveyCode})`
  // );

  // #region Assertions
  utils.assert(department != null, "Department is required");
  utils.assert(surveyCode != null, "surveyCode is required");
  // #endregion

  // #region Initialize
  const { surveyService, employeeService, employee } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [survey, setSurvey] = useState(null);
  const [openOverview, setOpenOverview] = useState(false);
  const [openSurveyReset, setSurveyReset] = useState(false);
  const [error, setError] = useState("");
  const [, setSearchParams] = useSearchParams();
  const [recipient, setRecipient] = useState(null);

  const columns = [
    { Header: "UID", accessor: "uid" },

    { Header: "Employee", accessor: "fullName" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Email", accessor: "email" },
    /* { Header: "Occupation", accessor: "occupation" }, */
    {
      Header: "Status",
      Cell: ({ cell: value }) => {
        const exemptionReason = value.row.original.exemptionReason;
        const surveyCompleted = value.row.original.surveyCompleted;
        if (surveyCompleted) return "COMPLETED";
        return exemptionReason ?? "OPEN";
      },
    },
  ];

  // #region Functions
  const updateList = async () => {
    try {
      const recipients =
        await employeeService.getAssignedRecipientsByDepartment(
          employee.uid,
          surveyCode,
          department.code
        );

      setList(recipients);
    } catch (error) {
      // Handle errors here
      // utils.log.error("Error updating list:", error);
    }
  };
  // #endregion

  // #region Events
  useEffect(() => {
    // utils.log.useEffect(
    //   `Department.Recipients - onChange(surveyCode: ${surveyCode}, openOverview: ${openOverview})`
    // );
    if (surveyCode) {
      updateList();
    }
  }, [openOverview, surveyCode]);

  const handleClose = () => {
    setOpenOverview(false);
    setSurveyReset(false);
    setList([]);
    updateList();
  };
  useEffect(() => {
    async function fetchSurvey() {
      try {
        const surveyData = await surveyService.getByCode(surveyCode);
        setSurvey(surveyData);
      } catch (error) {
        // Handle errors here, e.g., show an error message
        // utils.log.error("Error fetching survey:", error);
      }
    }
    fetchSurvey();
  }, [surveyCode]);

  const handleEdit = (row) => {
    setRecipient(row);
    if (!row.surveyCompleted) {
      // Has not completed survey
      setOpenOverview(true);
      // utils.log.stateChange("setOpenOverview(true)");
    } else {
      setSurveyReset(true);
      // utils.log.stateChange("setSurveyReset(true)");
    }
  };
  // #endregion

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} md={4}>
          Worksite: {department.worksite}
        </Grid>
        <Grid item xs={12} md={4}>
          Department: {`${department.name}(${department.code})`}
        </Grid>
        <Grid item xs={12} md={4}>
          Coordinator: {department.coordinator}
        </Grid>
        <Grid item container justifyContent={"flex-end"}>
          <Button
            onClick={() => {
              setSearchParams({
                view: "surveys",
                surveyCode: surveyCode,
                activeTab: "recipients_upload",
                worksiteCode: department.worksiteCode,
                departmentCode: department.code,
              });
              onClose?.();
            }}
          >
            Upload recipients
          </Button>
        </Grid>
      </Grid>

      <Table
        columns={columns}
        data={list}
        selected
        // caption="Recipients"
        onSelected={(row) => handleEdit(row.original)}
        // sx={{ minWidth: 1500 }}
      ></Table>
      {openOverview && (
        <Overview
          error={error}
          setError={setError}
          openOverview={openOverview}
          onClose={handleClose}
          department={department}
          survey={survey}
          recipient={recipient}
        />
      )}
      {openSurveyReset && (
        <ResetDialog
          open={openSurveyReset}
          onClose={handleClose}
          error={error}
          setError={setError}
          uid={recipient?.uid}
          surveyCode={surveyCode}
        ></ResetDialog>
      )}
    </>
  );
};

const Overview = ({
  error,
  setError,
  openOverview,
  onClose,
  department,
  survey,
  recipient,
}) => {
  return (
    <div style={{ margin: "40px" }}>
      <MUIDialog
        open={openOverview}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        // PaperProps={{ style: { height: "600px" } }}
        height={"600px"}
        title={`Department(${department.code}): ${department.name}`}
      >
        Coordinator :{department.coordinator ?? "- Unassigned -"}
        <Tabs>
          <Tab
            label="Details"
            component={
              <RecipientDetails
                surveyCode={survey?.code}
                recipient={recipient}
              />
            }
          />
          <Tab
            label="Exemptions"
            component={
              <ExemptionSelector
                error={error}
                setError={setError}
                survey={survey}
                uid={recipient?.uid}
              />
            }
          />
        </Tabs>
      </MUIDialog>
    </div>
  );
};

const ResetDialog = ({ open, onClose, error, setError, uid, surveyCode }) => {
  // utils.log.component(`ResetDialog(uid: ${uid}, surveyCode: ${surveyCode})`);
  // #region Assertions
  // #endregion
  // #region Functions
  const surveyReset = () => {
    recipientService.resetSurvey(uid, surveyCode).then((result) => {
      if (result.ok) onClose();
    });
  };
  // #endregion
  // #region Initialize
  const { recipientService } = useContext(AuthContext);
  const [options] = useState({
    open: open,
    onClose: onClose,
    error: error,
    setError: setError,
    dialogTitle: "Reset Survey Recipient",
    dialogContentText: "This recipient has already completed their survey.",
    dialogActions: <Button onClick={surveyReset}>Reset</Button>,
    gridItems: <Grid item xs={12}></Grid>,
  });
  // #endregion
  // #region Events

  // #endregion
  return <Popup options={options}></Popup>;
};

const ExemptionSelector = ({ error, setError, survey, uid }) => {
  // utils.log.component(
  //   `ExemptionSelector(survey.code: ${survey.code}, uid: ${uid})`,
  //   survey
  // );
  // #region Assertions
  utils.assert(survey != null, "Survey is required");
  utils.assert(uid != null, "UID is required");
  utils.assert(error != null, "Error is required");
  utils.assert(setError != null, "SetError is required");

  const [exemptionsOpen, setExemptionsOpen] = useState(false);
  const { recipientService, exemptionService } = useContext(AuthContext);
  const [exemptions, setExemptions] = useState([]); // List of exemption reasons
  const [exemptReason, setExemptReason] = useState(""); // Recipient's selected exemption reason

  const updateList = async () => {
    // utils.log.info("ExemptionSelector.updateList()");
    const result = await exemptionService.getAll();

    const list = utils.toSelectItem(
      result,
      "@['code']: @['reason']",
      "code",
      true
    );
    setExemptions(list);
    // utils.log.stateChange(`setExemptions(length: ${list.length})`, list);
  };

  useEffect(() => {
    // utils.log.useEffect("ExemptionSelector.Initialize");
    updateList();
  }, []);

  const handleExemptReason = async (event) => {
    // utils.log.info("ExemptionSelector.handleExemptReason()");

    try {
      await exemptionService.setRecipientExemptionStatus(
        uid,
        survey?.code,
        exemptReason
      );
    } catch (error) {
      // utils.log.error("Error handling exemption reason:", error);
    }
  };
  const handleManualComplete = async (event) => {
    // utils.log.info("ExemptionSelector.handleManualComplete()", event);

    try {
      await recipientService.setSurveyManualComplete(uid, survey?.code);
    } catch (error) {
      // utils.log.error("Error handling manual complete:", error);
    }
  };

  const handleExemptionsClose = () => {
    setExemptionsOpen(false);
    updateList();
    setExemptReason(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Exemption Selector
        </Grid>
        <Grid item xs={12}>
          An exemption allows a recipient to skip the survey.
        </Grid>

        <Grid item xs={12}>
          <GridComponent
            exemptions={exemptions}
            survey={survey}
            exemptReason={exemptReason}
            setSelected={setExemptReason}
          />
          {error && <div>{error}</div>}
        </Grid>

        <Grid item container xs={12} justifyContent={"flex-end"} spacing={2}>
          <Grid item>
            <Button onClick={() => setExemptionsOpen(true)}>Exemptions</Button>
          </Grid>
          <Grid item>
            <Button onClick={handleManualComplete}>Manual Complete</Button>
          </Grid>
          <Grid item>
            <Button onClick={handleExemptReason}>Set</Button>
          </Grid>
        </Grid>
      </Grid>

      <MUIDialog
        open={exemptionsOpen}
        onClose={handleExemptionsClose}
        title="Exemptions Maintenance"
        actions={<Button onClick={() => handleExemptionsClose()}>Close</Button>}
      >
        <Exemptions></Exemptions>
      </MUIDialog>
    </>
  );
};

export const GridComponent = ({
  exemptions,
  survey,
  exemptReason,
  setSelected,
}) => {
  // utils.log.component("GridComponent", {
  //   exemptions,
  //   survey,
  //   exemptReason,
  //   setSelected,
  // });
  // #region Assertions
  /*   utils.assert(
      exemptions != null,
      "Exemptions are required.  These are defined by the user on a per survey basis under Survey.Recipients.Exemptions."
    ); */
  //utils.assert(setSelected != null, "setSelected is required");
  // #endregion
  // #region Initialize
  const isDisabled = exemptions == null || exemptions.length === 0;

  /*   const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#ebdf9d",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      },
    })); */
  // #endregion
  // #region Events
  const handleChange = (event) => {
    // utils.log.event(`GridComponent.handleChange(${event.target.value})`, event);
    setSelected(event.target.value);
    // utils.log.stateChange(`setSelected(${event.target.value})`, event);
  };

  // #endregion
  // utils.log.debug("GridComponent *************************************", {
  //   exemptions,
  //   survey,
  //   exemptReason,
  //   setSelected,
  // });
  return (
    <>
      {/*       <HtmlTooltip
          title={
            exemptions == null
              ? "NOTE: You need to define exemption reasons for this survey. Click the Exemptions button to manage your exemptions."
              : ""
          }
        > */}
      <Grid item xs>
        {
          // TODO:Set default value if existing
        }
        <FormSelect
          label={
            isDisabled
              ? "No exemption reasons defined"
              : "Select reason for exemption"
          }
          disabled={isDisabled}
          data={exemptions ?? []}
          fullWidth
          onChange={(e) => handleChange(e)}
          defaultValue={exemptReason ?? ""}
          value={exemptReason ?? ""}
        ></FormSelect>
      </Grid>

      {/*       </HtmlTooltip> */}
    </>
  );
};

export const DepartmentSelector = ({ columns, worksite, coordinator }) => {
  const { departmentService, employeeService } = useContext(AuthContext);
  const {
    setSelectedEmployeeUid,
    setDepartmentAssignToEmployee,
    setDepartmentUnAssignToEmployee,
  } = useContext(CoordinatorContext);

  const [openUpdate, setUpdateOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [employeeDepartments, setEmployeeDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedRowsIndex, setSelectedRowsIndex] = useState({});
  const [isLoading, setisLoading] = useState(true);

  // #endregion
  // #region Functions
  const getAllDepartments = async () => {
    try {
      const response = await departmentService.getAllDepartments();
      if (response.status === 200) {
        const data = await response.json();
        // utils.log.info("ALL DEPARTMENTS DATA", data);
      }
      setisLoading(false);
    } catch (error) {
      // utils.log.error(error);
      setisLoading(false);
    }
  };
  const getCurrentCoordinatorDepartments = async () => {
    try {
      const response = await employeeService.getEmployeeDepartments(
        coordinator?.uid
      );
      if (response?.status === 200) {
        const employeeDepartments = await response.json();

        const assignedDepartments = employeeDepartments?.filter(
          (department) => department?.isAssigned
        );
        setEmployeeDepartments(assignedDepartments);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      // utils.log.error(error);
    }
  };

  const handleRowClick = (update) => {
    setDetail(update);
    setUpdateOpen(true);
  };
  const handleClose = () => {
    setUpdateOpen(false);
  };

  const filterDepartment = useMemo(() => {
    if (worksite) {
      return employeeDepartments.filter(
        (department) => department.code === worksite
      );
    } else {
      return employeeDepartments;
    }
  }, [worksite, employeeDepartments]);
  // #endregion

  // #region Events
  useEffect(() => {
    setSelectedEmployeeUid(coordinator?.uid);
  }, []);

  useEffect(() => {
    getCurrentCoordinatorDepartments();
  }, [coordinator?.uid]);
  useEffect(() => {
    const updatedIndex = {};

    employeeDepartments?.forEach((department, index) => {
      if (department?.isAssigned) {
        updatedIndex[index] = true;
      }
    });

    setSelectedRowsIndex(updatedIndex);
  }, [employeeDepartments]);
  useEffect(() => {
    if (!selectedDepartments || !employeeDepartments) return;

    const selectedDepartmentCodes = selectedDepartments.map(
      (department) => department.code
    );

    const departmentsToAssign = selectedDepartments
      .filter((department) => !department.isAssigned)
      .map((department) => department.code);

    const departmentsToUnAssign = employeeDepartments
      .filter(
        (dept) =>
          dept.isAssigned && !selectedDepartmentCodes.includes(dept.code)
      )
      .map((dept) => dept.code);

    setDepartmentAssignToEmployee(departmentsToAssign);
    setDepartmentUnAssignToEmployee(departmentsToUnAssign);
  }, [selectedDepartments, employeeDepartments]);
  // #endregion

  return (
    <>
      <Table
        columns={columns}
        data={filterDepartment}
        selected
        multiSelect
        onSelected={(row) => handleRowClick(row.original)}
        getSelectedRows={(rows) => setSelectedDepartments(rows)}
        selectedRows={selectedRowsIndex}
        isLoading={isLoading}
      />

      {openUpdate && (
        <Details
          detail={detail}
          setDetail={setDetail}
          open={openUpdate}
          onClose={handleClose}
          getAllDepartments={getAllDepartments}
        ></Details>
      )}
    </>
  );
};
export default Progress;
