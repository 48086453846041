// TODO: 051323 - Deprecate FormUtility in favor of cache.js
// TODO: 051423 - Review if we need to add time zone info for recipient and organization to calculate correct dates.
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormSelect from "../../components/Select";
import Input from "../../components/Input/inputs";
import { Typography, Grid } from "@mui/material";
import * as utils from "../../services/utilities";
import {
  updateQuestionAnswer,
  initValueRangeObject,
} from "../../services/question";
import * as config from "../../services/config";
import { QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";

import FormUtility from "../../services/form";
// import useMediaQuery from "../../hooks/useMediaQuery";
const formUtility = new FormUtility();

export const comparators = [
  "None",
  "equals",
  "not",
  "before",
  "after",
  "between",
  "weekday",
  "weekend",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "first day of month",
  "last day of month",
  //"not leap year",
  //"leap year",
];
export const comparatorWithNoValue = [
  "None",
  "weekday",
  "weekend",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "first day of month",
  "last day of month",
];

export function New() {
  return {
    type: "DateInput",
    code: null,
    title: "New Date Input Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: null,
    min: dayjs(),
    max: dayjs().add(1, "day"),
  };
}
export function Reset(question) {
  // PURPOSE: Reset user answers specific to this question type.
  utils.assert(question != null, "question is null.");
  // utils.log.component(`DateInput.Reset(${question.code})`, question);
  question.answer = null;
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // utils.log.component(`DateInput.Edit(${question.code})`, question);
  utils.assert(question != null, "question is null.");
  // #region Initialize
  formUtility.setDetail(question);
  // Validate min/max exists

  const disablePastDates = (date) => dayjs(date).isBefore(question?.min, "day");

  question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    question.min,
    dayjs()
  );

  const disableFutureDates = (date) =>
    dayjs(date).isAfter(question?.max, "day");

  question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    question.max,
    dayjs().add(1, "day")
  );

  // #endregion
  // #region Events
  const handleChange = (e, target) => {
    // NOTE: target = The question.property to update
    // Valid value
    const isoDate = dayjs(e.$d).format(config.defaults.DATE_STORAGE_FORMAT);
    question[target] = isoDate; //value.toLocaleDateString();
    // Adjust min/max
    const targetOpposite = target === "min" ? "max" : "min";
    const mode = target === "min" ? "up" : "down";
    question[targetOpposite] = utils.adjustDate(
      dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
      dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
      mode
    )[targetOpposite];

    // Update question
    const event = {
      target: { name: target, value: isoDate },
    };
    formUtility.handleChange(event, setInputs);
  };
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          id="non-linear-slider"
          gutterBottom
          style={{ fontFamily: "Public-sans" }}
        >
          Range:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} id="textboxMinimum">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            value={question.min}
            label="Minimum"
            onChange={(e) => handleChange(e, "min")}
            shouldDisableDate={disableFutureDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6} id="textboxMaximums">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            value={question.max}
            label="Maximum"
            onChange={(e) => handleChange(e, "max")}
            shouldDisableDate={disablePastDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}

export const DependencySelector = ({context}) => {
  // !NOTE: useState() is prohibited in this component.
  //STEP:1 => Get Dependency => that is on which qn(single choice,multiple choice) it is dependent;
  return(
    <DependencySelectorComparatorValue
    context={context}
    />
  )
};

const DependencySelectorComparatorValue = ({context}) =>{
    // Note :: We send and recive date(min,max,value,answer) in YYYY-MM-DD format but we convert into dayjs object to
    // get all functionality

  const dependency = dependencyUtil.get(context);
  // utils.log.info(":: STEP:1",dependency);
  const {question , value ,comparator } = dependency || {};
  const {min , max} = question || {};
  // utils.log.info(":: STEP:2",question);

  const [minDate] = useState(dayjs(min));
  const [maxDate] = useState(dayjs(max));
  const valueDate = dayjs(value);
  const list = utils.toSelectItem(comparators);
  const [selectedComparator,setSelectedComparator] = useState(comparator ?? list[0].value);
  // ASSIGNING SELECTED VALUE AS MINM DATE IF VALUE IS NULL => USER CAN'T SEND EMPTY VALUE FOR COMPARATOR equals,not,...
  const [selectedValue , setSelectedValue] = useState(value ? valueDate : minDate);

  // If there is no comparator => then add comparator
  if(!comparator){
  dependency.comparator = "None";
  dependencyUtil.set(context, dependency);
  }
  // if there is no value => then add value
  if(!value){
    dependency.value = min;
    dependencyUtil.set(context, dependency);
  }
  // const value = valueConvertByComparator(dependency, minDate, maxDate ,selectedComparator);

  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    const comparator = e.target.value;
    if(comparator === "None"){
      setSelectedValue(null);
    }
    dependency.comparator = comparator;
    setSelectedComparator(comparator);
    dependencyUtil.set(context, dependency);
  };
  const handleValueChange = (e) => {
    const isoDate = e.format(config.defaults.DATE_STORAGE_FORMAT);
    dependency.value = isoDate;
    setSelectedValue(e);
  };

  utils.log.info(":: DateInput Comparator and Value",{selectedComparator,selectedValue ,minDate ,value , valueDate});
  return (
    <div style={{
      display:"flex",
      gap:"20px"
    }}>
      <div id="dropdownComparators">
      <FormSelect
        id="dateInput-comparators"
        label="Comparators"
        data={list}
        value={selectedComparator}
        onChange={handleComparatorChange}
        style={{
          width: utils.getTextBoxWidth(selectedComparator?.length),
        }}
      />
      </div>
      {!comparatorWithNoValue?.includes(selectedComparator) && 
      <LocalizationProvider dateAdapter={AdapterDayjs}>
       {selectedComparator === "between" && (
          <ComparatorBetween
            context={context}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        {selectedComparator !== "between" && (
          <DatePicker
            renderInput={(props) => (
              <Input style={{ width: "150px" }} {...props} />
            )}
            label="Value"
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
            value={selectedValue}
            // disabled={comparatorIsValue.includes(dependency.comparator)}
            onChange={handleValueChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </LocalizationProvider>}

    </div>
  );
}

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  // utils.log.component("DateInput.Render()", props);
  // #region Initialize
  const question = props.question;
  utils.assert(question != null, "question is null.");
  // TODO: 062923 Why is question of type DateInput appearing here with choice collection?
  const preview = props.preview ?? false;

  let minDate = (question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    question.min,
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let maxDate = (question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    question.max,
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let answer =
    question.answer == null
      ? null
      : utils.checkDateValidity(
          `dependency(${question.code}).value`,
          question.answer,
          minDate
        );

  // #endregion
  // #region Events

  const handleChange = (e) => {
    let answer = e.format(config.defaults.DATE_STORAGE_FORMAT); //e.$d.toLocaleDateString(); // Format to date only.
    // utils.log.event(`handleChange(e): ${answer}`);
    //question.answer = answer;
    updateQuestionAnswer(question, answer, preview, props.setQuestions);
  };

  const isQuestionCompleted = isCompleted(question);

  // const {isSmDown} = useMediaQuery();

  // #endregion
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            {question.code}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          {/* <div>{helperText}</div> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(props) => <Input {...props} />}
              label="Answer"
              value={answer}
              onChange={handleChange}
              minDate={minDate}
              maxDate={maxDate}
              // sx={{  width: isSmDown ? "100%":"300px", height: "56px", background: "#F8F8F8" }}
              sx={{ width: "300px", height: "56px", background: "#F8F8F8" }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </>
  );
}

// #region Dependency
export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  utils.log.info("^^ dependency in DateInput",dependency);
  if (dependency == null) {
    return true;
  }
  const {question} = dependency || {};
  const {answer} = question || {};
  if(!answer)return false;
  // *** Evaluate ***
  switch (dependency.comparator) {
    case "None":
      return handleComparatorNone(dependency);
    case "equals":
    case "not":
      return handleComparatorEquality(dependency);
    case "before":
    case "after":
    case "between":
      return handleComparatorPrepositions(dependency);
    case "weekday":
    case "weekend":
      return handleComparatorWeek(dependency);
    case "first day of month":
    case "last day of month":
      return handleComparatorDaysOfMonth(dependency);
    case "not leap year":
    case "leap year":
      return handleComparatorLeapYear(dependency);
    case "monday":
    case "tuesday":
    case "wednesday":
    case "thursday":
    case "friday":
    case "saturday":
    case "sunday":
      return handleComparatorDaysOfWeek(dependency);

    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
// export const valueConvertByComparator = (dependency, minDate, maxDate , comparator) => {
//   // PURPOSE: Convert value to object for between comparator and string for other comparators.
//   /* PARAMETERS:
//   dependency: required object
//   minDate: string
//   maxDate: string
// */
//   // #region Assertions
//   if(comparator === "None")return;
//   utils.assert(
//     dependency.hasOwnProperty("comparator"),
//     `Required property(comparator) is not in dependency.`
//   );
//   utils.assert(
//     dependency.hasOwnProperty("value"),
//     `Required property(value) is not in dependency.`
//   );
//   // #endregion Assertions

//   if (dependency.comparator === "between") {
//     if (typeof dependency.value !== "object") {
//       // Initialize value to object for between comparator
//       dependency.value = initValueRangeObject(minDate, maxDate);
//       // utils.log.info(
//       //   "Converted dependency.value to object for between comparator."
//       // );
//     }
//     return dependency.value;
//   } else {
//     // Comparator is NOT betwen and value is a string
//     // Check if value is object and convert to string

//     if (typeof dependency.value === "object") {
//       dependency.value = minDate;
//       // utils.log.info(
//       //   `Converted dependency.value to string for ${dependency.comparator} comparator.`
//       // );
//     }
//     dependency.value = utils
//       .checkDateValidity(
//         `dependency(${dependency.question.code}).value`,
//         dependency.value,
//         minDate
//       )
//       .format(config.defaults.DATE_STORAGE_FORMAT);
//     return dependency.value;
//   }
// };
// #region Comparators

function isAnswerInRange(answer, min, max) {
  if (!answer) return false; 
  const answerDate = dayjs(answer);
  const minDate = dayjs(min);
  const maxDate = dayjs(max);

  if (!answerDate.isValid() || !minDate.isValid() || !maxDate.isValid()) {
    return false;
  }

  const isSameOrAfterMin = answerDate.isSame(minDate) || answerDate.isAfter(minDate);
  const isSameOrBeforeMax = answerDate.isSame(maxDate) || answerDate.isBefore(maxDate);

  return isSameOrAfterMin && isSameOrBeforeMax;
}


function handleComparatorNone(dependency) {
  const { question } = dependency || {};
  if (!question) return false; 
  const { min, max, answer } = question;
  return isAnswerInRange(answer, min, max);
}

function handleComparatorEquality(dependency){

  const {question ,comparator , value} = dependency || {};
  const {min , max , answer} = question;
  const isWithInRange = isAnswerInRange(answer, min , max);
  const answerDate = dayjs(answer);
  const valueDate = dayjs(value);
  switch (comparator) {
    case "equals":
      return isWithInRange && valueDate.isSame(answerDate, "day");
    case "not":
      return isWithInRange && !valueDate.isSame(answerDate, "day");
    default:
      throw new Error(
        `handleComparatorEquality(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
const handleComparatorPrepositions = (dependency) => {
  const { question , comparator , value} = dependency || {};
  const { min , max , answer} = question;

  const isWithInRange = isAnswerInRange(answer, min , max);
  const valueDate = dayjs(value);
  const answerDate = dayjs(answer);
  switch (comparator) {
    case "before":
      return isWithInRange && answerDate?.isBefore(valueDate);
    case "after":
      return isWithInRange && answerDate?.isAfter(valueDate);
    case "between":
      const isAfterMin =
      answerDate.isAfter(dayjs(dependency.value.min)) ||
      answerDate.isSame(dayjs(dependency.value.min), "day");

      const isBeforeMax =
      answerDate.isBefore(dayjs(dependency.value.max)) ||
      answerDate.isSame(dayjs(dependency.value.max), "day");

      return isWithInRange && isAfterMin && isBeforeMax;

    default:
      throw new Error(
        `handleComparatorPrepositions(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};

const isWeekday = (date) => {
  const day = date.day(); // 0 = Sunday, 6 = Saturday
  return day > 0 && day < 6; // Monday to Friday
};

const isWeekend = (date) => !isWeekday(date);

function handleComparatorWeek(dependency) {
  const { question, comparator } = dependency || {};
  if (!question) return false;
  const { answer } = question;
  if (typeof answer !== "string" || !dayjs(answer).isValid()) return false;
  const answerDate = dayjs(answer);
  switch (comparator) {
    case "weekday":
      return isWeekday(answerDate);
    case "weekend":
      return isWeekend(answerDate);
    default:
      throw new Error(
        `handleComparatorWeek(${question.code}) - Unknown comparator: ${comparator}`
      );
  }
}

const handleComparatorDaysOfMonth = (dependency) => {
  const { question, comparator } = dependency || {};
  if (!question || !comparator) return false;

  const answerDate = dayjs(question.answer);
  if (!answerDate.isValid()) return false;

  switch (comparator) {
    case "first day of month":
      return answerDate.date() === 1;
    case "last day of month":
      return answerDate.isSame(answerDate.endOf("month"), "day");
    default:
      throw new Error(
        `handleComparatorDaysOfMonth(${question.code}) - Unknown comparator: ${comparator}`
      );
  }
};

const handleComparatorLeapYear = (dependency) => {
  const { question, comparator } = dependency || {};
  if (!question || !comparator) return false;

  const answerDate = dayjs(question.answer);
  if (!answerDate.isValid()) return false;

  switch (comparator) {
    case "leap year":
      return answerDate.isLeapYear();
    case "not leap year":
      return !answerDate.isLeapYear();
    default:
      throw new Error(
        `handleComparatorLeapYear(${question.code}) - Unknown comparator: ${comparator}`
      );
  }
};

const handleComparatorDaysOfWeek = (dependency) => {
  const { answer } = dependency.question || {};
  const { comparator } = dependency || {};

  utils.log.info(":: handlkeComparator",{answer,comparator});



  const dayOfWeek = dayjs(answer).day();
  const daysMap = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0,
  };

  if (comparator in daysMap) {
    return dayOfWeek === daysMap[comparator];
  }

  throw new Error(
    `handleComparatorDaysOfWeek(${dependency.question.code}) - Unknown comparator: ${comparator}`
  );
};

// #endregion Comparators

// PURPOSE: Component for between comparator
export const ComparatorBetween = ({ context, minDate, maxDate }) => {

  const dependency = dependencyUtil.get(context);
  if (typeof dependency.value !== "object" || !dependency.value) {
    dependency.value = initValueRangeObject();
    dependencyUtil.set(context, dependency);
  }
  utils.log.info("mindATE",dependency.value);
  const [valueMin, setValueMin] = useState(minDate);
  const [valueMax, setValueMax] = useState(maxDate);
  dependency.value.min = valueMin.format(config.defaults.DATE_STORAGE_FORMAT);
  dependency.value.max = valueMax.format(config.defaults.DATE_STORAGE_FORMAT);

  const handleChange = (e, target) => {
    const isoDate = e.format(config.defaults.DATE_STORAGE_FORMAT);
    dependency.value[target] = isoDate;

    // const targetOpposite = target === "min" ? "max" : "min";
    // const mode = target === "min" ? "up" : "down";
    // dependency.value[targetOpposite] = utils.adjustDate(
    //   dayjs(dependency.value.min).format(config.defaults.DATE_STORAGE_FORMAT),
    //   dayjs(dependency.value.max).format(config.defaults.DATE_STORAGE_FORMAT),
    //   mode
    // )[targetOpposite];
    // Update dependency.value
    dependencyUtil.set(context, dependency);
    if(target === "min"){
      setValueMin(dayjs(dependency.value.min));
    }
    if(target === "max"){
      setValueMax(dayjs(dependency.value.max));
    }
  };
  return (
    <>
      <DatePicker
        renderInput={(props) => <Input style={{ width: "150px" }} {...props} />}
        label="Minimum"
        defaultValue={valueMin} /* value={value} */
        value={valueMin}
        onChange={(e) => handleChange(e, "min")}
        minDate={minDate}
        maxDate={maxDate}
      />{" "}
      <DatePicker
        renderInput={(props) => <Input style={{ width: "150px" }} {...props} />}
        label="Maximum"
        defaultValue={valueMax} /* value={value} */
        value={valueMax}
        onChange={(e) => handleChange(e, "max")}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  );
};

export const isCompleted = (question) => {
  if (!question) {
    return false;
  }

  const { answer, min, max } = question;

  if (answer == null) return false;

  const dayjsAnswer = dayjs(answer);

  if (min != null && dayjsAnswer.isBefore(min)) return false;
  if (max != null && dayjsAnswer.isAfter(max)) return false;

  return true;
};

export const getValue = (question) => {
  return {
    value: question.answer,
  };
};

// #endregion
/* 
FIXED
  051423 - Date picker not showing correct selectable date range sometimes
  STEPS: 
    1. Specify a date range.
    2. Click on Preview tab.
    3. Click on the date picker.
  EXPECTED:
    The date picker should allow selection of dates the same as the specified date range.
  ACTUAL:
    * The date picker allows selection of dates outside of the specified date range.
    * The issue occurs sometimes and not others.
  CAUSE:
    * When min/max date is retrieved, new Date(string) is converting the date to GMT.
  RESOLUTION:
    * Review later to see if we need to add time zone for recipient.
  FIX:
    * In Render, used Luxon to instantiate the date object for min/max.
*/
