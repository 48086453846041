import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { getConfig } from "../auth/config";
import Input from "../components/Input/inputs";
import { AuthContext } from "../services/context";
import { makeStyles } from "@mui/styles";
import { Card, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(4, 2),
    fontFamily: "Public-sans",
    maxWidth: 600,
    width: "100%",
    margin: "auto",
  },
  button: {
    marginLeft: "auto",
    width: "100px",
    marginTop: theme.spacing(2),
  },
}));

export const Provisioning = () => {
  // utils.log.component("Provisioning");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth0();
  const classes = useStyles();
  const { apiOrigin: backendUrl, setUpEmployee } = useContext(AuthContext);

  const { apiOrigin = backendUrl, audience } = getConfig();
  const [legalName, setLegalName] = useState(null);
  // #endregion
  // #region Events
  const handle_Change_LegalName = (e) => {
    setLegalName(e.target.value);
  };
  const handle_onClick_Create = async () => {
    if (!user) {
      console.error("User Info is not available.");
      return;
    }
    setIsSubmitting(true);
    await fetch(`${apiOrigin}/organization/provision`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        LegalName: legalName,
        User: {
          Given_Name: user?.given_name || "",
          Family_Name: user?.family_name || "",
          Email: user.email,
          Sub: user?.sub || null,
        },
      }),
    })
      .then(async (result) => {
        await setUpEmployee();
        navigate("/?view=coordinators");
      })
      .catch((error) => {
        console.error("Error creating organisation", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // #endregion

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography component="h3" variant="h4" mt={2} fontWeight={700}>
          Getting Started
        </Typography>

        <Typography
          component="p"
          variant="body1"
          mb={3}
          style={{ fontFamily: "Public-sans" }}
        >
          To get started, please enter the name of your organization.
        </Typography>

        <Input
          label="Enter the name of your organization."
          fullWidth
          onChange={handle_Change_LegalName}
        />

        <Button
          type="submit"
          color="primary"
          className={classes.button}
          onClick={() => handle_onClick_Create()}
          disabled={!audience || isSubmitting || !legalName?.trim()}
        >
          {isSubmitting ? "Creating..." : "Create"}
        </Button>
      </Card>
    </div>
  );
};

/* export default withAuthenticationRequired(Provisioning, {
  onRedirecting: () => <Loading />,
}); */
export default Provisioning;
