import { useEffect, useState } from "react";

const useMediaQuery = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmDown = windowWidth <= 600;
  const isSmUp = windowWidth > 600;
  const isMdDown = windowWidth <= 900;
  const isMdUp = windowWidth > 900;
  const isLgDown = windowWidth <= 1200;
  const isLgUp = windowWidth > 1200;
  const isXlgDown = windowWidth <= 1536;
  const isXlgUp = windowWidth > 1536;

  return {
    isSmDown,
    isSmUp,
    isMdDown,
    isMdUp,
    isLgDown,
    isLgUp,
    isXlgDown,
    isXlgUp,
  };
};

export default useMediaQuery;
