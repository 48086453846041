import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig as getAuthConfig } from "./auth/config";
import { ApiProvider } from "./services/context";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import { ThemeProvider, createTheme } from "@mui/material";
import OnboardingProvider from "./components/OnboardingProvider";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const authConfig = getAuthConfig();
const authProviderConfig = {
  domain: authConfig.domain,
  clientId: authConfig.clientId,
  authorizationParams: {
    redirectUri: window.location.origin,
    audience: authConfig.audience,
  },
  onRedirectCallback,
};

const theme = createTheme({
  typography: {
    fontFamily: "Public-sans",
  },
  box: {
    fontFamily: "Public-sans",
  },
  tabs: {
    fontFamily: "Public-sans",
  },
  tab: {
    fontFamily: "Public-sans",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: "#041433",
            fontSize: "16px",
            borderRadius: 0,
            textTransform: "capitalize",
            "&:hover": {
              color: "#0066F9",
            },
          },
        },
        {
          props: { variant: "contained-primary" },
          style: {
            color: "#ffffff",
            backgroundColor: "#0066F9",
            fontSize: "15px",
            boxShadow: "none",
            borderRadius: "0px",
            textTransform: "capitalize",
            fontWeight: 700,
            letterSpacing: "0.2px",
            padding: "8px 25px",
            "&:hover": {
              background: "#0066F9",
              color: "#fff",
              opacity: "0.8",
            },
          },
        },
        {
          props: { variant: "contained-green" },
          style: {
            color: "#ffffff",
            backgroundColor: "#8DC63F",
            fontSize: "15px",
            boxShadow: "none",
            borderRadius: "0px",
            textTransform: "capitalize",
            fontWeight: 700,
            letterSpacing: "0.2px",
            padding: "8px 25px",
            "&:hover": {
              background: "#8DC63F",
              color: "#fff",
            },
          },
        },
        {
          props: { variant: "contained-white" },
          style: {
            color: "#29292B",
            backgroundColor: "#ffffff",
            border: "2px solid #29292B",
            fontSize: "14px",
            boxShadow: "none",
            borderRadius: "0px",
            textTransform: "capitalize",
            fontWeight: 700,
            letterSpacing: "0.2px",
            padding: "8px 25px",
            "&:hover": {
              background: "#ffffff",
              color: "#29292B",
            },
          },
        },
      ],
    },
  },
});

const root = document.getElementById("root");
createRoot(root).render(
  <ThemeProvider theme={theme}>
    <Auth0Provider {...authProviderConfig}>
      <ApiProvider>
        <SnackbarProvider
          // hideIconVariant
          // action={(key) => (
          //   <IconButton
          //     aria-label="close"
          //     color="inherit"
          //     onClick={() => closeSnackbar(key)}
          //   >
          //     <CloseIcon />
          //   </IconButton>
          // )}
          maxSnack={3}
        >
          <ConfirmProvider>
            <OnboardingProvider>
              <App />
            </OnboardingProvider>
          </ConfirmProvider>
        </SnackbarProvider>
      </ApiProvider>
    </Auth0Provider>
  </ThemeProvider>
);

serviceWorker.unregister();
