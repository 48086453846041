import React from "react";
import { FOOTER_LINKS, FOOTER_SOCIAL_LINKS } from "../../utils/contentData";
import { Link } from "react-router-dom";
import style from "./style.module.css";

const Footer = () => {
  return (
    <footer className={style.footerWrapper}>
      <div className={style.socialContainer}>
        {FOOTER_SOCIAL_LINKS.map((item, index) => (
          <div key={index} className={style.socialLinksWrapper}>
            <img src={item.img} alt="GOPASSGO" width={120} height={80} />
            <p className={style.socialText}>{item.description}</p>
            <div className={style.socialIconsWrapper}>
              {item.social_links.map((social, i) => (
                <Link to={social.link} key={i}>
                  <img
                    src={social.icon}
                    alt={social.link}
                    width={32}
                    height={32}
                  />
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={style.footerLinksWrapper}>
        {FOOTER_LINKS.map((section, index) => (
          <div key={index} className={style.linkContainer}>
            <p>{section.title}</p>
            {section.links.map((link, i) => (
              <Link to={link.link} key={i} className={style.linksSection}>
                {link.subtitle}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
