import { DateTime } from "luxon";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { cssStyle, logLevel } from "./config.js";
import * as config from "./config.js";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getQuerystring = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);
  return value;
};
export const getPathNameLast = () => {
  var parts = document.location.pathname.split("/");
  var lastPart = parts[parts.length - 1];

  return lastPart;
};
export const flattenJSON = (obj, prefix = [], current = {}) => {
  if (typeof obj === "object" && obj !== null) {
    for (const key of Object.keys(obj)) {
      flattenJSON(obj[key], prefix.concat(key), current);
    }
  } else {
    current[prefix.join(".")] = obj;
  }
  return current;
};

// inputDate is in dayjs format
export function formatDate(inputDate, inputFormat = null, outputFormat = null) {
  const date = dayjs(inputDate, inputFormat);
  return date.format(outputFormat);
}
export function getTimeZones(region = null) {
  const timeZones = Intl.supportedValuesOf("timeZone");
  if (region != null) return timeZones.filter((tz) => tz?.startsWith(region));
  else return timeZones;
}
export function getUserTimeZone() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return tz;
}
export function isValidDate(dateString) {
  const date = DateTime.fromJSDate(dateString);
  return date.isValid;
}
export const checkDateValidity = (label, dateValue, validDate) => {
  if (
    dateValue === null ||
    dateValue === undefined ||
    !dayjs(dateValue).isValid()
  ) {
    log.info(
      `INVALID DATE: ${label} was changed from ${dateValue} to ${validDate.toString()}.`,
      dateValue,
      validDate
    );
    return validDate;
  }
  return dayjs(dateValue);
};
function isDateValid(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}
export function daysDiff(date1, date2) {
  if (!isDateValid(date1) || !isDateValid(date2)) return 0;

  const startDate = new Date(date1);
  const endDate = new Date(date2);

  if (startDate > endDate) return 0;

  const diff = Math.abs(startDate - endDate);
  const result = Math.ceil(diff / (1000 * 60 * 60 * 24)).toLocaleString(
    undefined,
    { minimumFractionDigits: 0 }
  );
  return result;
}

export const adjustDate = (minDateValue, maxDateValue, mode) => {
  // PURPOSE: Ensures that two dates representing a range are in the correct order and values.

  log.info(
    `adjustDate(minDateValue: ${minDateValue}, maxDateValue: ${maxDateValue}, mode: ${mode})`
  );
  if (mode === "down" && minDateValue > maxDateValue) {
    log.info(
      `Adjust down minDateValue from ${minDateValue} to ${maxDateValue}`
    );
    return { min: maxDateValue, max: maxDateValue };
  }
  if (mode === "down" && minDateValue < maxDateValue) {
    log.info(`No adjustment needed.`);
    return { min: minDateValue, max: maxDateValue };
  }

  if (mode === "up" && minDateValue > maxDateValue) {
    log.info(`Adjust up maxDateValue from ${maxDateValue} to ${minDateValue}`);
    return { min: minDateValue, max: minDateValue };
  }
  if (mode === "up" && minDateValue < maxDateValue) {
    log.info(`Adjust up minDateValue from ${minDateValue} to ${maxDateValue}`);
    return { min: maxDateValue, max: maxDateValue };
  }
  log.info(`No adjustment needed.`);
  return { min: minDateValue, max: maxDateValue };
};
export const adjustDateOrTime = (minObject, maxObject, mode) => {
  assert(mode === "up" || mode === "down", "Invalid mode.");
  assert(dayjs.isDayjs(minObject), "minObject is not a dayjs object.");
  assert(dayjs.isDayjs(maxObject), "maxObject is not a dayjs object.");

  log.info(`adjustDateOrTime(mode: ${mode})`, minObject, maxObject);
  if (mode === "down" && maxObject.isBefore(minObject)) {
    log.info(`Adjust down minObject from ${minObject} to ${maxObject}`);
    return { min: maxObject, max: maxObject };
  }
  if (mode === "down" && minObject.isBefore(maxObject)) {
    log.info(`No adjustment needed.`);
    return { min: minObject, max: maxObject };
  }

  if (mode === "up" && minObject.isAfter(maxObject)) {
    log.info(`Adjust up maxObject from ${maxObject} to ${minObject}`);
    return { min: minObject, max: minObject };
  }
  if (mode === "up" && minObject.isBefore(maxObject)) {
    log.info(`Adjust up minObject from ${minObject} to ${maxObject}`);
    return { min: maxObject, max: maxObject };
  }
  log.info(`No adjustment needed.`);
  return { min: minObject, max: maxObject };
};
export function getTicks(trailingDigits = null) {
  const ticks = new Date().getTime();
  if (trailingDigits == null) return ticks;
  else {
    return ticks % Math.pow(10, trailingDigits);
  }
}
export const noSelection = { label: "- None -", value: -1 };
function mergeIntoString(item, template) {
  for (var key in item) {
    template = template.replace(`@['${key}']`, item[key]);
  }
  return template;
}

export function toSelectItem(
  list,
  labelField,
  valueField,
  customFormat = false
) {
  var items = [];
  if (list == null) return items;
  if (list.length > 0) {
    for (var i = 0; i < list.length; i++) {
      if (customFormat) {
        items.push({
          label: mergeIntoString(list[i], labelField),
          value: list[i][valueField] ?? i,
        });
      } else {
        const simpleList =
          list[i][labelField] === undefined &&
          list[i][valueField] === undefined;
        if (simpleList) items.push({ label: list[i], value: list[i] });
        else
          items.push({
            label: list[i][labelField] ?? list[i][valueField] ?? list[i],
            value: list[i][valueField] ?? i,
          });
      }
    }
  }

  return items;
}

export const formatMoney = (money) => {
  return money.toLocaleString();
};
export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;

  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};
export const isUniqueValue = (value, list) => {
  const lowerCaseArray = list.map((item) => item.toLowerCase());
  const lowerCaseValue = value?.toLowerCase();
  const isUnique = !lowerCaseArray.includes(lowerCaseValue);

  return isUnique;
};

export const isSyntheticEvent = (event) => {
  return event.nativeEvent && !(event instanceof Event);
};
export const isEvent = (event) => {
  if (typeof event === "object" && event !== null && "target" in event) {
    const { target } = event;
    if (
      typeof target === "object" &&
      target !== null &&
      "name" in target &&
      "value" in target
    ) {
      return true;
    }
  }
  return false;
};

export const newGuid = () => {
  return uuidv4();
};
export const findGreatestLength = (strings) => {
  let greatestLength = 0;

  for (const str of strings) {
    if (str.length > greatestLength) {
      greatestLength = str.length;
    }
  }

  return greatestLength;
};
export function removeFunctions(obj) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "function") {
      delete obj[key];
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        log.info("Array.isArray(obj[key])", this);
        obj[key].forEach((item) => removeFunctions?.(item));
      } else {
        removeFunctions?.(obj[key]);
      }
    }
  });
}
export function copyFunctions(obj, copiedObj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "function") {
        copiedObj[key] = obj[key];
      } else {
        copiedObj[key] = copyFunctions(
          obj[key],
          Array.isArray(obj[key]) ? [] : {}
        );
      }
    }
  }

  return copiedObj;
}
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function required(value, errorMessage) {
  try {
    if (value == null) {
      throw new Error();
    }
  } catch (error) {
    const stackLines = error.stack.split("\n");
    const callingFunctionLine = stackLines[2];
    console.error(`Required parameter  ${callingFunctionLine}`);

    if (errorMessage != null) console.error(`${errorMessage}`);
  }
}
export function assert(condition, message) {
  // PURPOSE: Throws an error if the condition is false.
  if (!condition) {
    throw new Error("ASSERT(FAIL): " + message);
  }
}

export const log = {
  disabled() {},

  debug(message, styling = null, args) {
    if (logLevel.debug)
      logger("DEBUG: " + message, styling, cssStyle.debug, args);
  },
  component(message, styling = null, args) {
    if (logLevel.component)
      logger("COMPONENT: " + message, styling, cssStyle.component, args);
  },
  section(message, styling = null, args) {
    if (logLevel.debug)
      logger("SECTION: " + message, styling, cssStyle.section, args);
  },
  info(message, styling = null, args) {
    if (logLevel.info) logger("INFO: " + message, styling, cssStyle.info, args);
  },
  warn(message, styling = null, args) {
    if (logLevel.warn) logger("WARN: " + message, styling, cssStyle.warn, args);
  },
  error(message, styling = null, args) {
    if (logLevel.error)
      logger("ERROR: " + message, styling, cssStyle.error, args);
  },
  stateChange(message, styling = null, args) {
    if (logLevel.stateChange)
      logger(
        "*** STATE CHANGE *** " + message,
        styling,
        cssStyle.stateChange,
        args
      );
  },
  useEffect(message, styling = null, args) {
    if (logLevel.useEffect)
      logger("useEffect(): " + message, styling, cssStyle.useEffect, args);
  },
  event(message, styling = null, args) {
    if (logLevel.event)
      logger("EVENT: " + message, styling, cssStyle.event, args);
  },
  api(message, styling = null, args) {
    if (logLevel.api) logger("API: " + message, styling, cssStyle.api, args);
  },

  clear() {
    console.clear();
  },
  set(state = true, levels = null) {
    Object.keys(logLevel).forEach((key) => {
      if (levels == null || levels.includes(key)) logLevel[key] = state;
    });
    return this;
  },
};
function logger(message, styling, defaultStyling, args) {
  try {
    const validStyle = cssStyle.hasMatch(styling);
    const messageFormatted = message?.startsWith("%c")
      ? message
      : `%c${message}`;
    const argsReturned = validStyle ? args : styling;

    if (validStyle) {
      if (argsReturned === undefined || argsReturned === null)
        console.log(messageFormatted, styling);
      else console.log(messageFormatted, styling, argsReturned);
    } else {
      if (argsReturned === undefined || argsReturned === null)
        console.log(messageFormatted, defaultStyling);
      else console.log(messageFormatted, defaultStyling, argsReturned);
    }
  } catch (error) {
    // debugger;
  }
}
export function validateEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}
export const getTextBoxWidth = (maxLength, customCharacterWidth = null) => {
  if (isNaN(maxLength) || maxLength < 0)
    throw new Error(`Invalid maxLength(number 0 or greater): ${maxLength}`);
  const characterWidth =
    customCharacterWidth ?? config.defaults.CHARACTER_WIDTH_PX;
  const characterWidthMin = config.defaults.CHARACTER_WIDTH_MIN_PX;
  const calculatedWidth = characterWidth * maxLength;
  const textBoxWidth =
    calculatedWidth < characterWidthMin ? characterWidthMin : calculatedWidth;

  const textBoxWidthFormatted = textBoxWidth + "px";
  return textBoxWidthFormatted;
};
export const pushIfNotExist = (array, element) => {
  if (!array.includes(element)) {
    array.push(element);
  }
};
