import Grid from "../Grid";
import styled from "styled-components";

const StyledForm = styled.form`
  padding:8px;
`;

const FormLabel = styled.h1`
  font-size: 1.5rem;
  font-family: "Public-sans";
  font-weight: bold;
`;
export default function Form(props) {
  const { label = "", children, ...rest } = props;

  return (
    <StyledForm {...rest}>
      <Grid container spacing={2}>
        {label && <Grid item xs={12}>
          <FormLabel>{label}</FormLabel>
        </Grid>}
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </StyledForm>
  );
}
