/* eslint-disable no-useless-catch */
import { getApiOrigin, isDevelopment } from "./context";
import * as utils from "./utilities";

export default class EmployeeService {
  constructor(email, headers) {
    // utils.log.api(`EmployeeService.constructor(email: ${email})`, headers);
    this.email = email;
    this.headers = headers;
    this.currentEmployee = null;
  }
  async get(email) {
    // utils.log.api(`EmployeeService.get(email: ${email})`);
    utils.assert(email != null, "Email required.  Unable to get employee.");

    if (email == null) {
      // utils.log.warn("Email is null");
      return null;
    }

    try {
      const remoteUrl = `${getApiOrigin()}/employee/ByEmail/${email}?isInternal=false`;
      // utils.log.info(
      //   `Fetching employee by email ${email} remoteUrl: ${remoteUrl}`
      // );

      const response = await fetch(remoteUrl, { headers: this.headers });
      utils.assert(
        response.status === 200,
        `WARNING: response.status == ${response.status}`
      );

      if (response.status === 204) {
        // utils.log.warn(
        //   `Employee with email ${email} not found.  This can indicate new user.`
        // );
        return null;
      }

      const employee = await response.json();

      utils.assert(
        isDevelopment && employee != null,
        "WARN: Employee not found."
      );

      if (employee != null) {
        // utils.log.debug("Current Employee", employee);
        this.setCurrentEmployee(employee);
        return employee;
      } else return null;
    } catch (error) {
      // utils.log.error(error);
    }
  }
  setCurrentEmployee(employee) {
    this.currentEmployee = employee;
  }

  async add(employee) {
    //  utils.log.api(
    //   `EmployeeService.add(employee: ${employee.firstName} ${employee.lastName})`,
    //   employee
    // );
    const remoteUrl = `${getApiOrigin()}/employee/add`;

    return await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(employee),
    });
  }
  async getAll() {
    // utils.log.api("EmployeeService.getAll()");
    const remoteUrl = `${getApiOrigin()}/employee?viewModel=Employee.ViewModels.External`;

    // try {
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const list = await response.json();

    return list;
    // } catch (error) {
    // utils.log.error(`Fetch failed: ${error.message}`);
    // throw error;
    // }
  }
  async getDepartments(employee, surveyCode) {
    // utils.log.api("EmployeeService.getDepartments()");
    utils.assert(employee != null, "Employee required.");
    utils.assert(employee.uid != null, "Employee UID required.");
    utils.assert(surveyCode != null, "Survey code required.");
    const remoteUrl = `${getApiOrigin()}/employee/${
      employee.uid
    }/departments?surveyCode=${surveyCode}`;

    // try {
    const response = await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const list = await response.json();

    return list;
    // } catch (error) {
    // utils.log.error(`Fetch failed: ${error.message}`);
    // throw error;
    // }
  }

  async getAssignedRecipientsByDepartment(uid, surveyCode, departmentCode) {
    const remoteUrl = `${getApiOrigin()}/employee/${uid}/recipients?surveyCode=${surveyCode}&departmentCode=${departmentCode}`;

    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const list = await response.json();

      return list;
    } catch (error) {
      // utils.log.error(`Fetch failed: ${error.message}`);
      throw error;
    }
  }
  async update(employee) {
    // utils.log.api("EmployeeService.update", employee);
    const remoteUrl = `${getApiOrigin()}/employee/update`;

    return await fetch(remoteUrl, {
      method: "PATCH", // TODO: Change to PUT
      headers: this.headers,
      body: JSON.stringify(employee),
    });
  }

  async invite(employees) {
    // utils.log.api("EmployeeService.invite", employees);
    const remoteUrl = `${getApiOrigin()}/employee/invite?role=departmentCoordinator`;

    return await fetch(remoteUrl, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(employees),
    });
  }
  hasRole(role) {
    const isInRole = this.currentEmployee.roles.includes(role);
    // utils.log.debug(
    //   `EmployeeService.hasRole(${role}): ${this.currentEmployee.email} ${isInRole}`,
    //   this.currentEmployee.roles
    // );
    return isInRole;
  }
  addRole(role) {
    // Add role if it doesn't exist
    if (!this.currentEmployee.roles.includes(role))
      this.currentEmployee.roles.push(role);
  }
  addEmployeeRole(role, employee) {
    // Add role if it doesn't exist
    if (!employee.roles.includes(role)) employee.roles.push(role);
  }

  removeRole(role) {
    // Remove role if it exists
    if (this.currentEmployee.roles.includes(role))
      this.currentEmployee.roles = this.currentEmployee.roles.filter(
        (r) => r !== role
      );
  }
  removeEmployeeRole(role, employee) {
    // Remove role if it exists
    if (employee.roles.includes(role))
      employee.roles = employee.roles.filter((r) => r !== role);
  }
  isAllowed(role) {
    var isPrimaryCoordinator = this.currentEmployee.roles.some(
      (r) => r.toLowerCase() === "primarycoordinator"
    );
    if (isPrimaryCoordinator) return true;
    // Department coordinators have access to only specific departments
    return this.currentEmployee.roles.includes(role);
  }

  isAllowed_SurveyAdd(list) {
    //debugger;
    const plan = this.currentEmployee.plan;
    const surveyAddAllowed = this.currentEmployee.isAllowed.survey.add;
    const result = plan?.surveyMax > list?.length && surveyAddAllowed;
    // utils.log.debug(
    //   `EmployeeService.isAllowed_SurveyAdd(surveyAddAllowed: ${surveyAddAllowed} surveyMax: ${plan?.surveyMax} list: ${list?.length}  result: ${result})`,
    //   plan,
    //   list
    // );
    return result;
  }
  async isAllowed_SurveyRecipientAdd(recipientCount) {
    const plan = this.currentEmployee.plan;
    const result = plan?.surveyRecipientMax > recipientCount;
    // utils.log.debug(
    //   `EmployeeService.isAllowed_SurveyRecipientAdd(recipientCount: ${recipientCount} surveyRecipientMax: ${plan.surveyRecipientMax} result: ${result})`,
    //   plan
    // );
    return result;
  }
  async activate(email, accessCode) {
    // utils.log.api("EmployeeService.active", email, accessCode);
    const remoteUrl = `${getApiOrigin()}/employee/activate?email=${email}&accessCode=${accessCode}`;
    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const employee = await response.json();
      return employee;
    } catch (error) {
      // utils.log.error(error);
      throw error;
    }
  }

  async getOrganisations(email) {
    const endpoint = `${getApiOrigin()}/employee/organizations`;

    return await fetch(endpoint, {
      method: "GET",
      headers: this.headers,
    });
  }

  async setOrganisation(orgCode) {
    const endpoint = `${getApiOrigin()}/employee/organization/${orgCode}`;

    return await fetch(endpoint, {
      method: "PATCH",
      headers: this.headers,
    });
  }

  async getServerStatus() {
    // utils.log.api(":: EmployeeService.getServerStatus");
    try {
      const endpoint = `${getApiOrigin()}/Organization/environment`;
      const response = await fetch(endpoint, {
        method: "GET",
        headers: this.headers,
      });
      return response;
    } catch (error) {
      // utils.log.error(error);
    }
  }

  async getEmployeeDepartments(uid) {
    try {
      const endPoints = `${getApiOrigin()}/employee/${uid}/departments`;
      const response = await fetch(endPoints, {
        method: "GET",
        headers: this.headers,
      });
      return response;
    } catch (error) {
      // utils.log.error(error);
    }
  }

  async updateEmployeeDepartments(uid, selectedDepartments, actionType) {
    try {
      const endPoints = `${getApiOrigin()}/department/assignments/${uid}?action=${actionType}`;
      const response = await fetch(endPoints, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(selectedDepartments),
      });
      return response;
    } catch (error) {
      // utils.log.error(error);
    }
  }

  async getEmployeeTransactions(email) {
    const remoteUrl = `${getApiOrigin()}/billing/orders/history/employee/${email}`;

    try {
      const response = await fetch(remoteUrl, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const list = await response.json();

      return list;
    } catch (error) {
      // utils.log.error(`Fetch failed: ${error.message}`);
      throw error;
    }
  }

  async disableEmployee(uid, isEnabled) {
    const remoteUrl = `${getApiOrigin()}/employee/${uid}/isEnabled/${isEnabled}`;

    return await fetch(remoteUrl, {
      method: "PATCH",
      headers: this.headers,
    });
  }

  async remove(uid) {
    try {
      const remoteUrl = `${getApiOrigin()}/employee/${uid}/remove`;
      const response = await fetch(remoteUrl, {
        method: "DELETE",
        headers: this.headers,
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(
          json?.detail || "An Errror occur while deleting the Coordinator"
        );
      }
    } catch (error) {
      // utils.log.error("EmployeeService.remove()",error);
      throw new Error(
        error?.message || "An Errror occur while deleting the Coordinator"
      );
    }
  }

  async renameOrganization(organizationCode, name) {
    // utils.log.api("renameOrganization",{organizationCode, name})
    try {
      const remoteUrl = `${getApiOrigin()}/employee/organization/${organizationCode}/rename/${name}`;
      const response = await fetch(remoteUrl, {
        method: "PATCH",
        headers: this.headers,
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(
          json?.detail || "An Errror occur while renaming organization"
        );
      }
    } catch (error) {
      // utils.log.error("EmployeeService.renameOrganization()",error);
      throw new Error(
        error?.message || "An Errror occur while renaming organization"
      );
    }
  }
}
