import * as SingleChoice from "../views/survey-questions/singleChoice";
import * as TextInput from "../views/survey-questions/textInput";
import * as NumericInput from "../views/survey-questions/numericInput";
import * as NumericInputRange from "../views/survey-questions/numericInputRange";
import * as MultipleChoice from "../views/survey-questions/multipleChoice";
import * as CategoryChoice from "../views/survey-questions/categoryChoice";
import * as DateInput from "../views/survey-questions/dateInput";
import * as DateInputRange from "../views/survey-questions/dateInputRange";
import * as TimeInput from "../views/survey-questions/timeInput";
import * as TimeInputRange from "../views/survey-questions/timeInputRange";

export const googleTrackingID = "G-5FQ5VWVHV2";
export const logLevel = {
  api: true,
  debug: true,
  info: true,
  warn: true,
  error: true,
  stateChange: true,
  useEffect: true,
  event: true,
  section: true,
  component: true,
};
export const cssStyle = {
  info: "",
  stateChange:
    "color: blue;font-size: larger;font-weight: bold;background-color: lightblue;",
  useEffect:
    "color: lime;font-size: larger;font-weight: bold;background-color: yellow;",
  surveyHeading: "font-size: larger;font-weight:bolder;color:blue;",
  event:
    "color:yellow;background-color: blue;font-size: larger;font-weight: italic;",
  questionHeading: "color:yellow;font-weight:bolder;background-color:blue;",
  success: "color: green",
  error: "color: red",
  warn: "background-color: yellow; color: green;",
  debug: "color:darkorange;background-color:white;",
  api: "color:yellow;background-color: black;font-size: smaller;",
  component: "color:darkgreen;text-decoration: underline;",
  section: "color:yellow;background-color: #34a359;font-size: larger;",
  hasMatch(css) {
    for (const key in this) {
      if (this.hasOwnProperty(key) && this[key] === css) {
        return true;
      }
    }
    return false;
  },
};

// PURPOSE: The types array below is a lookup table to get functions by question type.
export const questionTypes = [
  {
    type: "SingleChoice",
    label: "Single Choice",
    description: "A question requiring a single answer.",
    showChoicesTab: true,
    new: SingleChoice.New,
    edit: SingleChoice.Edit,
    dependencySelector: SingleChoice.DependencySelector,
    dependencyNew: SingleChoice.DependencyNew,
    isAnswered: SingleChoice.isAnswered,
    isCompleted: SingleChoice.isCompleted,
    render: SingleChoice.Render,
    reset: SingleChoice.Reset,
    getValue: SingleChoice.getValue,
  },
  {
    type: "MultipleChoice",
    label: "Multiple Choice",
    description: "A question that can take multiple answers or none at all.",
    showChoicesTab: true,
    new: MultipleChoice.New,
    edit: MultipleChoice.Edit,
    dependencySelector: MultipleChoice.DependencySelector,
    dependencyNew: MultipleChoice.DependencyNew,
    isAnswered: MultipleChoice.isAnswered,
    isCompleted: MultipleChoice.isCompleted,
    render: MultipleChoice.Render,
    reset: MultipleChoice.Reset,
    getValue: MultipleChoice.getValue,
  },
  {
    type: "CategoryChoice",
    label: "Category Choice",
    description: "A question that requires a single answer per category.",
    showChoicesTab: true,
    new: CategoryChoice.New,
    edit: CategoryChoice.Edit,
    dependencySelector: CategoryChoice.DependencySelector,
    dependencyNew: CategoryChoice.DependencyNew,
    isAnswered: CategoryChoice.isAnswered,
    isCompleted: CategoryChoice.isCompleted,
    render: CategoryChoice.Render,
    reset: CategoryChoice.Reset,
    getValue: CategoryChoice.getValue,
  },
  {
    type: "TextInput",
    label: "Text Input",
    showChoicesTab: false,
    description: "A question requiring text as an answer.",
    new: TextInput.New,
    edit: TextInput.Edit,
    dependencySelector: TextInput.DependencySelector,
    dependencyNew: TextInput.DependencyNew,
    isAnswered: TextInput.isAnswered,
    render: TextInput.Render,
    reset: TextInput.Reset,
    isCompleted: TextInput.isCompleted,
    getValue: TextInput.getValue,
  },
  {
    type: "NumericInput",
    label: "Numeric Input",
    description: "A question requiring a number as an answer.",
    showChoicesTab: false,
    new: NumericInput.New,
    edit: NumericInput.Edit,
    dependencySelector: NumericInput.DependencySelector,
    dependencyNew: NumericInput.DependencyNew,
    isAnswered: NumericInput.isAnswered,
    render: NumericInput.Render,
    reset: NumericInput.Reset,
    isCompleted: NumericInput.isCompleted,
    getValue: NumericInput.getValue,
  },
  {
    type: "NumericInputRange",
    label: "Numeric Input (Range)",
    description: "A question requiring a number range as an answer.",
    showChoicesTab: false,
    new: NumericInputRange.New,
    edit: NumericInputRange.Edit,
    dependencySelector: NumericInputRange.DependencySelector,
    dependencyNew: NumericInputRange.DependencyNew,
    isAnswered: NumericInputRange.isAnswered,
    render: NumericInputRange.Render,
    reset: NumericInputRange.Reset,
    isCompleted: NumericInputRange.isCompleted,
    getValue: NumericInputRange.getValue,
  },
  {
    type: "DateInput",
    label: "Date Input",
    description: "A question requiring a date as an answer.",
    showChoicesTab: false,
    new: DateInput.New,
    edit: DateInput.Edit,
    dependencySelector: DateInput.DependencySelector,
    dependencyNew: DateInput.DependencyNew,
    isAnswered: DateInput.isAnswered,
    render: DateInput.Render,
    reset: DateInput.Reset,
    isCompleted: DateInput.isCompleted,
    getValue: DateInput.getValue,
  },
  {
    type: "DateInputRange",
    label: "Date Input (Range)",
    description: "A question requiring a date range as an answer.",
    showChoicesTab: false,
    new: DateInputRange.New,
    edit: DateInputRange.Edit,
    dependencySelector: DateInputRange.DependencySelector,
    dependencyNew: DateInputRange.DependencyNew,
    isAnswered: DateInputRange.isAnswered,
    render: DateInputRange.Render,
    reset: DateInputRange.Reset,
    isCompleted: DateInputRange.isCompleted,
    getValue: DateInputRange.getValue,
  },
  {
    type: "TimeInput",
    label: "Time Input",
    description: "A question requiring a time as an answer.",
    showChoicesTab: false,
    new: TimeInput.New,
    edit: TimeInput.Edit,
    dependencySelector: TimeInput.DependencySelector,
    dependencyNew: TimeInput.DependencyNew,
    isAnswered: TimeInput.isAnswered,
    render: TimeInput.Render,
    reset: TimeInput.Reset,
    isCompleted: TimeInput.isCompleted,
    getValue: TimeInput.getValue,
  },
  {
    type: "TimeInputRange",
    label: "Time Input (Range)",
    description: "A question requiring a time range as an answer.",
    showChoicesTab: false,
    new: TimeInputRange.New,
    edit: TimeInputRange.Edit,
    dependencySelector: TimeInputRange.DependencySelector,
    dependencyNew: TimeInputRange.DependencyNew,
    isAnswered: TimeInputRange.isAnswered,
    render: TimeInputRange.Render,
    reset: TimeInputRange.Reset,
    isCompleted: TimeInputRange.isCompleted,
    getValue: TimeInputRange.getValue,
  },
];
export function typeLookup(type) {
  const result = questionTypes.find((item) => item.type === type);
  if (result == null) throw new Error(`Question type "${type}" not found.`);
  return result;
}
export const subscriptionPlans = [
  {
    isEnabled: true,
    code: "Subscription(Monthly)",
    description: "Subscription(Monthly) $9.99/mo",
    price: 99,
  },
  {
    isEnabled: true,
    code: "Subscription(Annual)",
    description: "Subscription(Annual) $100/yr",
    price: 100,
  },
  {
    isEnabled: false,
    code: "Disabled Plan test",
    description: "$100000/minute",
    price: 100,
  },
];

export const defaults = {
  ALLOWED_ATTR: ["href", "src", "style"],
  ALLOWED_TAGS: [
    "a",
    "b",
    "blockquote",
    "br",
    "caption",
    "code",
    "del",
    "dd",
    "div",
    "dl",
    "dt",
    "em",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "hr",
    "i",
    "img",
    "li",
    "ol",
    "p",
    "pre",
    "s",
    "span",
    "sup",
    "sub",
    "strong",
    "strike",
    "ul",
    "u",
  ],
  CHARACTER_WIDTH_PX: 10,
  CHARACTER_WIDTH_MIN_PX: 120,
  DATE_STORAGE_FORMAT: "YYYY-MM-D",
  DATE_DISPLAY_FORMAT: "M/D/YY",
  HOUR_STORAGE_FORMAT: "H:mm A",
  HOUR_DISPLAY_FORMAT: "H:mm A",
  SURVEY_SAMPLE_GOAL_PCT: 30,
  SURVEY_EMAIL_SEND_TIME: "10:00",
  TABS_BACKGROUND_COLOR: "#9ac2ed",
  TABS_INDICATOR_COLOR: "primary",
  TABLE_HEADER_BACKGROUND_COLOR: "red",
  TABLE_SELECTED_BACKGROUND_COLOR: "#FF8400",
};
