import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import Grid from "../../../components/Grid";
import Table from "../../../components/Table";

import Button from "../../../components/Button";
import * as config from "../../../services/config";
import { AuthContext } from "../../../services/context";
import * as utils from "../../../services/utilities";
import Recipient from "../detail";
// import FormSelect from "../../../components/Select";
import Input from "../../../components/Input/inputs";
import MUIDialog from "../../../components/Modal";
import Tabs ,{Tab} from "../../../components/Tabs";

const dateTimeDisplayFormat = `${config.defaults.DATE_DISPLAY_FORMAT} ${config.defaults.HOUR_DISPLAY_FORMAT}`;

export default function Summary({
  survey,
  selectedWorksiteCode,
  selectedWorksiteInfo,
}) {
  utils.log.component(`Recipient.Summary(surveyCode: ${survey.code})`, survey);
  // #region Assertions
  utils.assert(survey != null, "Survey required.");
  // #endregion

  return (
    <div>
      {selectedWorksiteCode && selectedWorksiteInfo ? (
        <Worksite
          selectedWorksiteCode={selectedWorksiteCode}
          worksite={selectedWorksiteInfo}
          survey={survey?.code}
        />
      ) : null}
    </div>
  );
}

export const Worksite = ({
  selectedWorksiteCode,
  worksite,
  survey,
  refreshDepartments,
}) => {
  // utils.log.component(`Recipient.Worksite(${worksite.code})`, worksite);
  utils.assert(worksite != null, "Worksite required.");
  utils.assert(survey != null, "Survey required.");
  const getDepartments = async (worksite) => {
    setIsLoading(true);
    try {
      utils.assert(worksite != null, "Worksite required.");
      const result = await departmentService.getByWorksite(
        worksite.code,
        survey
      );
      const data = await result.json();
      setIsLoading(false);
      // Sort the data by the 'code' property
      data.sort((a, b) => a.code.localeCompare(b.code));
      setDepartments(data);
      // utils.log.stateChange("setDepartments(data)", data);
    } catch (error) {
      // Handle any potential errors here
      setIsLoading(false);
      // utils.log.error("Error updating list:", error);
    }
  };
  const { departmentService } = useContext(AuthContext);
  const [departments, setDepartments] = useState([]);
  const [isUpdateDepartmentsOpen, setIsUpdateDepartmentsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [department, setDepartment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    { Header: "Department", accessor: "name" },
    { Header: "Coordinator", accessor: "coordinator" },
    { Header: "Contacted", accessor: "contactedCount", align: "center" },
    { Header: "Started", accessor: "startedCount", align: "center" },
    { Header: "Completed", accessor: "completedCount", align: "center" },
    { Header: "Exempted", accessor: "exemptedCount", align: "center" },
  ];
  // #endregion
  // #region Events
  useEffect(() => {
    getDepartments(worksite);
  }, [worksite.code]);

  useEffect(() => {
    getDepartments(worksite);
  }, [refreshDepartments]);

  const handleView = (row) => {
    const department = row?.original;
    setIsEditOpen(true);
    setDepartment(department);
  };
  const handleEdit = (department) => {
    setIsUpdateDepartmentsOpen(true);
    setDepartment(department?.original);
  };

  if (departments == null) return <>- No departments found. -</>;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            // caption={`${worksite.code}: ${worksite.name}`}
            columns={columns}
            data={departments}
            selected
            onSelected={(row) => handleView(row)}
            isLoading={isLoading}
            actions={{
              edit: handleEdit,
              view: handleView,
            }}
          ></Table>
        </Grid>
      </Grid>
      {isEditOpen && (
        <Department
          open={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          department={department}
          survey={survey}
          setDepartment={setDepartment}
        ></Department>
      )}
      {isUpdateDepartmentsOpen && (
        <UpdateDepartment
          department={department}
          isUpdateDepartmentsOpen={isUpdateDepartmentsOpen}
          setIsUpdateDepartmentsOpen={setIsUpdateDepartmentsOpen}
        />
      )}
    </>
  );
};

const UpdateDepartment = ({
  department,
  isUpdateDepartmentsOpen,
  setIsUpdateDepartmentsOpen,
}) => {
  const [inputs, setInputs] = useState({
    code: department?.code,
    name: department?.name,
  });

  const { departmentService } = useContext(AuthContext);

  const handleUpdate = async () => {
    try {
      const response = await departmentService.update(inputs);
      utils.log.info(response);
    } catch (error) {
      // utils.log.info(":: error ", error);
    }
  };

  const onClose = () => {
    setIsUpdateDepartmentsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <MUIDialog
      open={isUpdateDepartmentsOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      title="Update Department"
      actions={
        <Button
          variant="contained-primary"
          style={{
            borderRadius: "4px",
          }}
          onClick={handleUpdate}
        >
          Update
        </Button>
      }
      height={"300px"}
      width={"800px"}
    >
      <Grid container spacing={4} sx={{ padding: "10px 10px" }}>
        <Grid item xs={12}>
          <Input
            label="Code"
            fullWidth
            name="code"
            disabled
            defaultValue={inputs?.code}
            onChange={handleChange}
          ></Input>
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Name"
            fullWidth
            name="name"
            required
            defaultValue={inputs?.name}
            onChange={handleChange}
          ></Input>
        </Grid>
      </Grid>
    </MUIDialog>
  );
};
export const Department = ({
  open,
  onClose,
  department,
  survey,
  setDepartment,
}) => {
  utils.assert(department != null, "Department required.");
  utils.assert(survey != null, "Survey required.");
  utils.assert(setDepartment != null, "setDepartment required.");
  const updateList = async () => {
    try {
      const recipients = await recipientService.getByDepartment(
        department.code,
        survey
      );

      setList(recipients);
    } catch (error) {
      // utils.log.error("Error updating list:", error);
    }
  };
  const {  recipientService } = useContext(AuthContext);
  const [list, setList] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const columns = [
    { Header: "Recipient", accessor: "fullName" },
    { Header: "UID", accessor: "uid" },
    { Header: "Occupation", accessor: "occupation" },
    { Header: "Status", accessor: "" },
  ];
  useEffect(() => {
    updateList();
  }, []);
  const handleEdit = (row) => {
    const recipient = row?.original;
    setIsEditOpen(true);
    setRecipient(recipient);
  };
  return (
    <>
      <MUIDialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        title="All Recipients"
        height={"600px"}
      >
        <div style={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Table
                columns={columns}
                data={list}
                selected
                onSelected={(row) => handleEdit(row)}
                actions={{
                  edit:handleEdit
                }}
              ></Table>
            </Grid>
          </Grid>
        </div>
      </MUIDialog>

      <MUIDialog
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        fullWidth
        maxWidth="lg"
        title={`Recipient - ${recipient?.firstName} ${recipient?.lastName}`}
      >
        <div style={{ padding: "15px 15px" }}>
          <RecipientDetails surveyCode={survey} recipient={recipient} />
        </div>
      </MUIDialog>
    </>
  );
};

export const RecipientDetails = ({ surveyCode, recipient }) => {
  return (
    <>
        <Tabs>
          <Tab
            label="Details"
            component={<Recipient
              surveyCode={surveyCode}
              recipient={recipient}/>}
            />
          <Tab
          label="Contact History"
          component={ <RecipientContactHistory recipient={recipient}/>}
          />
        </Tabs>
    </>
  );
};

const RecipientContactHistory = ({recipient}) => {
  /*eslint-disable-next-line*/
  const [recipientsContactHistory, setRecipientsContactHistory] =
    useState(null);
  /*eslint-disable-next-line*/
  const [openRecipientContactHistory, setOpenRecipientContactHistory] =
    useState(false);
  const contactHistoryColumns = [
    {
      Header: "Date",
      accessor: "contactDate",
      Cell: ({ cell: value }) => {
        const formattedDate = dayjs(value.row.original.contactDate).format(
          dateTimeDisplayFormat
        );
        return value.value !== undefined ? formattedDate : "???";
      },
    },
    { Header: "Contact Method", accessor: "contactMethod" },
  ];
  const handleEdit = (row) => {
    setOpenRecipientContactHistory(true);
    setRecipientsContactHistory(row);
  };
  return (
    <div>
      <Table
        columns={contactHistoryColumns}
        data={recipient?.contactHistory}
        selected
        caption="Contact History"
        onSelected={(row) => handleEdit(row.original)}
      ></Table>
    </div>
  );
};
